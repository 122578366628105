import React from 'react';
import strings from 'resources/locales/Translate';
import './ZmccStylesheet.scss';

export const ZmccRadioButton = ({ data, dataTest, disabled, error, field, label, onChange, isRequired, value}) => {
  const onValueChange = value => () => {
    onChange(value, field);
  };

  return (
    <div className='app-radio-button' data-test={dataTest}>
      <div className='radio-button-flex label-container'>
      <div>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>} </div>
      <div className='btn-group radio-button-flex' role='group' aria-label='Basic example'>
        {data.map((item, key) => (
          <button
            disabled={disabled}
            key={key}
            type='button'
            className={`btn text-left rounded-0  ${error && 'input-error'}`}
            onClick={onValueChange(item.id)}
        >
         <div className='radio-button-flex'><div className={`circle-before ${value === item.id ? 'active' : ''}`}>
          {
            value === item.id &&
            <div className='tick-mark'>&#10003;</div>
          }
          </div> {strings[item.value]}</div>
        </button>
        ))}
      </div>
      </div>
    </div>
  );
};

ZmccRadioButton.defaultProps = {
  value: '',
  disabled: false,
};

export default ZmccRadioButton;