// utilise this file if we have any enums/objects that commonly used accross application

export const timeSlotList = [
  { id: '08:00', value: '8:00 AM' },
  { id: '08:30', value: '8:30 AM' },
  { id: '09:00', value: '9:00 AM' },
  { id: '09:30', value: '9:30 AM' },
  { id: '10:00', value: '10:00 AM' },
  { id: '10:30', value: '10:30 AM' },
  { id: '11:00', value: '11:00 AM' },
  { id: '11:30', value: '11:30 AM' },
  { id: '12:00', value: '12:00 PM' },
  { id: '12:30', value: '12:30 PM' },
  { id: '13:00', value: '1:00 PM' },
  { id: '13:30', value: '1:30 PM' },
  { id: '14:00', value: '2:00 PM' },
  { id: '14:30', value: '2:30 PM' },
  { id: '15:00', value: '3:00 PM' },
  { id: '15:30', value: '3:30 PM' },
  { id: '16:00', value: '4:00 PM' },
  { id: '16:30', value: '4:30 PM' },
  { id: '17:00', value: '5:00 PM' },
  { id: '17:30', value: '5:30 PM' },
  { id: '18:00', value: '6:00 PM' }
];
export const sapCrmNewCient = 'sapcrm020';
export const IQS_SSC_DE_DESIGNATION = 'IQS_SSC_DE';
export const RMS_L_L_DESIGNATION = 'RMS_L_L';
export const RMS_ZMCC_DESIGNATION = 'RMS_ZMCC';

export const REQUEST='ZMCC_REQUEST';
export const CHECKED='ZMCC_CHECKED';
export const CANCELLED= 'ZMCC_CANCELLED';
export const WAITING_FOR_SAMPLES='ZMCC_WAITING_FOR_SAMPLES';
export const WAITING_FOR_PROCESSING="ZMCC_WAITING_FOR_PROCESSING";
export const IN_PROGRESS="ZMCC_IN_PROGRESS";
export const WAITING_FOR_REPORT="ZMCC_WAITING_FOR_REPORT";

// Utility function to get constant by parameter
export function getStatusConstantByKey(key) {
  const statusMap = {
    REQUEST,
    CHECKED,
    CANCELLED,
    WAITING_FOR_SAMPLES,
    WAITING_FOR_PROCESSING,
    IN_PROGRESS,
    WAITING_FOR_REPORT,
  };

  return statusMap[key] || 'Unknown status';
}

export const IQS_SSC_DC_STOCK_ALLOWED_FILE_TYPES = "image/*,.pdf,.ppt,.pptx,.jpg,.png,.jpeg,.xls,.xlsx,.doc,.docx";
