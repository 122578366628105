import React, {Component} from 'react';

import strings from 'resources/locales/Translate';

import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { categoriesListRequestJson } from 'library/api/endpoint-request-json';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

import Filter from './Filter/container/FilterContainer';
import Carousel from './Carousel/Carousel.component';
import './homepage.styles.scss';
import AppUrlChangePopupComponent from './UrlChangePopup/AppUrlChangePopup.component';
import QuickStart from './Quickstart/QuickStart';

export default class Homepage extends Component {
  componentDidMount() {
    document.title = 'ZDMA | Homepage';
    const {isLoggedIn, user} = this.props.authentication;
    const isZMCCEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';

    if (isLoggedIn) {
      this.fetchMasterData();
      this.props.fetchKitListForHomepage(isLoggedIn);
      this.props.fetchCartDataFromLocalStorage();
      isZMCCEnabled && this.props.getListOfUnexecutedSemTransaction();
      this.props.getAllScheduledEventList(user.accountId)
      this.props.checkIsOperator(user.accountId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      authentication,
      language,
      fetchKitListForHomepage,
      fetchCartDataFromLocalStorage,
      fetchMasterData,
      fetchSubMenuOfZmccRequest,
    } = this.props;

    if (authentication.isLoggedIn !== prevProps.authentication.isLoggedIn) {
      this.fetchMasterData();
      fetchKitListForHomepage(authentication.isLoggedIn);
      fetchCartDataFromLocalStorage();
    }

    if (language !== prevProps.language) {
      fetchMasterData(URLS.loanTypesListForHomePage, actionTypes.FETCH_LOAN_TYPE_LIST);
      fetchMasterData(URLS.countryList, actionTypes.FETCH_COUNTRY_LIST);
    }

    if (language.language !== prevProps.language.language) {
      fetchMasterData(URLS.loanTypesListForHomePage, actionTypes.FETCH_LOAN_TYPE_LIST);
      fetchSubMenuOfZmccRequest();
    }
  }

  fetchMasterData() {
    const { fetchMasterData, fetchRefurbishmentStocks, fetchSubMenuOfZmccRequest } = this.props;

    fetchMasterData(URLS.categoryList, actionTypes.FETCH_CATEGORY_LIST, categoriesListRequestJson);
    fetchMasterData(URLS.loanTypesListForHomePage, actionTypes.FETCH_LOAN_TYPE_LIST);
    // fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
    fetchMasterData(URLS.businessUnitList, actionTypes.FETCH_BUSINESS_UNIT_LIST);
    fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
    fetchRefurbishmentStocks();
    fetchSubMenuOfZmccRequest();
  }

  checkRolesAndPermission(kits = {}) {
    const {user} = this.props.authentication;

    let isBookingAllowed = false;
    let isFilterAllowed = false;

    if (user) {
      if (user.admin) {
        isFilterAllowed = true;
        isBookingAllowed = true;
      } else {
        const rolesToCheck = ['manager', 'stock controller', 'dispatcher', 'borrower', 'external borrower'];
        isFilterAllowed = this.checkRoleForFilter(rolesToCheck);
        if (kits && kits.content && kits.content.length > 0) {
          isBookingAllowed = this.checkRoles(kits, rolesToCheck);
        }
      }
    }

    return {isBookingAllowed, isFilterAllowed};

  }

  checkRoles(kits, rolesToCheck) {
    if (kits && kits.content && kits.content.length) {
      return kits.content.forEach(kit => {
        if (kit.stockInformation && kit.stockInformation.userRole && kit.stockInformation.userRole.length > 0) {
          kit.stockInformation.userRole.some(({role}) => {
            return rolesToCheck.some(checkRole => {
              return (
                (role.roleName && role.roleName.toLowerCase() === checkRole) ||
                (role.role && role.role.toLowerCase() === checkRole)
              );
            });
          });
        }
      });
    }
  }

  checkRoleForFilter(rolesToCheck) {
    const {user} = this.props.authentication;
    return (
      user.stockRoles &&
      user.stockRoles.some(role => {
        return rolesToCheck.some(checkRole => {
          return role.roleName.toLowerCase() === checkRole;
        });
      })
    );
  }

  handleChangeLoanType = loanType => {
    const { fetchMasterData } = this.props;

    if (loanType === 1) {
      fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
    } else if (loanType === 2) {
      fetchMasterData(`${URLS.countryList}?loantype=single-loan`, actionTypes.FETCH_COUNTRY_LIST);
    } else if(loanType === 3) {
      fetchMasterData(`${URLS.countryList}?loantype=course-loan`, actionTypes.FETCH_COUNTRY_LIST);
    } else if(loanType === 5){
      fetchMasterData(`${URLS.countryList}?loantype=zmcc`, actionTypes.FETCH_COUNTRY_LIST);
    } else if(loanType === 6){
      fetchMasterData(`${URLS.countryList}?loantype=l@l`, actionTypes.FETCH_COUNTRY_LIST);
    } else{
      fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
    }
  };

  getBusinessUnits = (user) => {
    const businessUnits = new Set();
    if(user){
      user.stockRoles.forEach(role => {
          businessUnits.add(role.businessUnitName);
      });
    }

    return Array.from(businessUnits);
}



  render() {
    const { displayActionMessage, type, message } = this.props.appActions;
    const { history, toggleActionMessage, fetchWorkflowData,  } = this.props;
    const { user, isLoggedIn } = this.props.authentication;
    const uniqueBusinessUnits = this.getBusinessUnits(user); 
    const {loansSubMenu} = this.props.homepage;
    return (
      <>
      <div className='homepage-container'>
        {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
        <Filter
          history={history}
          onToggleMessage={toggleActionMessage}
          onChangeLoanType={this.handleChangeLoanType}
          isFilterAllowed={this.checkRolesAndPermission().isFilterAllowed}
          fetchWorkflowData={fetchWorkflowData}
          loansSubMenu={loansSubMenu}
          uniqueBusinessUnits={uniqueBusinessUnits}
        />
        <Carousel  uniqueBusinessUnits={uniqueBusinessUnits} />
      </div>
        <QuickStart isFilterAllowed={this.checkRolesAndPermission().isFilterAllowed} user={user} />
        </>
    );
  }
}
