import React from 'react';
import { NavLink } from 'react-router-dom';

import strings from 'resources/locales/Translate';

import SmallButton from 'library/common/commonComponents/SmallButton/SmallButton.component';
import CreateServiceTicketPopup from 'library/common/commonComponents/CreateServiceTicketPopup';

import DeleteButton from '../DeleteButton/DeleteButton';
import { EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

const ExpandableRowOfKitListTable = ({ fetchKitsList, row, handleDelete, user }) => {
  const { stockInformation, isTransactionAssociated, isLabsLocationPartner, isZMCCKit } = row;
  let isEditable = user.admin ? user.admin : false;
  let isDeletable = user.admin ? user.admin : false;
  let isServiceTicketCreatable = user.admin ? user.admin : false;

  if (stockInformation && stockInformation.userRole && stockInformation.userRole.length > 0) {
    isEditable =
      stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
      user.admin ||
      (!isLabsLocationPartner && stockInformation.userRole[0].role.role.toLowerCase() === 'reconditioning') ||
      stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher';

    isDeletable =
      stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
      stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher' ||
      user.admin;

    isServiceTicketCreatable =
      stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
      user.admin ||
      stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher' ||
      stockInformation.userRole[0].role.role.toLowerCase() === 'reconditioning';
  }

  return (
    <>
      <td colSpan='15' className='expanded-row'>
        <div className='row no-gutters mb-3 mt-3'>
          <div className='d-flex align-items-center col-12'>
            <div className='d-flex align-items-center align-self-start col-5'> {/* Todo: Conditionally apply col-4 based service ticket */}
              <NavLink to={{ pathname: `/kit_details/${row.kitInformationId}` }} exact={true}>
                <SmallButton
                  iconName='openEye'
                  className='transaction-actions-btns'
                  value={strings.showDetails}
                  textOnly={true}
                  size={20}
                  viewBox={'0 0 20 15'}
                  iconClassName={'mr-2'}
                />
              </NavLink>
              {isEditable && (
                <div className='ml-2'>
                  <NavLink
                    to={{
                      pathname: isLabsLocationPartner
                        ? `/edit-llkit/${row.kitInformationId}`
                        : isZMCCKit
                        ? `/edit-zmcc-kit/${row.kitInformationId}`
                        : `/edit-kit/${row.kitInformationId}`,
                      state: { edit: true },
                    }}
                  >
                    <SmallButton
                      bgFill={false}
                      customIcon={<EditOutlineIcon width='20' height='20' viewBox='0 0 20 20' className='mr-2 mt-1' />}
                      value='Edit'
                      className='transaction-actions-btns f-16'
                      textOnly={true}
                    />
                  </NavLink>
                </div>
              )}

              {isServiceTicketCreatable && row.ibase && (
                <div className='ml-2' data-toggle='modal' data-target={`#createServiceTicket${row.kitInformationId}`}>
                  <SmallButton 
                    iconName='file' 
                    value={strings.createServiceTicket}
                    textOnly={true}
                    className='transaction-actions-btns'
                    iconClassName={'mr-2'}
                  />
                </div>
              )}

              {!isTransactionAssociated && isDeletable && (
                <div className='ml-2'>
                  <DeleteButton
                    iconName='delete'
                    value={strings.delete}
                    id={row.kitInformationId}
                    handleDelete={handleDelete}
                  />
                </div>
              )}
            </div>
            <div className='d-flex flex-column'>
              <strong>{isLabsLocationPartner || isZMCCKit ? strings.function : strings.commentText}</strong>
              <p>{row.comment || "-"}</p>
            </div>
          </div>
        </div>
      </td>
      <CreateServiceTicketPopup
        id={`createServiceTicket${row.kitInformationId}`}
        kitDetails={row}
        user={user}
        handleFetch={fetchKitsList}
      />
    </>
  );
};

export default ExpandableRowOfKitListTable;
