import Validators from 'library/utilities/Validators';

export const informationFormModel = [
  {
    label: 'zmccFullForm',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockInformation',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    disabled: true,
  },
  {
    label: 'systemClassText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClass',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
    disabled: false,
  },
  {
    label: 'systemMainComponent',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponent',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'systemComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
  },
  {
    label: 'serialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'ibaseText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'ibase',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'dateOfManufacture',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'dateOfManufacture',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    dateFormat: 'MM/YYYY',
  },
  {
    label: 'availableFrom',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'availableTo',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityTo',
    styleClass: 'col-sm-3',
  },
  {
    label: 'inputRoomLabel',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'room',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'warehouseRoomId',
    displayValue: [{ key: 'roomName', separator: '' }],
  },
  {
    label: 'owner',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'owner',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'userId',
    displayValue: [{ key: 'lastName', separator: ', ' },{ key: 'firstName', separator: '' }],
  },
  {
    label: 'isMobileOrExternal',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'isMobileOrExternal',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    options: [
      {
        id: 'Yes',
        value: 'yes',
      },
      {
        id: 'No',
        value: 'no',
      },
    ],
    displayValue: [{ key: 'id', separator: '' }],
  },
];

export const kitConditionFormModel = [
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    field: 'kitCondition',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'kitStatusId',
    displayValue: [{ key: 'translatedStatus', separator: '' }],
  },
  {
    label: 'function',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [],
    required: false,
    styleClass: 'col-sm-9',
  },
];
