import React from 'react';
import './index.styles.scss';
import { useOperatorSchedule } from './OperatorSchedule.hook';
import strings from 'resources/locales/Translate';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Validators from 'library/utilities/Validators';
import Button from 'library/common/commonComponents/Button/Button';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Loader from 'library/common/commonComponents/Loader';
import { connect } from 'react-redux';
import { toggleActionMessage, changeLoader } from 'library/common/commonActions/AppActionsActions';
import {
  updateZmccInformationTab,
  updateZmccTransaction,
  getWholeSemTransaction,
  updateAssignOperatorsForZmcctranscation,
} from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.actions';
import { fetchOperatorScheduleOfStockOnGivenDate } from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.actions';
import $ from 'jquery';
import AppSchedular from 'library/common/commonComponents/AppSchedular/AppSchedular';
import classNames from 'classnames';

function OperatorsSchedules({
  trasanctionDetails = {},
  updateZmccInformationTab,
  formType,
  getDataToSendFromInformationTab,
  editing,
  updateAssignOperatorsForZmcctranscation,
  handleCloseModal,
}) {

  const {
    selectedFromTime,
    selectedToTime,
    timeSlotList,
    onChangeSuggestedDate,
    onChangeFromTime,
    onChangeToTime,
    onClickAssign,
    loading,
    buttonLoading,
    selectedKits,
    alreadyBooked,
    operatorEvents,
    allDay,
    operatorScheduleAlternateDate,
    operatorScheduleSuggestedDate,
    getSelectedOperatorsCallback,
    onChangeAlternateDate,
    errors,
    selectedOperators,
    handleAllDay
  } = useOperatorSchedule(
    trasanctionDetails,
    formType,
    updateZmccInformationTab,
    getDataToSendFromInformationTab,
    handleCloseModal,
    editing,
    updateAssignOperatorsForZmcctranscation,
  );

  if (!loading && (selectedKits?.content?.length === 0 || operatorEvents.length === 0) ) {
    return (
      <div className='d-flex flex-row align-items-center justify-content-center'>
        <h6 >{strings.noDataFoundText}</h6>
      </div>
    );
  }
  return (
    <>
      <div className='d-flex flex-row'>
        <div className='col-3 p-0 mb-1'>
          <DateInput
            field='dateRange'
            label={strings.suggestedDate}
            placeholder={strings.pleaseSelectDate}
            selectedDate={operatorScheduleSuggestedDate || new Date()}
            onChange={e => onChangeSuggestedDate(e)}
            isRequired={true}
            isRange={false}
            // minDate={new Date()}
            error={errors.suggestedDateError}
          />
        </div>
        <div className='col-3 ml-2 p-0 mb-1'>
          <DateInput
            field='dateRange'
            label={strings.alternateDate}
            placeholder={strings.pleaseSelectDate}
            selectedDate={operatorScheduleAlternateDate || new Date()}
            onChange={e => onChangeAlternateDate(e)}
            isRequired={true}
            isRange={false}
            minDate={new Date()}
            error={errors.alternateDateError}
          />
        </div>
        <div className='col-2 p-0 mb-1 ml-2'>
          <Dropdown
            data={timeSlotList}
            hasSection={false}
            multiSelect={false}
            filter={false}
            idKey='id'
            field='startTime'
            displayValue={[{ key: 'value', separator: ' ' }]}
            label={strings.startTime}
            isRequired
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            placeholder={strings.pleaseSelect}
            onChange={onChangeFromTime}
            value={selectedFromTime.value}
            fullValue={selectedFromTime.fullValue}
            error={errors.fromTimeError}
            disabled={allDay}
          />
        </div>
        <div className='col-2 p-0 mb-4 ml-2'>
          <Dropdown
            data={timeSlotList}
            hasSection={false}
            multiSelect={false}
            filter={false}
            idKey='id'
            field='endTime'
            displayValue={[{ key: 'value', separator: ' ' }]}
            label={strings.endTime}
            isRequired
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            placeholder={strings.pleaseSelect}
            onChange={onChangeToTime}
            value={selectedToTime.value}
            fullValue={selectedToTime.fullValue}
            error={errors.toTimeError}
            disabled={allDay}
          />
        </div>
        <div className='col-2 p-0 mt-4 ml-2'>
          <Checkbox label={strings.allDay} selected={allDay} onChange={val => handleAllDay(val)} />
        </div>
      </div>
      {!loading && selectedKits?.content?.length && operatorScheduleSuggestedDate ? (
        <>
          <AppSchedular
            resources={selectedKits.content}
            updateEventsOnChange
            // disableClick
            resizeable={true}
            // dragable={false}
            schedulerWrapperClassName='mr-3'
            alreadyBooked={alreadyBooked}
            startDate={operatorScheduleSuggestedDate}
            isLabsLocation={false}
            isZMCCCalendar={true}
            events={[]}
            cart={[]}
            operatorsList={operatorEvents}
            isCalendarFromOperatorSchedules={true}
            getSelectedOperatorsCallback={getSelectedOperatorsCallback}
            selectedOperators={selectedOperators}
          />
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <p
                className={classNames({
                  'd-none': !errors?.operatorsAssignError
                })}
                style={{ color: 'red' }}
              >
                {errors.operatorsAssignError}
              </p>
            </div>
            <Button
              value={strings.assignOperator}
              loading={buttonLoading}
              styleClass={'col-2'}
              onClick={onClickAssign}
            ></Button>
          </div>
        </>
      ) : (
        <div className='d-flex align-items-center justify-content-center'>
          <Loader />
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ languageReducer, authReducer, zmccTransactionDetailsReducer, createSEMFormReducer }) => ({
  user: authReducer.user,
  language: languageReducer.language,
  zmmctransactionDetails: zmccTransactionDetailsReducer.zmccTransactionDetails,
  zmccStockOperators: zmccTransactionDetailsReducer.zmccOperators,
  zmccSubscriberUsers: zmccTransactionDetailsReducer.zmccSubscriberUsers,
  systemCategoriesList: createSEMFormReducer.systemCategoriesList,
});

export default connect(mapStateToProps, {
  toggleActionMessage,
  updateZmccTransaction,
  updateZmccInformationTab,
  fetchOperatorScheduleOfStockOnGivenDate,
  changeLoader,
  getWholeSemTransaction,
  updateAssignOperatorsForZmcctranscation,
})(OperatorsSchedules);
