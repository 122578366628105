import React, { Component } from 'react';
import { debounce, isEqual } from 'lodash';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';
import { bookingBreadcrumbData } from 'modules/Booking/bookingBreadcrumbsConstants';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import DelayMessage from 'modules/Booking/Components/DelayMessage/DelayMessage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import AdjustDates from 'modules/Transactions/TransactionDetails/Components/AdjustDates';
import Icon from 'library/common/commonComponents/Icon/Icon';

import {
  INITIAL_SORT,
  INITIAL_FILTER,
  INITIAL_LABS_LOCATION_SORT,
  INITIAL_LABS_LOCATION_FILTER,
  typeOfLLBookings,
  typeZmccBookings,
  INITIAL_ZMCC_FILTER,
  typeOfNonLLNonZmccBookings,
  queryResultBreadcrumbData
} from './BookingConstants';
import TeamBookings from './Components/TeamBookings';
import ExportTransactionList from './Components/ExportTransactionList/ExportTransactionList.component';
import DLATransactions from './Components/DLATransactions';
import LLTransactions from './Components/LLTransactions';
import ZMCCTransaction from './Components/ZMCCTransaction';
import ContinueUseApplicationPopup from './Components/ContinueApplicationPopup/ContinueUseApplicationPopup.component';
import { checkZmccUserRoles } from 'library/utilities/checkRoles';
import { SaveQueryPopup } from './Components/SaveQueryPopup/SaveQueryPopup.component';
import { EditMyQueryOnResultPage } from './Components/EditMyQueryOnResultPage/EditMyQueryOnResultPage.component';

const getDateTypes = language => [
  { id: 1, name: language !== 'de' ? 'Delivery date' : 'Liefertermin' },
  { id: 2, name: language !== 'de' ? 'Begin of Loan' : 'Beginn der Ausleihe' },
  { id: 3, name: language !== 'de' ? 'End of loan' : 'Ende der Ausleihe' },
];

const getZmccDateTypes = language => [
  { id: 'created', name: language !== 'de' ? 'Request date' : 'Anfragedatum' },
  { id: 'startdate', name: language !== 'de' ? 'Start Date' : 'Start Datum' },
  { id: 'duedate', name: language !== 'de' ? 'Due Date' : 'Fälligkeitsdatum' },
  { id: 'lastmodified', name: language !== 'de' ? 'Last modified' : 'Zuletzt geändert' },
];

const checkFiltersLanguage = (filter, data, isLabsLocationType) => {
  let loanTypeFullValue;
  const currentPositionFullValue = [];
  const transactionStatusesFullValue = [];
  let conditionFullValue;
  let dateTypeFullValue;
  const {
    conditions,
    language,
    loanTypes,
    transactionPositions,
    transactionStatuses,
    labsLocationTransactionStatuses,
  } = data;
  if (filter.loanType) {
    loanTypeFullValue = loanTypes.filter(item => item.id === filter.loanType);
  }
  if (filter.currentPosition && filter.currentPosition.length) {
    filter.currentPosition.forEach(current => {
      const found = transactionPositions.find(item => item.transactionPositionId === current);
      if (found) {
        currentPositionFullValue.push(found);
      }
    });
  }
  if (filter.transactionStatuses && filter.transactionStatuses.length) {
    const statuses = isLabsLocationType ? labsLocationTransactionStatuses : transactionStatuses;
    filter.transactionStatuses.forEach(current => {
      const found = statuses.find(item => item.transactionStatusId === current);
      if (found) {
        transactionStatusesFullValue.push(found);
      }
    });
  }
  if (filter.condition) {
    conditionFullValue = conditions.filter(item => item.kitStatusId === filter.condition);
    if (conditionFullValue) {
      conditionFullValue = conditionFullValue[0];
    }
  }
  if (filter.dateType) {
    dateTypeFullValue = getDateTypes(language).filter(item => item.id === filter.dateType);
    if (dateTypeFullValue) {
      dateTypeFullValue = dateTypeFullValue[0];
    }
  }
  return {
    ...filter,
    loanTypeFullValue,
    currentPositionFullValue,
    transactionStatusesFullValue,
    conditionFullValue,
    dateTypeFullValue,
  };
};

const updateZmccFiltersOnCLangChange = (filter, data) => {
  const {zmccCategory, systemCategoriesList, zmccTransactionStatuses, language} = data
  let dateTypeFullValue;
  if(filter?.dateType){
    dateTypeFullValue = getZmccDateTypes(language).filter(dateTpe => dateTpe.id === filter.dateType);
    if(dateTypeFullValue.length > 0){
      dateTypeFullValue = dateTypeFullValue[0];
    }
  }
  let categoryFullValue=[];
  if(filter?.category?.length){
    categoryFullValue = zmccCategory.filter(category => {
      return filter.category.includes(category.id);
    })
  }
  let transactionStatusesFullValue = [];
  if(filter?.transactionStatuses?.length){
    transactionStatusesFullValue = zmccTransactionStatuses.filter(status => {
      return filter.transactionStatuses.includes(status.transactionStatusId);
    })
  }
  let systemCategoriesIdFullValue = [];
  if(filter?.systemCategoriesId?.length){
    systemCategoriesIdFullValue = systemCategoriesList.filter(system => {
      return filter.systemCategoriesId.includes(system.id);
    })
  }
  return {
    ...filter,
    dateTypeFullValue: dateTypeFullValue ? dateTypeFullValue: filter.dateTypeFullValue,
    categoryFullValue: categoryFullValue.length > 0 ? categoryFullValue: filter.categoryFullValue,
    transactionStatusesFullValue: transactionStatusesFullValue?.length > 0 ? transactionStatusesFullValue: filter.transactionStatusesFullValue,
    systemCategoriesIdFullValue: systemCategoriesIdFullValue.length > 0? systemCategoriesIdFullValue: filter.systemCategoriesIdFullValue,
  }
}

class Booking extends Component {
  constructor(props) {
    super(props);
    let filter = { ...INITIAL_FILTER };
    let sort = { ...INITIAL_SORT };
    const urlData = this.props.match && this.props.match.url.slice(1).split('-');
    let transactionType = '';
    urlData.forEach((item, index) => {
      if (index !== 0) {
        transactionType += item.charAt(0).toUpperCase() + item.slice(1);
      } else {
        transactionType = item;
      }
    });
    if (
      props.history &&
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.loanType
    ) {
      filter = {
        ...filter,
        loanType: props.history.location.state.loanType,
        loanTypeFullValue:
          props.loanTypes &&
          props.loanTypes.length &&
          props.loanTypes.filter(item => item.id === props.history.location.state.loanType),
      };
      saveToStorage('filter', filter);
      saveToStorage('sort', sort);
    } else {
      let identifierFilter = identifiers.filtersTransaction;
      let identifierSort = identifiers.filtersTransactionSort;
      if (transactionType === 'cancelledProcesses') {
        identifierFilter = identifiers.filtersTransactionCancelled;
        identifierSort = identifiers.filtersTransactionSortCancelled;
      } else if (transactionType === 'archiveProcesses') {
        identifierFilter = identifiers.filtersTransactionArchive;
        identifierSort = identifiers.filtersTransactionSortArchive;
      } else if (transactionType === 'cancellationApproval') {
        identifierFilter = identifiers.filtersCancelledApproval
      }
      const storageFilter = fetchFromStorage(identifierFilter);
      const storageSort = fetchFromStorage(identifierSort);
      if (storageFilter) {
        filter = {
          ...storageFilter,
          searchTerm: storageFilter.searchTerm || '',
        };
      }
       else if (transactionType !== 'cancelledProcesses' && transactionType !== 'archiveProcesses' && transactionType !== 'cancellationApproval') {
        filter = { ...filter, transactionStatuses: [1, 2, 3, 9] };

      }
       if (transactionType === 'cancellationApproval') {
        filter = { ...filter, transactionStatuses: [1, 2, 3] };
      }
      if (storageSort) {
        sort = { ...storageSort };
      }
    }
    let identifierLabsLocationFilter = identifiers.filtersLabsLocationTransaction;
    let isZMCCFilter = identifiers.zmccTransactionListFilter;
    let identifierLabsLocationSort = identifiers.identifierLabsLocationSort;
    if (transactionType === 'cancelledProcesses') {
      identifierLabsLocationFilter = identifiers.filtersLabsLocationTransactionCancelled;
      identifierLabsLocationSort = identifiers.identifierLabsLocationSortCancelled;
    } else if (transactionType === 'archiveProcesses') {
      identifierLabsLocationFilter = identifiers.filtersLabsLocationTransactionArchive;
      identifierLabsLocationSort = identifiers.identifierLabsLocationSortArchive;
    }
    const storageLabsLocationFilter = fetchFromStorage(identifierLabsLocationFilter);
    const storageLabsLocationSort = fetchFromStorage(identifierLabsLocationSort);
    const hasDefaultStatuses =
      !storageLabsLocationFilter && !isZMCCFilter && transactionType !== 'cancelledProcesses' && transactionType !== 'archiveProcesses';
    const defaultStatuses = hasDefaultStatuses ? { transactionStatuses: [1, 7, 8] } : {};
    const noTransactionTypeSelected = ['archiveProcesses','cancelledProcesses', 'cancellationApproval'];
    // const zmccStatus = (!noTransactionTypeSelected.includes(transactionType) && isZMCCFilter) ? {transactionStatuses: [1]} : {}
    const { initialType } = this.getUserPermissions(transactionType);
    this.state = {
      isShowFilter: true,
      data: {
        page: 1,
        size: 10,
        sort,
        filter,
      },
      labsLocationData: {
        page: 1,
        size: 10,
        sort: {
          ...INITIAL_LABS_LOCATION_SORT,
          ...storageLabsLocationSort,
        },
        filter: {
          ...INITIAL_LABS_LOCATION_FILTER,
          ...storageLabsLocationFilter,
          ...defaultStatuses,
        },
      },
      zmccData: {
        page: 1,
        size: 10,
        sort:{},
        filter: {
          ...INITIAL_ZMCC_FILTER,
          ...this.applyZmccSaveFilters(transactionType)
        }

      },
      typeTransactions: initialType,
      transactionType,
      isVisibleAdjustDates: false,
      transactionDetail: null,
    };
  }

  async componentDidMount() {
    const { data, labsLocationData, transactionType, typeTransactions, zmccData } = this.state;
    const { fetchTransactionListData, getTeamMembers, fetchCartDataFromLocalStorage, fetchZmccTransactionList, getSaveFilterData, saveFilterData } = this.props;
    let type = typeTransactions === 'zmcc' ? zmccData :typeTransactions === 'dla' ? data : labsLocationData;
    saveToStorage(identifiers.isZmccTransactionsCheckboxActive, false);
    // saveToStorage(identifiers.lastSlectedTransCheckBox, type === 'zmcc'? 'zmcc' : type === 'dla' ? 'dla' : 'l@l')

    if (transactionType === 'teamBookings') {
      await getTeamMembers();
    }
    this.checkFiltersLanguage();
    if (typeTransactions === 'zmcc') {
      const {history} = this.props;
      let params = new URLSearchParams(history.location.search)
      if(params.get('safeSearch') && params.get('safeSearch') > 0){
        getSaveFilterData(params.get('safeSearch'))
      }else{
        fetchZmccTransactionList(type, typeTransactions, this.props.match.url.slice(1), true)
      }
    }else{
      fetchTransactionListData(type, typeTransactions, this.props.match.url.slice(1), true);
    }
    fetchCartDataFromLocalStorage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.language !== prevProps.language) {
      this.fetchDataOnLanguageChange();
    }
    if (prevState.typeTransactions !== this.state.typeTransactions) {
      this.checkFiltersLanguage();
    }

    if (!isEqual(this.props.saveFilterData, prevProps.saveFilterData) ) {
      const { fetchZmccTransactionList } = this.props
      let sendData = {
        filter: {...JSON.parse(this.props.saveFilterData.search)},
        page: 1,
        size: 10,
        sort:{}, 
      }
      fetchZmccTransactionList(sendData, this.state.typeTransactions, this.props.match.url.slice(1), true)
      this.setState(prevState => {
        return {
          ...prevState,
          zmccData: {...prevState.zmccData, filter: {...JSON.parse(this.props.saveFilterData.search)}}
        }
      })
    }
  }

  componentWillUnmount() {
    saveToStorage(identifiers.isZmccTransactionsCheckboxActive, false);
    this.debounceUpdateData.cancel();
    this.debounceUpdateLabsLocationData.cancel();
    this.debounceUpdateZmccData.cancel();
  }

  applyZmccSaveFilters = (transactionType) => {
    let indentifiers = ''
    if (transactionType === 'cancelledProcesses') {
      indentifiers  = identifiers.cancelledZmccFilters;
    } else if (transactionType === 'archiveProcesses') {
      indentifiers = identifiers.archivedZmccFilters;
    } else {
      indentifiers = identifiers.zmccTransactionListFilter;
    }
    let filters = fetchFromStorage(indentifiers);
    if(!filters){
      return {};
    }
    return {...filters}
  }

  getUserPermissions = transactionType => {
    const { user } = this.props;
    const RMS_LL = 'RMS labs@location Partner';
    const RMS_ZMCC = 'RMS (ZMCC)';

    if (!user) return { initialType: false, hasAllPermissions: false };

    const roles = ['Stock Controller', 'Operator', 'Dispatcher', 'Reconditioning'];
    const hasAccessToZmccWarehouse = checkZmccUserRoles(roles, user);
    const hasLLTransactions = typeOfLLBookings.includes(transactionType);
    const hasZmccTransaction = typeZmccBookings.includes(transactionType);
    const hasMyBookings = transactionType === 'myBookings';
    const hasLLPermissions = user.stockRoles.filter(
      item => item.businessUnitName === RMS_LL && item.roleName !== 'Borrower',
    ).length;
    const hasLLBorrowerPermissions = user.stockRoles.filter(
      item => item.businessUnitName === RMS_LL && item.roleName === 'Borrower',
    ).length;
    const hasOnlyLLBorrowerPermissions = !hasLLPermissions && hasLLBorrowerPermissions;
    const hasDLAPermissions = user.stockRoles.some(item => item.businessUnitName !== RMS_LL && item.businessUnitName !== RMS_ZMCC);
    const hasOnlyLLPermissions =
      ((hasLLPermissions && hasLLTransactions) || (hasOnlyLLBorrowerPermissions && hasMyBookings)) &&
      !hasDLAPermissions &&
      !user.admin;
    const hasOnlyMyBookingPermissions = hasOnlyLLBorrowerPermissions && hasDLAPermissions && hasMyBookings;
    const checkHasAllPermissions =
      (user.admin && hasLLTransactions) ||
      (hasLLPermissions && hasDLAPermissions && hasLLTransactions) ||
      hasOnlyMyBookingPermissions;
    const hasAllPermissions = checkHasAllPermissions == 0 ? false : true;
    const hasZmccPermision =  user.stockRoles.some(item => item.businessUnitName == RMS_ZMCC);
    const zmccPermission = hasZmccTransaction && hasZmccPermision
    let initialType ;
    const lastSelectedValue = fetchFromStorage(identifiers.lastSlectedCheckboxInTrans);
    
    initialType = hasAccessToZmccWarehouse ? 'zmcc' : hasOnlyLLPermissions ? 'l@l' : hasDLAPermissions ? 'dla' : hasZmccPermision ?'zmcc': 'dla';
    if (this.props.history && transactionType === 'myBookings') {
      if (this.props.history.location.search === '?type=l@l') {
        initialType = 'l@l';
        saveToStorage('l@l');
      }
      if (this.props.history.location.search === '?type=zmcc') {
        initialType = 'zmcc';
        saveToStorage('zmcc');
      }
      if (this.props.history.location.search === '?type=dla') {
        initialType = 'dla';
        saveToStorage('dla');
      }
    }
    const {history} = this.props;
    let params = new URLSearchParams(history.location.search)
    if (lastSelectedValue !== null && lastSelectedValue !== undefined && (this.props.history.location.search !== '?type=l@l' && this.props.history.location.search !== '?type=zmcc' && this.props.history.location.search !== '?type=dla')) {
      initialType = lastSelectedValue;
    }
    if(typeOfNonLLNonZmccBookings.includes(transactionType) && lastSelectedValue !== 'dla'){
      initialType = 'dla'
    }
    if(params.get('safeSearch') && params.get('safeSearch') > 0){
      initialType = 'zmcc';
    }
    return { initialType, hasAllPermissions , zmccPermission, hasDLAPermissions, hasOnlyLLPermissions };
  };

  fetchDataOnLanguageChange = async () => {
    const { typeTransactions, data, labsLocationData } = this.state;
    const { fetchTransactionListData, fetchMasterDataOnLanguageChange, fetchZmccTransactionList } = this.props;
    const type = typeTransactions === 'dla' ? data : labsLocationData;
    if (this.state.typeTransactions === 'zmcc') {
      fetchZmccTransactionList(this.state.zmccData, typeTransactions, this.props.match.url.slice(1), false)
    }else{
    await fetchTransactionListData(type, typeTransactions, this.props.match.url.slice(1), false);
    }
    await fetchMasterDataOnLanguageChange();
    this.checkFiltersLanguage();
  };

  saveToStorage = (type, data) => {
    const { transactionType } = this.state;
    let identifier = identifiers.filtersTransaction;
    if (type === 'filter') {
      if (transactionType === 'cancelledProcesses') {
        identifier = identifiers.filtersTransactionCancelled;
      } else if (transactionType === 'archiveProcesses') {
        identifier = identifiers.filtersTransactionArchive;
      }else if (transactionType === 'cancellationApproval') {
        identifier = identifiers.filtersCancelledApproval
      }
    } else if (type === 'sort') {
      identifier = identifiers.filtersTransactionSort;
      if (transactionType === 'cancelledProcesses') {
        identifier = identifiers.filtersTransactionSortCancelled;
      } else if (transactionType === 'archiveProcesses') {
        identifier = identifiers.filtersTransactionSortArchive;
      }
    }
    saveToStorage(identifier, data);
  };

  saveLabsLocationFilterToStorage = data => {
    const { transactionType } = this.state;
    let identifier = identifiers.filtersLabsLocationTransaction;

    if (transactionType === 'cancelledProcesses') {
      identifier = identifiers.filtersLabsLocationTransactionCancelled;
    }
    if (transactionType === 'archiveProcesses') {
      identifier = identifiers.filtersLabsLocationTransactionArchive;
    }

    saveToStorage(identifier, data);
  };

  saveZmccFilterToStorage = data => {
    const { transactionType } = this.state;
    let identifier = identifiers.zmccTransactionListFilter;

    if (transactionType === 'cancelledProcesses') {
      identifier = identifiers.cancelledZmccFilters;
    }
    if (transactionType === 'archiveProcesses') {
      identifier = identifiers.archivedZmccFilters;
    }

    saveToStorage(identifier, data);
  };

  saveLabsLocationDataToStorage = data => {
    const { transactionType } = this.state;
    let identifier = identifiers.identifierLabsLocationSort;

    if (transactionType === 'cancelledProcesses') {
      identifier = identifiers.identifierLabsLocationSortCancelled;
    }
    if (transactionType === 'archiveProcesses') {
      identifier = identifiers.identifierLabsLocationSortArchive;
    }

    saveToStorage(identifier, data);
  };

  checkFiltersLanguage = () => {
    const { typeTransactions } = this.state;
    const isLabsLocationType = typeTransactions === 'l@l';
    const isZmccType = typeTransactions === 'zmcc';
    const newFilter = checkFiltersLanguage(this.state.data.filter, this.props, isLabsLocationType);
    const newZmccFilters = updateZmccFiltersOnCLangChange(this.state.zmccData.filter, this.props);
    const newLabsLocationFilter = checkFiltersLanguage(
      this.state.labsLocationData.filter,
      this.props,
      isLabsLocationType,
    );


    if (
      newFilter.loanTypeFullValue ||
      newFilter.currentPositionFullValue.length ||
      newFilter.transactionStatusesFullValue.length ||
      newFilter.conditionFullValue ||
      newFilter.dateTypeFullValue
    ) {
      this.setState(
        prevState => ({
          data: {
            ...prevState.data,
            filter: newFilter,
          },
        }),
        () => {
          this.saveToStorage('filter', this.state.data.filter);
        },
      );
    }

    if (isLabsLocationType && newLabsLocationFilter.transactionStatusesFullValue.length) {
      this.setState(
        prevState => ({
          labsLocationData: {
            ...prevState.labsLocationData,
            filter: newLabsLocationFilter,
          },
        }),
        () => {
          this.saveLabsLocationFilterToStorage(this.state.labsLocationData.filter);
        },
      );
    }
    if(isZmccType){
      this.setState(
        prevState => ({
          zmccData: {
            ...prevState.zmccData,
            filter: newZmccFilters,
          },
        }),
        () => {
          this.saveZmccFilterToStorage(this.state.zmccData.filter);
        },
      );
    }
  };

  handleSearchChange = e => {
    const { typeTransactions } = this.state;
    const { value } = e.target;
    const { fetchTransactionListData, fetchZmccTransactionList } = this.props;
    const isLabsLocationType = typeTransactions === 'l@l';
    const isZmccType = typeTransactions === 'zmcc'

    if (isLabsLocationType) {
      return this.debounceUpdateLabsLocationData({ value, fetchTransactionListData });
    }
    if (isZmccType) {
      return this.debounceUpdateZmccData({ value, fetchZmccTransactionList });
    }
    this.debounceUpdateData({ value, fetchTransactionListData });
  };

  debounceUpdateData = debounce(({ value, fetchTransactionListData }) => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.page = 1;
        newData.filter.searchTerm = value;
        return { data: newData };
      },
      () => {
        this.saveToStorage('filter', this.state.data.filter);
        fetchTransactionListData(this.state.data, this.state.typeTransactions, this.props.match.url.slice(1));
      },
    );
  }, 1000);

  debounceUpdateLabsLocationData = debounce(({ value, fetchTransactionListData }) => {
    this.setState(
      prevState => {
        const newLabsLocationData = { ...prevState.labsLocationData };
        newLabsLocationData.page = 1;
        newLabsLocationData.filter.searchTerm = value;
        return { labsLocationData: newLabsLocationData };
      },
      () => {
        this.saveLabsLocationFilterToStorage(this.state.labsLocationData.filter);
        fetchTransactionListData(
          this.state.labsLocationData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  }, 1000);

  debounceUpdateZmccData = debounce(({ value, fetchZmccTransactionList }) => {
    this.setState(
      prevState => {
        const newZmccData = { ...prevState.zmccData };
        newZmccData.page = 1;
        newZmccData.filter.searchTerm = value;
        return { zmccData: newZmccData };
      },
      () => {
        this.saveZmccFilterToStorage(this.state.zmccData.filter);
        fetchZmccTransactionList(
          this.state.zmccData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  }, 1000);

  handleFilterChange = (key, value, fullValue) => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.filter[key] = value && value === [] ? null : value;
        newData.filter[`${key}FullValue`] = fullValue && fullValue === [] ? null : fullValue;
        newData.page = 1;
        if (key === 'dateType') {
          newData.filter.dateRange = null;
        }
        return {
          data: newData,
        };
      },
      () => {
        this.saveToStorage('filter', this.state.data.filter);
        this.props.fetchTransactionListData(
          this.state.data,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  };

  handleLabsLocationFilterChange = (value, key, fullValue) => {
    this.setState(
      prevState => {
        const newLabsLOcationData = { ...prevState.labsLocationData };
        newLabsLOcationData.filter[key] = value && value === [] ? null : value;
        newLabsLOcationData.filter[`${key}FullValue`] = fullValue && fullValue === [] ? null : fullValue;
        newLabsLOcationData.page = 1;

        return {
          labsLocationData: newLabsLOcationData,
        };
      },
      () => {
        this.saveLabsLocationFilterToStorage(this.state.labsLocationData.filter);
        this.props.fetchTransactionListData(
          this.state.labsLocationData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  };

  handleZmccFilterChange = (value, key, fullValue) => {
    this.setState(
      prevState => {
        const newZmccData = { ...prevState.zmccData };
        newZmccData.filter[key] = value && value?.length === 0 ? null : value;
        newZmccData.filter[`${key}FullValue`] = fullValue && fullValue?.length === 0 ? null : fullValue;
        newZmccData.page = 1;
        if (key === 'dateType') {
          newZmccData.filter.dateRange = null;
        }
        return {
          zmccData: newZmccData,
        };
      },
      () => {
        this.saveZmccFilterToStorage(this.state.zmccData.filter);
        this.props.fetchZmccTransactionList(
          this.state.zmccData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  };

  resetFilters = () => {
    this.setState(
      prevState => ({
        data: {
          ...prevState.data,
          filter: { ...INITIAL_FILTER, searchTerm: prevState.data.searchTerm },
        },
      }),
      () => {
        this.saveToStorage('filter', this.state.data.filter);
        this.props.fetchTransactionListData(
          this.state.data,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  };

  resetFiltersZmcc = () => {
    this.setState(
      prevState => ({
        zmccData: {
          ...prevState.zmccData,
          filter: { ...INITIAL_ZMCC_FILTER, searchTerm: prevState.zmccData.searchTerm },
        },
      }),
      () => {
        this.saveZmccFilterToStorage(this.state.zmccData.filter);
        this.props.fetchZmccTransactionList(
          this.state.zmccData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  }

  resetLLFilters = () => {
    this.setState(
      prevState => ({
        labsLocationData: {
          ...prevState.labsLocationData,
          sort: {
            ...INITIAL_LABS_LOCATION_SORT,
          },
          filter: {
            ...INITIAL_LABS_LOCATION_FILTER,
          },
        },
      }),
      () => {
        this.saveToStorage('filter', this.state.data.filter);
        this.props.fetchTransactionListData(
          this.state.labsLocationData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        );
      },
    );
  }

  showOrHideFilter = () => {
    this.setState(prevState => ({ isShowFilter: !prevState.isShowFilter }));
  };

  handleSort = name => {
    const { fetchTransactionListData } = this.props;
    let data;
    this.setState(
      prevState => {
        const current = prevState.data.sort[name] ? (prevState.data.sort[name] === 'asc' ? 'desc' : null) : 'asc';
        let createdAt = prevState.data.sort.createdAt || 'desc';
        if (name === 'createdAt') {
          createdAt = prevState.data.sort[name] ? (prevState.data.sort[name] === 'asc' ? 'desc' : null) : 'asc';
        } else {
          createdAt = current ? null : createdAt;
        }
        data = {
          ...prevState.data,
          sort: {
            ...INITIAL_SORT,
            [name]: current,
            createdAt,
          },
        };
        return { data };
      },
      () => {
        this.saveToStorage('sort', this.state.data.sort);
        fetchTransactionListData(data, this.state.typeTransactions, this.props.match.url.slice(1));
      },
    );
  };

  handleLabsLocationSort = name => {
    const { fetchTransactionListData } = this.props;
    let labsLocationData;
    this.setState(
      prevState => {
        const current = prevState.labsLocationData.sort[name]
          ? prevState.labsLocationData.sort[name] === 'asc'
            ? 'desc'
            : null
          : 'asc';
        const createdAt = current ? null : 'desc';

        labsLocationData = {
          ...prevState.labsLocationData,
          sort: {
            ...INITIAL_SORT,
            [name]: current,
            createdAt,
          },
        };
        return { labsLocationData };
      },
      () => {
        this.saveLabsLocationDataToStorage(this.state.labsLocationData.sort);
        fetchTransactionListData(labsLocationData, this.state.typeTransactions, this.props.match.url.slice(1));
      },
    );
  };

  handlePageChange = page => {
    const { fetchTransactionListData } = this.props;

    this.setState(
      prevState => {
        const newData = { ...prevState.data };

        newData.page = page + 1;
        return { data: newData };
      },
      () => fetchTransactionListData(this.state.data, this.state.typeTransactions, this.props.match.url.slice(1)),
    );
  };

  handleLabsLocationPageChange = page => {
    const { fetchTransactionListData } = this.props;

    this.setState(
      prevState => {
        const newLabsLocationData = { ...prevState.labsLocationData };

        newLabsLocationData.page = page + 1;
        return { labsLocationData: newLabsLocationData };
      },
      () =>
        fetchTransactionListData(
          this.state.labsLocationData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        ),
    );
  };

  handleItemsPerPage = size => {
    const { fetchTransactionListData } = this.props;

    this.setState(
      prevState => {
        const newData = { ...prevState.data };

        newData.size = size;
        return { data: newData };
      },
      () => fetchTransactionListData(this.state.data, this.state.typeTransactions, this.props.match.url.slice(1)),
    );
  };

  handleLabsLocationItemsPerPage = size => {
    const { fetchTransactionListData } = this.props;

    this.setState(
      prevState => {
        const newLabsLocationData = { ...prevState.labsLocationData };

        newLabsLocationData.size = size;
        return { labsLocationData: newLabsLocationData };
      },
      () =>
        fetchTransactionListData(
          this.state.labsLocationData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        ),
    );
  };

  handleZmccPageChange = page => {
    const { fetchZmccTransactionList } = this.props;

    this.setState(
      prevState => {
        const newzmccData = { ...prevState.zmccData };

        newzmccData.page = page + 1;
        return { zmccData: newzmccData };
      },
      () =>
      fetchZmccTransactionList(
          this.state.zmccData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        ),
    );
  };

  handleZmccItemsPerPage = size => {
    const { fetchZmccTransactionList } = this.props;

    this.setState(
      prevState => {
        const newzmccData = { ...prevState.zmccData };

        newzmccData.size = size;
        return { zmccData: newzmccData };
      },
      () =>
      fetchZmccTransactionList(
          this.state.zmccData,
          this.state.typeTransactions,
          this.props.match.url.slice(1),
        ),
    );
  };

  downloadTransactionList = () => {
    $('#export-transaction-list').modal('show');
  };

  saveCancellationRequest = async (id, cancellationData, isFinalCancel, isRejectCancel) => {
    const { data, labsLocationData, typeTransactions, zmccData } = this.state;
    const { fetchCancellationRequest, fetchTransactionListData, updateTransactionStatus, fetchZmccTransactionList } = this.props;

    let url = URLS.cancelRequest.replace('{transactionId}', id);

    if (!isFinalCancel && !isRejectCancel) {
      url = `${url}?reasonForCancellation=${cancellationData}`;
      fetchCancellationRequest(url).then(res => {
        const type = typeTransactions === 'dla' ? data : labsLocationData;
        if (res.success) {
          if (typeTransactions === 'zmcc') {
          fetchZmccTransactionList(zmccData, this.state.typeTransactions, this.props.match.url.slice(1));
            
          }else{
          fetchTransactionListData(type, this.state.typeTransactions, this.props.match.url.slice(1));
          }
        }
      });
    } else if (isRejectCancel) {
      url = URLS.rejectCancelRequest.replace('{transactionId}', id);
      fetchCancellationRequest(url).then(res => {
        const type = typeTransactions === 'dla' ? data : labsLocationData;
        if (res.success) {
          if (typeTransactions === 'zmcc') {
            fetchZmccTransactionList(zmccData, this.state.typeTransactions, this.props.match.url.slice(1));
              
            }else{
            fetchTransactionListData(type, this.state.typeTransactions, this.props.match.url.slice(1));
            }
        }
      });
    } else if (isFinalCancel) {
      const { success } = await updateTransactionStatus(id, 'cancelled', true, null);
      if (success) {
        const type = typeTransactions === 'dla' ? data : labsLocationData;
        if (typeTransactions === 'zmcc') {
          fetchZmccTransactionList(zmccData, this.state.typeTransactions, this.props.match.url.slice(1));
        }
        else{
          fetchTransactionListData(type, this.state.typeTransactions, this.props.match.url.slice(1));
        }
      }
    }

    scrollToTop(500);
  };

  get bookingBreadcrumbData() {
    const { transactionType } = this.state;
    document.title = strings[transactionType];
    const data = [...bookingBreadcrumbData];
    data.push({ name: transactionType });
    return data;
  }

  createDataForSystemClassDropdown() {
    const { stock } = this.state.data.filter;
    const { categoriesAndSystems } = this.props;
    let arrayOfSystemClass = [];

    if (stock && stock.length > 0) {
      const { stockSystemClasses, systemClasses } = categoriesAndSystems;
      stock.forEach(stock => {
        const data = stockSystemClasses && stockSystemClasses[stock];
        if (data) {
          data.forEach(item => {
            arrayOfSystemClass.push(systemClasses.find(systemClass => item === systemClass.systemClassId));
          });
        }
      });
    } else {
      arrayOfSystemClass = categoriesAndSystems && categoriesAndSystems.systemClasses;
    }

    return Array.from(new Set(arrayOfSystemClass)).sort((a, b) => {
      const systemClassA = a.systemClassName.toUpperCase();
      const systemClassB = b.systemClassName.toUpperCase();

      return systemClassA > systemClassB ? 1 : -1;
    });
  }

  setAdjustDatesModalVisible = data => {
    const isC2CLoan = data && data.c2cLoan;
    if(isC2CLoan) {
      this.props.history.push(`/c2c/${data && data.transactionsId}/maintain-c2c-loan`);
    }
    else{
      this.setState({ transactionDetail: data }, () => {
        if ($(`#adjustDatesModal${data.transactionsId}`) && $(`#adjustDatesModal${data.transactionsId}`).modal) {
          $(`#adjustDatesModal${data.transactionsId}`).modal('show');
        }
        this.setState({ isVisibleAdjustDates: true });
      });
    }
  };

  setAdjustDatesModalInVisible = async isCloseAfterSave => {
    if (isCloseAfterSave) {
      await this.props.fetchTransactionListData(
        this.state.data,
        this.state.typeTransactions,
        this.props.match.url.slice(1),
        false,
      );
    }

    this.setState({ isVisibleAdjustDates: false, transactionDetail: null });
  };

  filterLoanTypes = () => {
    const { transactionType, loanTypes } = this.props;
    let transactionTypeToFilter = ['sapOrderCreation', 'goodReceipt', 'returnGoodsBooking'];
    let newLoanTypes = [...loanTypes];
    if (transactionTypeToFilter.includes(transactionType)) {
      newLoanTypes = newLoanTypes.filter(item => item.id !== 1);
    }

    return newLoanTypes;
  };

  filterLoanTypesForExport = () => {
    const loanTypeIds = [1, 2, 3];
    let newLoanTypes = this.filterLoanTypes();

    return newLoanTypes.filter(item => loanTypeIds.includes(item.id));
  };

  handlTypeChange = (_, type) => {
    const { fetchTransactionListData, match, fetchZmccTransactionList } = this.props;
    const typeOfData = type === 'dla' ? 'data' : 'labsLocationData';
    saveToStorage(identifiers.lastSlectedCheckboxInTrans, type);
    if (type === 'zmcc') {
      saveToStorage(identifiers.lastSlectedCheckboxInTrans, 'zmcc');
      saveToStorage(identifiers.isZmccTransactionsCheckboxActive, true);
      this.setState(
        prevState => {
          if (prevState.typeTransactions !== type) {
            return { typeTransactions: type };
          }
        },
        () => fetchZmccTransactionList(this.state['zmccData'], this.state.typeTransactions, match.url.slice(1)),
      );
      return;
    }
    saveToStorage(identifiers.isZmccTransactionsCheckboxActive, false);
    this.setState(
      prevState => {
        if (prevState.typeTransactions !== type) {
          return { typeTransactions: type };
        }
      },
      () => fetchTransactionListData(this.state[typeOfData], this.state.typeTransactions, match.url.slice(1)),
    );
  };

  openTeamBookingsPopup = () => {
    if ($('team-bookings-popup') && $('team-bookings-popup').modal) {
      $('#team-bookings-popup').modal('show');
    }
  };
  openSaveQueryPopup = () => {
    $('#save-query').modal('show');
  };
  openEditSaveQueryPopup = () => {
    $('#edit-on-my-query-result').modal('show');
  };
  render() {
    const { isShowFilter, data, labsLocationData, transactionType, transactionDetail, typeTransactions, zmccData } =
      this.state;
    const {
      isLoading,
      countries,
      transactionStatuses,
      displayActionMessage,
      type,
      message,
      returnDelayedTransactions,
      language,
      saveAdjustTransactionDates,
      exportTransactionList,
      usersList,
      addUsersToTeamBookings,
      teamMembers,
      removeUserFromTeamBookings,
      match,
      history,
      createMyQuery,
      saveFilterData,
    } = this.props;
    const categoriesAndSystems = this.createDataForSystemClassDropdown();
    const dateTypes = getDateTypes(this.props.language);
    const isLabsLocationType = typeTransactions === 'l@l';
    const isDlaType = typeTransactions === 'dla';
    const isZmccType = typeTransactions === 'zmcc';
    const { hasAllPermissions, hasDLAPermissions, hasOnlyLLPermissions } = this.getUserPermissions(transactionType);
    const { zmccPermission } = this.getUserPermissions(transactionType);
    const isLLEnabled = process.env.REACT_APP_IS_LABSLOCATION_ENABLED === 'true';
    const zmccDateTpyes = getZmccDateTypes(this.props.language);
    const hasDemo = hasAllPermissions || (hasDLAPermissions && isLLEnabled);
    const isZMCCEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';
    const hasLLAccess = hasAllPermissions || (hasOnlyLLPermissions == 0 ? false : true);
    const isSafeSearchResultOpen = history.location.search.includes('safeSearch');
    const isFilterUpdated =
      saveFilterData && JSON.stringify(zmccData.filter).replace(/\s/g, '') !== saveFilterData.search.replace(/\s/g, '');

    return (
      <div className='booking'>
        {displayActionMessage && <ActionMessage type={type} message={type === 'info' ? message : strings[message]} />}

        {transactionType === 'myBookings' && !!returnDelayedTransactions && returnDelayedTransactions.length > 0 && (
          <DelayMessage transactions={returnDelayedTransactions} />
        )}

        <div className='container-fluid mb-3'>
          {!isSafeSearchResultOpen && <Breadcrumbs data={this.bookingBreadcrumbData} />}
          {isSafeSearchResultOpen && <Breadcrumbs data={queryResultBreadcrumbData} />}
          <div className='row no-gutters align-items-center'>
            <div className='col-lg col-12 mb-lg-0 mb-3'>
              <div className='d-flex align-items-center'>
                {!isSafeSearchResultOpen && <h1 className='booking-title mr-3'>{strings[transactionType]}</h1>}
                {isSafeSearchResultOpen && (
                  <h1 className='booking-title mr-3'>{`${strings[transactionType]} > ${
                    saveFilterData?.searchName ? saveFilterData?.searchName : ''
                  }`}</h1>
                )}
              </div>
            </div>
            {transactionType === 'teamBookings' && (
              <div className='team-container'>
                {teamMembers &&
                  teamMembers.length > 0 &&
                  teamMembers.map((member, index) => {
                    if (index < 3) {
                      return (
                        <div
                          className='member-block'
                          key={member.accountId}
                          data-tooltip={`${member.firstName || ''} ${member.lastName || ''}`}
                        >
                          {`${(member.firstName && member.firstName[0]) || ''}${
                            (member.lastName && member.lastName[0]) || ''
                          }`}
                        </div>
                      );
                    }
                    return;
                  })}
                {teamMembers && teamMembers.length > 3 && (
                  <div className='member-gray-block'>{`+${teamMembers.length - 3}`}</div>
                )}
                <Button bgFill={false} iconName='plus' onClick={this.openTeamBookingsPopup} styleClass='team-button' />
              </div>
            )}
            <div className='col-lg-auto col mr-3 d-flex'>
              <div className='input-wrapper'>
                {isDlaType && <SearchField onChange={this.handleSearchChange} initialValue={data.filter.searchTerm} />}
                {isLabsLocationType && (
                  <SearchField onChange={this.handleSearchChange} initialValue={labsLocationData.filter.searchTerm} />
                )}
                {isZmccType && (
                  <SearchField onChange={this.handleSearchChange} initialValue={zmccData.filter?.searchTerm} />
                )}
              </div>
            </div>

            {isZmccType && !isSafeSearchResultOpen && (
              <div className='ml-3'>
                <Button
                  bgFill={false}
                  iconName='save'
                  value={strings.saveQuery}
                  onClick={this.openSaveQueryPopup}
                  styleClass='filter-button'
                />
                <SaveQueryPopup
                  id='save-query'
                  createMyQuery={createMyQuery}
                  transactionType={transactionType}
                  zmccData={this.state.zmccData}
                />
              </div>
            )}
            {isSafeSearchResultOpen && isFilterUpdated && (
              <div className='ml-3'>
                <Button
                  bgFill={false}
                  iconName='save'
                  value={strings.saveQuery}
                  onClick={this.openEditSaveQueryPopup}
                  styleClass='filter-button'
                />
                <EditMyQueryOnResultPage
                  id='edit-on-my-query-result'
                  data={zmccData}
                  dbSavedFilter={saveFilterData}
                  createMyQuery={createMyQuery}
                />
              </div>
            )}
          </div>

          {transactionType === 'teamBookings' && <div className='subject'>{strings.subjectTeamBookings}</div>}
          
          <div className='mt-4 adjust-hideFilter-and-type'>
            <div className='adjust-trans-type-button'>
              {((hasDemo && zmccPermission) || (hasDemo && hasLLAccess)) && !isSafeSearchResultOpen && (
                <button
                  className={`${isDlaType ? 'active-transaction-type' : 'type-button'}`}
                  onClick={() => this.handlTypeChange('', 'dla')}
                >
                  {strings.loanEquipment} <div className="icon-container"><Icon name='infoRounded' fill={"rgba(151, 151, 151, 1)"} width='15' height='15' viewBox='0 0 15 14' className='info-button cursor-pointer' /> <div className="tooltip">{strings.loanEquipmentMessage}</div></div>
                </button>
              )}
              {((hasDemo && zmccPermission) || (zmccPermission && hasLLAccess)) &&
                isZMCCEnabled &&
                !isSafeSearchResultOpen && (
                  <button
                    className={`${isZmccType ? 'active-transaction-type' : 'type-button'}`}
                    onClick={() => this.handlTypeChange('', 'zmcc')}
                  >
                    {strings.zeissLocations} <div className="icon-container"><Icon name='infoRounded' fill={"rgba(151, 151, 151, 1)"} width='15' height='15' viewBox='0 0 15 14' className='info-button cursor-pointer' /><div className="tooltip">{strings.zeissLocationsMessage}</div></div>
                  </button>
                )}
              {((hasDemo && hasLLAccess) || (zmccPermission && hasLLAccess)) && !isSafeSearchResultOpen && (
                <button
                  className={`${isLabsLocationType ? 'active-transaction-type' : 'type-button'}`}
                  onClick={() => this.handlTypeChange('', 'l@l')}
                >
                  {strings.partnerLocations} <div className="icon-container"><Icon name='infoRounded' fill={"rgba(151, 151, 151, 1)"} width='15' height='15' viewBox='0 0 15 14' className='info-button cursor-pointer' /><div className="tooltip">{strings.partnerLocationsMessage}</div></div>
                </button>
              )}
            </div>
            <div onClick={this.showOrHideFilter}>
              {isShowFilter && (
                <button className='type-button mr-2'>
                  <Icon name='hideFilter' width='20' height='20' viewBox='0 0 20 20' fill={'#0088D0'} />{' '}
                  <span className='ml-1 hideFilter-button'>{strings.hideFilter}</span>
                </button>
              )}
              {!isShowFilter && <button className='type-button hideFilter-button mr-2'>{strings.showAll}</button>}
            </div>
          </div>
          <hr style={{marginTop:'3px'}}></hr>
        </div>
        {isDlaType && (
          <DLATransactions
            dateTypes={dateTypes}
            categoriesAndSystems={categoriesAndSystems}
            isShowFilter={isShowFilter}
            data={data}
            transactionType={transactionType}
            handleFilterChange={this.handleFilterChange}
            resetFilters={this.resetFilters}
            history={history}
            match={match}
            setAdjustDatesModalVisible={this.setAdjustDatesModalVisible}
            handleSort={this.handleSort}
            saveCancellationRequest={this.saveCancellationRequest}
            downloadTransactionList={this.downloadTransactionList}
            handleItemsPerPage={this.handleItemsPerPage}
            handlePageChange={this.handlePageChange}
            transactionStatuses={transactionStatuses}
            loanTypes={this.filterLoanTypes()}
            typeTransactions={this.state.typeTransactions}
          />
        )}
        {isZmccType && (
          <ZMCCTransaction
          data={zmccData}
          isShowFilter={isShowFilter}
          handleItemsPerPage={this.handleZmccItemsPerPage}
          handlePageChange={this.handleZmccPageChange}
          handleFilterChange={this.handleZmccFilterChange}
          zmccDateTpyes={zmccDateTpyes}
          match={match}
          saveCancellationRequest={this.saveCancellationRequest}
          resetFiltersZmcc={this.resetFiltersZmcc}
          />
        )}
        {isLabsLocationType && (
          <LLTransactions
            isShowFilter={isShowFilter}
            categoriesAndSystems={categoriesAndSystems}
            handleFilterChange={this.handleLabsLocationFilterChange}
            resetFilters={this.resetLLFilters}
            data={labsLocationData}
            handleSort={this.handleLabsLocationSort}
            saveCancellationRequest={this.saveCancellationRequest}
            history={history}
            match={match}
            transactionStatuses={transactionStatuses}
            handleItemsPerPage={this.handleLabsLocationItemsPerPage}
            handlePageChange={this.handleLabsLocationPageChange}
            typeTransactions={this.state.typeTransactions}
          />
        )}
        {transactionDetail && (
          <AdjustDates
            id={transactionDetail.transactionsId ? `adjustDatesModal${transactionDetail.transactionsId}` : ''}
            adjustTransactionDates={saveAdjustTransactionDates}
            enableSave={true}
            transactionDetails={this.state.transactionDetail || {}}
            changeVisible={this.setAdjustDatesModalInVisible}
            visible={this.state.isVisibleAdjustDates}
          />
        )}
        <ExportTransactionList
          id='export-transaction-list'
          countries={countries}
          categoriesAndSystems={categoriesAndSystems}
          loanTypes={this.filterLoanTypesForExport()}
          filters={data.filter}
          exportTransactionList={exportTransactionList}
          searchType={this.props.match.url.slice(1)}
          transactionType={transactionType}
          language={language}
          changeLoader={this.props.changeLoader}
        />
        
        <ContinueUseApplicationPopup
          id={'continue-use-app'}
          changeLoader={this.props.changeLoader}
        />
        <TeamBookings
          id='team-bookings-popup'
          usersList={usersList}
          addUsersToTeamBookings={addUsersToTeamBookings}
          members={teamMembers}
          removeUserFromTeamBookings={removeUserFromTeamBookings}
          data={data}
          transactionType={match.url.slice(1)}
        />
        {isLoading && <div className='loader' />}
      </div>
    );
  }
}

export default Booking;