import React, { useEffect } from 'react';
import { get } from 'lodash';
import * as $ from 'jquery';

import Button from 'library/common/commonComponents/Button/Button';
import { connect } from 'react-redux';
import { getIconByCondition, getIconByStatus } from 'library/utilities/getStatusAndConditionIcons';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import Checkbox from 'library/common/commonComponents/Checkbox/Checkbox';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { createSapErpLoanOrderForKit, returnSapErpLoanOrderForKit } from '../KitDetailsAction';

import { NavLink } from 'react-router-dom';
import CreateLoanOrderDetailsPopup from './CreateLoanOrderDetailsPopUp.component';
import KeyValueDetails from 'library/common/commonComponents/KeyValueDetails/KeyValueDetails';

const Information = ({
  kitDetail,
  history,
  isEditable,
  user,
  toggleActionMessage,
  createSapErpLoanOrderForKit,
  returnSapErpLoanOrderForKit,
  workFlowQuestions,
  activeTransactionLoanOrderData,
}) => {
  const transactionLoanOrder = activeTransactionLoanOrderData && activeTransactionLoanOrderData;
  const showTransactionLoanOder = transactionLoanOrder && transactionLoanOrder !== undefined && transactionLoanOrder.transactionsId !== 0 && (transactionLoanOrder.sapErp !== "" && transactionLoanOrder.sapeErp !== null);
  const istransactionLoanOderisCompleted = transactionLoanOrder && transactionLoanOrder !== undefined && transactionLoanOrder.transactionsId !== 0 && (transactionLoanOrder.sapErp !== "" && transactionLoanOrder.sapeErp !== null) && (transactionLoanOrder.sapReturnLoanNumber !== null && transactionLoanOrder.sapLoanOrderStatus === "RETURNED");
  const currentPosition = get(kitDetail, 'transactionPosition.transactionPositionTranslated[0].positionText');
  const businessUnit = get(kitDetail, 'businessUnit.name');
  const conditionString = get(
    kitDetail,
    'kitCondition.kitConditionTranslations[0].translatedCondition',
    '',
  ).toLowerCase();
  const organizationName = get(kitDetail, 'organizationName');
  const showedOrganizationName = organizationName ? `[${organizationName}]` : '';
  const division = get(kitDetail, 'divisions.divisionsTranslated[0].name');
  const kitReparation =
    kitDetail.kitReparation &&
    kitDetail.kitReparation.length &&
    kitDetail.kitReparation.find(item => item.isReparationTimeActive);

  const isHavingRole = (roles, stockId) => {
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      return roles.some(role => {
        return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId);
      });
    }
    return false;
  };
  const RolesKitLevelLoanOrder = ['Stock Controller', 'Inbound staff', 'Outbound staff', 'Dispatcher'];
  const isAdminOrHasLoanOrderAccess =
    (user && user.admin) ||
    isHavingRole(RolesKitLevelLoanOrder, kitDetail && kitDetail.stockInformation && kitDetail.stockInformation.id);

  const hasERPActiavted = () => {
    const workFlowCategoryData =
      workFlowQuestions &&
      workFlowQuestions.stockWorkflowDTOS &&
      workFlowQuestions.stockWorkflowDTOS[4] &&
      workFlowQuestions.stockWorkflowDTOS[4]['stockWorkflowSettingDTO'];
    const isWorkflowErp = workFlowCategoryData && workFlowCategoryData.find(item => item.categoryQuestionId === 21);
    const isWorkflowExternal =
      workFlowCategoryData && workFlowCategoryData.find(item => item.categoryQuestionId === 26);
    const finalValue =
      isWorkflowErp != null && isWorkflowExternal != null
        ? isWorkflowErp.categoryAnswer === 'yes' && isWorkflowExternal.categoryAnswer === 'yes'
        : false;
    return finalValue;
  };

  const validateLoanOrder = loanOrderNumber => {
    if (
      loanOrderNumber &&
      loanOrderNumber.length == 10 &&
      loanOrderNumber.startsWith('1') &&
      /^\d+$/.test(loanOrderNumber)
    ) {
      return true;
    }
    return false;
  };

  const hasLoanOrderValue = kitDetail && !!kitDetail.sapERP && kitDetail.loanOrderAddedAs !== "FROM_TRANSACTION_MANUALLY";
  const isloanOrderNumberValid = kitDetail && validateLoanOrder(kitDetail.sapERP);

  const showCreateLoanOrderButton =
    kitDetail &&
    kitDetail.stockInformation &&
    hasERPActiavted(kitDetail) &&
    isAdminOrHasLoanOrderAccess &&
    (!hasLoanOrderValue || kitDetail.sapLoanOrderStatus === 'RETURNED');
  const showReturnLoanOrderButton =
    kitDetail &&
    kitDetail.stockInformation &&
    hasERPActiavted(kitDetail) &&
    ((isloanOrderNumberValid && kitDetail.sapLoanOrderStatus === null) || kitDetail.sapLoanOrderStatus === 'CREATED');
  const hidingCreateLoanOrder =
    (kitDetail && kitDetail.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE') ||
    kitDetail.sapLoanOrderStatus === 'CREATED';

  const createLoanOrder = () => {
    const divisionsMissing = kitDetail && !!kitDetail.divisions;
    const itemNumberMissing = kitDetail && !!kitDetail.itemNumber;
    if (!divisionsMissing || !itemNumberMissing) {
      toggleActionMessage(true, 'error', 'divisionOrItemNumberMissing');
      scrollToTop(500);
      return;
    }
    const kitId = kitDetail && kitDetail.kitInformationId;
    createSapErpLoanOrderForKit(kitId);
  };

  const returnLoanOrder = () => {
    const divisionsMissing = kitDetail && !!kitDetail.divisions;
    const itemNumberMissing = kitDetail && !!kitDetail.itemNumber;
    if (!divisionsMissing || !itemNumberMissing) {
      toggleActionMessage(true, 'error', 'returnLoanOrderDivisonMissing');
      scrollToTop(500);
      return;
    }
    const kitId = kitDetail && kitDetail.kitInformationId;
    returnSapErpLoanOrderForKit(kitId);
  };

  const onResendRequest = () => {
    $('#load-order-modal').modal('show');
  };

  const foeShowingLoanOrder = () => {
    if (showTransactionLoanOder) {
      const value = `${(transactionLoanOrder.sapLoanOrderStatus === 'CREATED' || transactionLoanOrder.sapLoanOrderStatus === 'WAITING_FOR_ZLA') ? 'ZLEI: ' : ((transactionLoanOrder.sapLoanOrderStatus === 'RETURNED') ? 'ZLA:' : 'ZLEI:')} ${(kitDetail.sapERP || '')} ${kitDetail.sapERP && "("+strings.transactionId+":"} `
      return value
    }

    if (!showTransactionLoanOder) {
      return kitDetail.sapERP || '-';
    }

    if (kitDetail?.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE') {
      return `${kitDetail.sapERP} ${strings.waitingForLoanOrder}`
    }

    if (kitDetail.sapLoanOrderStatus === 'WAITING_FOR_ZLA') {
      return `${kitDetail.sapERP} WAITING_FOR_ZLA`
    }

  }
  const showDisvisionAndItemNumber =  workFlowQuestions && workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS[4] && workFlowQuestions.stockWorkflowDTOS[4]['stockWorkflowSettingDTO'][0].categoryAnswer === 'yes';
  const isRefusmentActivated = workFlowQuestions && workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS[1] && workFlowQuestions.stockWorkflowDTOS[1]['stockWorkflowSettingDTO'][8].categoryAnswer === 'yes'
  const packageDimensionsDetails =[
    { key: strings.packageDimensions, value: kitDetail.packageDimensions || 'No package dimensions available'}];
  const mainComponentDeatiles = [
    { key: strings.categorySystem, value: kitDetail.systemClass?.systemClassName },
    { key: strings.systemMainComponent, value: kitDetail.systemMainComponent?.systemMainComponentName  || '-' },
    { key: strings.serialNumber, value: kitDetail.serialNumber },
    { key: strings.yearOfManufacture, value: kitDetail.dateOfManufacture ? getDateInFormat('YYYY', kitDetail.dateOfManufacture) : '-' },
    { key: strings.monthOfManufacture, value: kitDetail.dateOfManufacture ? getDateInFormat('MMMM', kitDetail.dateOfManufacture) : '-' },
    { key: strings.countryOfOrigin, value: kitDetail.country ? kitDetail.country?.name : '-' },
    { key: strings.commodityCode, value: kitDetail.commodityCode ? kitDetail.commodityCode : '-' },
    { key: strings.ibaseText, value: kitDetail.ibase ? kitDetail.ibase : '-' },
    { key: strings.marketingMaterialText, value: kitDetail?.marketingMaterial === true ? strings.yes : strings.no },
    { key: strings.supportingAsset, value: kitDetail?.supportingAsset === true ? strings.yes : strings.no },
    { key: strings.fixedAsset, value: kitDetail?.fixedAsset === true ? strings.yes : strings.no },
    { key: strings.dateCreated, value: kitDetail.dateCreated ? `${getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated)} ${kitDetail.createdBy ? strings.by + " " + kitDetail.createdBy.firstName + " " + kitDetail.createdBy.lastName : ""}` : '-' },
    { key: strings.dateModified, value: kitDetail.updatedAt && kitDetail.updatedBy ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${kitDetail.updatedBy ? strings.by + " " + kitDetail.updatedBy.firstName + " " + kitDetail.updatedBy.lastName : ""}` : '-' },
  ]

  if (showDisvisionAndItemNumber) {
    mainComponentDeatiles.push(
      { key: strings.division, value: division ?division : '-' },
      { key: strings.itemNumber, value: kitDetail?.itemNumber ? kitDetail.itemNumber : '-' },
    )
  }
  if(isRefusmentActivated){
    mainComponentDeatiles.push(
      { key: strings.materialNumberOfTheStand, value: kitDetail?.materialNumberOfStand ? kitDetail.materialNumberOfStand : '-' },
    )
  }

  const defaultPeriodsDeatiles = [
    { key: strings.delivery, value: kitDetail.deliveryDays === 0 ? kitDetail.deliveryDays : kitDetail.deliveryDays || '-' },
    { key: strings.returnDelivery, value: kitDetail.returnDeliveryDays === 0 ? kitDetail.returnDeliveryDays : kitDetail.returnDeliveryDays || '-' },
    { key: strings.reconditioning, value: kitDetail.reconditioningDeliveryDays === 0 ? kitDetail.reconditioningDeliveryDays : kitDetail.reconditioningDeliveryDays || '-' },
    { key: strings.maxLoanDuration, value: kitDetail.maxLoanDuration === 0 ? kitDetail.maxLoanDuration : kitDetail.maxLoanDuration || '-' },
  ]

  const availabilityDetails = [
    { key: kitDetail.availabilityFrom && kitDetail.availabilityTo ? strings.availabilityPeriod : strings.availableFrom, value: kitDetail.availabilityFrom
      ? getDateInFormat('DD.MM.YYYY', kitDetail.availabilityFrom, kitDetail.availabilityTo)
      : '-' }
  ]
  const assignmentDeatiles = [
    { key: strings.countryLand, value: kitDetail?.stockInformation ? kitDetail.stockInformation.country?.name : '-' },
    { key: strings.location, value: kitDetail?.stockInformation ? kitDetail.stockInformation.locationName : '-' },
    { key: strings.storageLocation, value: kitDetail?.storageLocation ? kitDetail.storageLocation : '-' },
    { key: strings.kitLoanOrderTextUpdated, value: foeShowingLoanOrder() ||  '-', buttonConfig : true ? {
      label :  showCreateLoanOrderButton && !hidingCreateLoanOrder && istransactionLoanOderisCompleted ? strings.createLoanOrder :
      kitDetail?.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE' ? strings.viewDetails: showReturnLoanOrderButton  && !showTransactionLoanOder ? strings.returnLoanOrder :'' ,
      onClick : kitDetail?.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE' ?onResendRequest : showReturnLoanOrderButton  && !showTransactionLoanOder ? () => returnLoanOrder(): createLoanOrder,
      icon : kitDetail?.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE' ? 'save' : 'plus',
    } : {},

    hasButton: showCreateLoanOrderButton && !hidingCreateLoanOrder && istransactionLoanOderisCompleted ? true :
    kitDetail && kitDetail.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE' ? true :
    showReturnLoanOrderButton  && !showTransactionLoanOder ? true :
    false,
    hasNavLink: showTransactionLoanOder ? true : false, link: `/transactions/${transactionLoanOrder.transactionsId}`, navLinkValue: `${kitDetail.sapERP && `${transactionLoanOrder.transactionsId}` || ''}`, extravalue: `${kitDetail.sapERP &&  ")"}`
  },
    { key: strings.walkOffSalesOrder, value: kitDetail?.walkOffSalesOrder ? kitDetail.walkOffSalesOrder : '-' },
    { key: strings.businessUnit, value: businessUnit },
    { key: strings.firstSalesOrder, value: kitDetail?.firstSalesOrder ? kitDetail.firstSalesOrder : '-' },

  ]
  return (
    <div className='information-container'>
      <div className='container-fluid'>
        <div className='mt-3'>
        <KeyValueDetails details={[]} sectionTitle={strings.kitStatus}>
          <div className='col-6'>
            <div className='key-header mb-2'>{strings.status}</div>
            <div className='value d-flex mb-3 flex-wrap pt-1'>
              {kitDetail.kitStatuses?.map(
                item =>
                  item.kitStatusesTranslated &&
                  item.kitStatusId !== 15 && (
                    <div key={item.kitStatusId} className='mr-4 mb-2'>
                      {getIconByStatus(item, true, 11)}
                    </div>
                  ),
              )}
              {conditionString && (
                <div key={kitDetail.kitCondition.kitConditionId} className='mr-4 mb-2'>
                  {getIconByCondition(kitDetail.kitCondition, true, 24)}
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='key-header'>Dispatch Status</div>
            <div className='description-container word-wrap mb-3 value pt-1'>
              -
            </div>
          </div>
          <div className='col-6'>
            <div className='key-header'>{strings.currentPosition}</div>
            <div className='description-container word-wrap mb-3 value pt-1'>
            {currentPosition ? currentPosition.replace(/warehouse|Lager|DemoLager/gi, strings.emailMessageCategoryHedaReplace) : '-'} {showedOrganizationName}
            </div>
          </div>
          <div className='col-6'>
            <div className='key-header'>{strings.commentText}</div>
            <div className='description-container word-wrap mb-3 value pt-1'>
            {kitDetail.comment || strings.noCommentAvailable}
            </div>
          </div>
        </KeyValueDetails>
        <KeyValueDetails details={assignmentDeatiles} sectionTitle={strings.assignment} styleColClass='col-3' />
        <KeyValueDetails details={mainComponentDeatiles} sectionTitle={strings.mainComponent} styleColClass='col-3' />
        <KeyValueDetails details={defaultPeriodsDeatiles} sectionTitle={strings.defaultPeriods} styleColClass='col-3' />
        <KeyValueDetails details={availabilityDetails} sectionTitle={strings.availability} styleColClass='col-5' />
        <KeyValueDetails details={packageDimensionsDetails} sectionTitle={strings.packageDimensions} styleColClass='col-5' />
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: `/edit-kit/${kitDetail.kitInformationId}`,
                state: { edit: true, tab: 'information' },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, kitDetailsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  activeTransactionLoanOrderData: kitDetailsReducer.activeTransactionLoanOrderData,
  workFlowQuestions: kitDetailsReducer.workFlowQuestions
});

export default connect(mapStateToProps, {
  toggleActionMessage,
  createSapErpLoanOrderForKit,
  returnSapErpLoanOrderForKit,
})(Information);
