import React, { useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import Accessories from 'library/common/commonComponents/AccessoriesForm';
import Components from 'library/common/commonComponents/ComponentsForm';

import InformationTab from './Components/InformationTab';
import { FETCH_ZMCC_BU_CONDITIONS } from './CreateZmccKit.constants';
import {FETCH_BUSINESS_UNIT_LIST} from 'library/common/commonConstants/storeConstants';
import VRDetailsForm from "../KitDetails/Components/VrDetails/VrDetails.componentForm";

export const useCreateZmccKit = props => {
  const {
    freeAccessoriesForm,
    furtherImportantComponentForm,
    salesComponentForm,
    location,
    updateForm,
    fetchStocks,
    createZmccKit,
    kitDetails,
    kitInformationId,
    kitAttachments,
    kitAttachmentsToDelete,
    kitImages,
    kitImagesToDelete,
    fetchMasterData,
    masterData,
    fetchZMCCKitDetails,
    resetForm,
    fetchSystemClasses,
    history,
    match,
    informationForm,
    rmsZMCCBuData,
    rmsBuData,
    rooms,
    fetchUsersOfBusinessUnit,
    zmccUsers,
     language,
     vrDetalisForm
  } = props;
  const isCopy = location.pathname.includes('copy');
  const isEdit = location.pathname.includes('edit');
  const [maxFilesError, setMaxFilesError] = useState("")

  useEffect(() => {
    return () => resetForm();
  }, [resetForm]);

  useEffect(() => {

    informationForm["formData"] = {
      ...informationForm.formData,
      businessUnit: rmsZMCCBuData && rmsZMCCBuData["id"],
      businessUnitFullValue: rmsZMCCBuData,
    }
    rmsBuData && fetchSystemClasses(rmsBuData["id"]);
  }, [masterData]);

  useEffect(() => {
    rmsZMCCBuData && fetchUsersOfBusinessUnit(rmsZMCCBuData["id"]);
  }, [rmsZMCCBuData]);

  useEffect(() => {
    
    fetchMasterData(URLS.kitCondition, FETCH_ZMCC_BU_CONDITIONS);
    fetchMasterData(URLS.businessUnitList, FETCH_BUSINESS_UNIT_LIST);
  }, [fetchStocks, fetchSystemClasses, fetchMasterData, fetchUsersOfBusinessUnit, language]);

  useEffect(() =>{
    rmsZMCCBuData &&  fetchStocks(rmsZMCCBuData["id"]);
  }, [rmsZMCCBuData])

  useEffect(() => {
    if (isEdit || isCopy) {
    fetchZMCCKitDetails(match.params.id, isCopy);
    }
  }, [match.params.id,fetchZMCCKitDetails, isCopy, isEdit]);

  const getTitle = () => {
    if (isEdit) return strings.maintainZmccKit;
    if (isCopy) return strings.copyZmccKit;
    return strings.createZMCCKit;
  };

  const addKitImageFile = files => {
    const newImages = [...kitImages, ...files];
    setMaxFilesError("");
    updateForm({ kitImages: newImages });
  };

  const removeKitImageFile = index => {
    const fileToRemove = kitImages[index];
    let newKitImagesToDelete = [...kitImagesToDelete];
    setMaxFilesError("");
    if (fileToRemove.kitImagesId) {
      newKitImagesToDelete.push(fileToRemove.kitImagesId);
    }
    const newKitImages = kitImages.filter((_, i) => i !== index);
    updateForm({ kitImages: newKitImages, kitImagesToDelete: newKitImagesToDelete });
  };

  const handleForm = ({ values, formName, field }) => {
    if (field === 'kitCondition' && values.kitCondition === 5) {
      return openInRefurbishmentPopup();
    }

    if (field === 'systemClass') {
      return updateForm({
        [formName]: {
          ...props[formName],
          formData: {
            ...props[formName].formData,
            ...values,
            systemMainComponent: null,
            systemMainComponentFullValue: null,
          },
        },
      });
    }
    updateForm({
      [formName]: {
        ...props[formName],
        formData: {
          ...props[formName].formData,
          ...values,
        },
      },
    });
  };

  const saveKit = async () => {
    const { success, kitInformationId } = await createZmccKit(isCopy);

    if (success) {
      history.replace(`/kit_details/${kitInformationId}`);
    } 
  };

  const cancelCreateKit = () => {
    history.replace('/kits');
  };

  const openInRefurbishmentPopup = () => {
    $('#inRefurbishmentStatus').modal('show');
  };

  const generateTabs = useCallback(() => [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => (
        <InformationTab
          cancelCreateKit={cancelCreateKit}
          saveKit={saveKit}
          handleForm={handleForm}
          updateForm={updateForm}
          rooms={rooms}
          zmccUsers={zmccUsers}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: () => (
        <Components
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ furtherImportantComponentForm, salesComponentForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
          isOptional={true}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: () => (
        <Accessories
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ freeAccessoriesForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
        />
      ),
    },
    {
      id: 4,
      tabTitle: 'attachments',
      className: '',
      render: () => (
        <Attachments
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ kitAttachments, kitAttachmentsToDelete }}
          cancelCreateKit={cancelCreateKit}
          kitInformationId={kitInformationId}
          handleForm={updateForm}
          hasVideo={true}
          newVersionUi={true}
          uploaderStyle={"kit-attachments-bordered"}
          enhancedUi={true}
          sectionWrapper={true}
        />
      ),
    },
  ], [cancelCreateKit, freeAccessoriesForm, furtherImportantComponentForm, handleForm, history, kitAttachments, kitAttachmentsToDelete, kitDetails, kitInformationId, salesComponentForm, saveKit, updateForm]);

  const generateTabsForVrDemoKit = useCallback(() => [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => (
        <InformationTab
          cancelCreateKit={cancelCreateKit}
          saveKit={saveKit}
          handleForm={handleForm}
          updateForm={updateForm}
          rooms={rooms}
          zmccUsers={zmccUsers}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: () => (
        <Components
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ furtherImportantComponentForm, salesComponentForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
          isOptional={true}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: () => (
        <Accessories
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ freeAccessoriesForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
        />
      ),
    },
    {
        id: 4,
        tabTitle: 'vrDetails',
        className: '',
        render: () => (
          <VRDetailsForm 
            formValue={{vrDetalisForm}}
            kitDetail={kitDetails}
            history={history} 
            saveKit={saveKit}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
          />
        ),
      },
    {
      id: 5,
      tabTitle: 'attachments',
      className: '',
      render: () => (
        <Attachments
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ kitAttachments, kitAttachmentsToDelete }}
          cancelCreateKit={cancelCreateKit}
          kitInformationId={kitInformationId}
          handleForm={updateForm}
          hasVideo={true}
        />
      ),
    },
  ], [cancelCreateKit, freeAccessoriesForm, furtherImportantComponentForm, handleForm, history, kitAttachments, kitAttachmentsToDelete, kitDetails, kitInformationId, salesComponentForm, saveKit, updateForm]);

  const title = getTitle();
  const tabs =  kitDetails && kitDetails.systemClass && kitDetails.systemClass.systemClassName && kitDetails.systemClass.systemClassName.toLowerCase() === 'VR Demo Kit'.toLowerCase() ? generateTabsForVrDemoKit() : generateTabs();
  
  const onMaxFileReached = (maxFileReached, multipleUploads) => {
    if(maxFileReached){
      setMaxFilesError(strings.maxFilesErrorMessageZmcc.replace("{number}", 6))
    }
  }

  return {
    title,
    tabs,
    addKitImageFile,
    removeKitImageFile,
    handleForm,
    getTitle,
    saveKit,
    cancelCreateKit,
    onMaxFileReached,
    maxFilesError,
  };
}
