import React, { Component } from 'react';
import { get } from 'lodash';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Loader from 'library/common/commonComponents/Loader';
import Message from 'library/common/commonComponents/Message';

class Messages extends Component {
  state = {
    loading: true,
    loadMoreComments: false,
    loadMoreDamage: false,
    width: window.innerWidth,
  };

  componentDidMount() {
    const { actionTypes, match } = this.props;
    window.addEventListener('resize', () => this.updateDimensions());
    this.props
      .fetchKitDetailsMessages({
        kitId: match.params.id,
        page: 1,
        actionTypes,
      })
      .then(res => {
        this.setState({ loading: false });
      });

    this.props
      .fetchKitDetailsComments({
        kitId: match.params.id,
        page: 1,
        actionTypes,
      })
      .then(res => {
        this.setState({ loading: false });
      });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  onLoadMore = type => () => {
    let page = 1;
    if (type === 'comment') {
      this.setState({ loadMoreComments: true }, () => {
        page = get(this.props, 'commentsData.number', 1);
        this.props.fetchKitDetailsComments({ kitId: this.props.match.params.id, page: page + 1 }).then(res => {
          this.setState({ loadMoreComments: false });
        });
      });
    } else {
      this.setState({ loadMoreDamage: true }, () => {
        page = get(this.props, 'damageLossReportData.number', 1);
        this.props
          .fetchKitDetailsMessages({
            kitId: this.props.match.params.id,
            page: page + 1,
          })
          .then(res => {
            this.setState({ loadMoreDamage: false });
          });
      });
    }
  };

  generateRows(data) {
    const first = [];
    const second = [];
    if (this.state.width < 990) {
      return { first: data };
    }
    data.forEach((element, i) => {
      if (i % 2 === 0) {
        first.push(element);
      } else {
        second.push(element);
      }
    });
    return { first, second };
  }

  renderMessages(data, type) {
    let title = strings.messages;
    let emptyMessage = strings.noMessage;
    let dataTest = 'transaction-details-comments';
    let dataTestButton = 'comments-load-more-button';
    let stateField = 'loadMoreComments';
    if (type === 'damage-loss-report') {
      title = strings.damageOrLossReports;
      emptyMessage = strings.noMessageDamage;
      stateField = 'loadMoreDamage';
      dataTest = 'transaction-details-damage-loss-report';
      dataTestButton = 'damage-load-more-button';
    }
    return (
      <div className='col col-md-6 col-12 pr-md-3'>
        <div className='new-style-heading mt-0 mb-3'>
          {title} {data && data.totalElements && `(${data.totalElements})`}
        </div>
        {data && data.content && !!data.content.length ? (
          data.content.map(message => <Message dataTest={dataTest} key={message.id} message={message} />)
        ) : (
          <div className='text-left components-description' data-test='empty-message'>
            {emptyMessage}
          </div>
        )}
        {data && data.content && data.content.length && !data.last && (
          <div className='d-flex flex-row justify-content-center'>
            <Button
              dataTest={dataTestButton}
              bgFill={false}
              iconName='refresh'
              loading={this.state[stateField]}
              value={strings.loadMoreMessages}
              styleClass='btn-lighter app-btn-bordered message-load-more-button'
              onClick={this.onLoadMore(type)}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const { commentsData, damageLossReportData, kitDetail } = this.props;
    return (
      <div className='container-fluid pt-3 pb-5'>
        {(damageLossReportData && damageLossReportData.content && damageLossReportData.content.length) || !loading ? (
          <div className='row no-gutters align-items-start'>
            {this.renderMessages(commentsData, 'comment')}
            {!kitDetail?.isZMCCKit && this.renderMessages(damageLossReportData, 'damage-loss-report')}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default Messages;