import * as $ from 'jquery';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { StockControllerDelete } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import Axios from 'axios';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { saveAs } from 'file-saver';
import strings from 'resources/locales/Translate';
import debounce from 'lodash/debounce';


export const useIqsAttachments = ({ getIqsSscAttachmentList, stockId, deleteIqsSscDeleteAttachment, canEditStock, changeLoader }) => {
  const [filters, setFilters] = useState({
    attachmentCategory: null,
    attachmentCategoryFullValue: null,
    pageNumber: 0,
    pageSize: 30,
    dateRange: null,
  });

  const [searchText, setSearchText] = useState('')
  useEffect(() => {
    getIqsSscAttachmentList(stockId);
  }, []);

  const onClickUpload = () => {
    $(`#iqs-upload-attachment-modal`).modal('show');
  };

  const downloadFile = (item) => {
    const url = process.env.REACT_APP_BASE_URL + URLS.iqsSscDownloadAttchment(item.stockImagesId);
    downloadStream(url, item);
  }

 const downloadStream = (url, file) => {
  changeLoader(true)
    const token = fetchFromStorage(identifiers.token);
    Axios(url, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        token: `${token}`,
      },
    })
      .then(response => {
        const newFile = new Blob([response.data], { type: file.type });
        saveAs(newFile, file.file);
        changeLoader(false);
      })
      .catch(error => {
        changeLoader(false)
        console.log(error);
      });
  };

  const getCols = () => [
    {
      title: strings.scheduleInputTitle,
      name: 'scheduleInputTitle',
      isSort: false,
      render: item => {
        return <span>{item.title}</span>;
      },
    },
    {
      title: strings.category,
      name: 'category',
      isSort: false,
      render: item => {
        return <span>{item.attachmentCategory}</span>;
      },
    },
    {
      title: strings.uploadedDate,
      name: 'uploadedDate',
      isSort: false,
      render: item => {
        return <span>{item.uploadedDate}</span>;
      },
    },
    {
      title: strings.uploadedBy,
      name: 'uploadedBy',
      isSort: false,
      render: item => {
        return <span>{item.uploader}</span>;
      },
    },
    {
      title: strings.comment,
      name: 'comment',
      isSort: false,
      render: item => {
        return <span>{item.comment}</span>;
      },
    },
    {
      title: '',
      name: 'download',
      isSort: false,
      render: item => {
        return (
          <span className='adjust-delete-edit-icon cursor-pointer'>
              <>
                <div onClick={()=>downloadFile(item)}>
                  <Icon name='newVersionDownload' height={17} width={17} viewBox={'0 0 16 13'} fill={'#0088D0'} />
                </div>
                {canEditStock &&<div onClick={() => deleteIqsSscDeleteAttachment(stockId, item.stockImagesId)}>
                  <StockControllerDelete fill='#0088D0' />
                </div>}
              </>
          </span>
        );
      },
    },
  ];
  
  const handleDateChange = useCallback(dateRange => {
    let newDateRange = null;

    if (dateRange) {
      newDateRange = [...dateRange];
      const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
      const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
      newDateRange[0] = dateRangeStart;
      newDateRange[1] = dateRangeEnd;
    }

    setFilters({ ...filters, dateRange });
    getIqsSscAttachmentList(stockId, { ...filters, dateRange });
  }, [filters, setFilters]);

  const onChange = useCallback((id, field, value) => {
    const idValue = id;
    const newFilters = {
      ...filters,
      [field]: idValue,
      [`${field}FullValue`]: value,
    }
    
    setFilters(newFilters);
    getIqsSscAttachmentList(stockId, newFilters);
  } , [filters, setFilters]);

  const onInputChange = (value) => {
    setSearchText(value);
    debounceUpdateData({value});
  }

  const debounceUpdateData = debounce(({ value }) => {
   getIqsSscAttachmentList(stockId, {...filters, searchText: value});
  }, 1000);

  return {
    onClickUpload,
    getCols,
    onChange,
    filters,
    handleDateChange,
    onInputChange,
    searchText
  };
};
