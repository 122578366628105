import { useEffect, useState } from 'react';
import { customerBackgroundModel, customerDetilsModel, primaryPointOfContactModel } from './CustomerInformation.constants';
import Validators from 'library/utilities/Validators';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { mapFormFieldAndValue } from 'library/utilities/mapFormFieldAndValue';

export const useCustomerInformation = (props) => {
  const {customerDetilsForm, primaryPointOfContactForm, customerBackgroundForm} = props.formValue
  const customerBackModel = () => {
    const model = [...customerBackgroundModel];
    model[0].options = props.marketSegments;
    model[4].options = props.realtionshipWithZeiss;
    model[1].options = props.marketSubSegments;
    model[5].options = props.sbuTypes;
    return model;
  };

  const getCustomerDetailsModel = () => {
    let model = [...customerDetilsModel];
    let crmData = fetchFromStorage(identifiers.zmccCrmParams);
    if((crmData && crmData?.phone) || (customerDetilsForm?.formData?.customerMobile)){
      model = model.map(input => {
        if(input.field === "customerMobile"){
          input.isDisplayed = false;
        }
        return {...input};
      })
    }
    model = mapFormFieldAndValue(model, "customerSkillLevelId", "options", props.skillLevel || []);
    return [...model];
  }

  useEffect(() =>{
    primaryContactModel();
  }, [customerDetilsForm.formData.customerPointOfContact])

  const primaryContactModel = () => {
    const model = [...primaryPointOfContactModel];
    if (customerDetilsForm.formData.customerPointOfContact && customerDetilsForm.formData.customerPointOfContact === 'no') {
      model[0].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }]
      model[0].required = true;
      model[1].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }, { check: Validators.email, message: 'invalidEmailError' }]
      model[1].required = true;
    }else {
      model[0].validators = []
      model[0].required = false;
      model[1].validators = []
      model[1].required = false;
    }
    return model;
  }

  const handleForm = ({ values, field, formName }) => {
    let resetValuesWith = {}
    if(field === "marketSegmentId" && values?.marketSegmentId !== customerBackgroundForm?.formData?.marketSegmentId){
      resetValuesWith = {
        customerMarketSubsgementId: null,
        customerMarketSubsgementIdFullValue: null,
      }
    }
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          ...values,
          ...resetValuesWith
        },
      },
    });
    props.setUnsavedChanges(true);
  };

  return {
    handleForm,
    customerBackModel,
    primaryContactModel,
    getCustomerDetailsModel,
  };

};
