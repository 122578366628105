import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import zeissInstance from 'library/utilities/AxiosInstance';

const fetchKitDetailService = async (kitId, isCopyKit = false) => {
  const query =
    'kitName,description,systemValue,marketingMaterial,hideKit,stockInformation[id,locationName,activeUntilDate,businessUnit[name,id]],businessUnit[id,name],storageLocation,' +
    'sapERP,systemClass[systemClassId,systemClassName],systemMainComponent[systemMainComponentId,systemMainComponentName],serialNumber,dateOfManufacture,' +
    'country[id,name],commodityCode,deliveryDays,returnDeliveryDays,reconditioningDeliveryDays,maxLoanDuration,availabilityFrom,' +
    'availabilityTo,packageDimensions,kitCondition[kitConditionId,kitConditionTranslated[translatedCondition]],comment,salesComponents,furtherImportantComponents,' +
    'freeAccessories,kitImages[kitImagesId,preview,type,size,file,actualImageUrl],kitAttachments[kitAttachmentsId,' +
    'preview,type,size,file],kitInformationId,ibase,kitReparation[isReparationTimeActive,reparationFrom,reparationTo,reparationComment,kitReparationId],' +
    'divisions[divisionsId,id,divisionsTranslated[name]],itemNumber,materialNumberOfStand,walkOffSalesOrder,sapLoanOrderStatus,sapCorrelationId,kitLoanAssociated,supportingAsset,fixedAsset,firstSalesOrder,isMobileOrExternal,owner[id,firstName,lastName],warehouseRoom[roomName,id],windowsUserPassword,vrDetailsUserName,vrDetailsPassword,linkToOnboardingMaterial';
  const queryCopy =
    'kitName,description,systemValue,marketingMaterial,hideKit,stockInformation[id,locationName,businessUnit[name,id]],businessUnit[id,name],storageLocation,' +
    'sapERP,systemClass[systemClassId,systemClassName],systemMainComponent[systemMainComponentId,systemMainComponentName],serialNumber,dateOfManufacture,' +
    'country[id,name],commodityCode,deliveryDays,returnDeliveryDays,reconditioningDeliveryDays,maxLoanDuration,availabilityFrom,' +
    'availabilityTo,packageDimensions,kitCondition[kitConditionId,kitConditionTranslated[translatedCondition]],comment,salesComponents,furtherImportantComponents' +
    ',freeAccessories,kitImages[kitImagesId,preview,type,size,file,actualImageUrl],kitAttachments[kitAttachmentsId,' +
    'preview,type,size,file],kitInformationId,ibase,kitReparation[isReparationTimeActive,reparationFrom,reparationTo,reparationComment,kitReparationId],kitLoanAssociated,supportingAsset,fixedAsset,firstSalesOrder,isMobileOrExternal,owner[id,firstName,lastName],warehouseRoom[roomName,id],windowsUserPassword,vrDetailsUserName,vrDetailsPassword,linkToOnboardingMaterial';
    return await zeissInstance.post(`${URLS.kit}/${kitId}`, { graphql: isCopyKit ? queryCopy : query });
};

const fetchUniqueIBaseService = async kitId => {
  return await zeissInstance.get(URLS.fetchIbase.replace('{kitId}', kitId));
};

const saveKitService = async data => {
  return await zeissInstance.post(URLS.kit, data);
};

const uploadKitAttachmentsService = async (kitId, data) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return await zeissInstance.post(URLS.uploadKitAttachment.replace('{kitId}', kitId), data, config);
};

const uploadKitImagesService = async (kitId, data) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return await zeissInstance.post(URLS.uploadKitImage.replace('{kitId}', kitId), data, config);
};

const saveKitForSale = data => {
  return zeissInstance.post(URLS.selloffplatform, data);
};

const saveKitAttachmentsForSale = (id, data) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return zeissInstance.post(URLS.selloffplatformAttachments(id), data, config);
};

const uploadKitImagesForSaleService = async (kitId, data) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return await zeissInstance.post(URLS.uploadKitImageForSale(kitId), data, config);
};

const getSellStatus = async id => {
  try {
    const { data } = await zeissInstance.get(URLS.sellStatus(id));
    return data.sellStatus;
  } catch (err) {
    return false;
  }
};

const saveKitManuallyService = data => {
  return zeissInstance.post(URLS.kitManual, data);
};

const saveSingleItemService = data => {
  return zeissInstance.post(URLS.singleItem, data);
};

const fetchStockById = id => {
  return zeissInstance.get(URLS.getStockInfo(id));
};

export default {
  fetchKitDetailService,
  fetchUniqueIBaseService,
  saveKitService,
  uploadKitAttachmentsService,
  uploadKitImagesService,
  saveKitForSale,
  saveKitAttachmentsForSale,
  getSellStatus,
  saveKitManuallyService,
  fetchStockById,
  uploadKitImagesForSaleService,
  saveSingleItemService,
};
