import React from 'react';

import strings from 'resources/locales/Translate';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import { AttachmentsBlock } from 'library/common/commonComponents/KitAttachments';

import { useAttachments } from './Attachments.hook';
import './Attachments.style.scss';
import { string } from 'prop-types';
import classnames from 'classnames';
import Section from '../Section/Section';
import "../../../../modules/Kits/KitPictureUploader/kitPictureUploader.styles.scss"

export const CreateKitAttachments = props => {
  const {
    formValue,
    saveKit,
    cancelCreateKit,
    isNotRemoved,
    isLoading,
    hasVideo,
    hasTransactionAttachmentsSection,
    transactionAttachments,
    actionFrom,
    uploaderStyle,
    newVersionUi,
    showHeading,
    enhancedUi,
  } = props;
  const {
    viewType,
    error,
    maxFileError,
    toggleViewType,
    addKitAttachmentFile,
    handleFileRejection,
    handleMaxFileReached,
    removeKitAttachmentFile,
  } = useAttachments(props);
  let mimeType = 'image/*,.pdf,.doc,.docx,.xls,.xlsx,application/msword,application/vnd.openxm,.czi';
  if(props.actionFrom == 'zmccAttachement'){
    mimeType = mimeType+',application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';
  }
  
  const types = hasVideo ? mimeType + ',.mp4,.mkv' : mimeType;
  const downloadUrl=props.actionFrom == 'zmccAttachement' ? process.env.REACT_APP_BASE_URL + URLS.downloadZmccSemAttachment : process.env.REACT_APP_BASE_URL + URLS.downloadAttachment;
  const Wrapper = props.sectionWrapper ? Section: React.Fragment
  return (
    <div className='kit-attachments-container'>
      <div className= {props.actionFrom !== 'zmccAttachement' ? 'container-fluid form-container' : 'form-container'}>
        <Wrapper sectionTitle={strings.attachments} containerClass={"kit-pics-container"}>
        <div className={classnames({
          'toggle-container d-flex align-items-center justify-content-between': showHeading,
          'toggle-container d-flex align-items-center justify-content-end': !showHeading,
        })}>
          {showHeading && <h3 className='form-section-title mb-0'>{strings.attachments}</h3>}
          {!enhancedUi && <div className='buttons'>
            <button onClick={toggleViewType('list')} data-test='kit-attachment-button-list'>
              <Icon name='list_view' width={32} height={32} fill={viewType === 'list' ? '#000' : '#e0e1dd'} />
            </button>
            <button onClick={toggleViewType('grid')} data-test='kit-attachment-button-grid'>
              <Icon name='grid_view' width={32} height={32} fill={viewType === 'grid' ? '#000' : '#e0e1dd'} />
            </button>
          </div>}
        </div>

        <div className='mb-3'>
          {hasTransactionAttachmentsSection && <h5 className='mb-3'>{strings.kitAttachments }</h5>}
          <FileUploader
            maxSize={50}
            mimeType={types}
            files={formValue.kitAttachments}
            maxFiles={30}
            viewType={viewType}
            onReject={handleFileRejection}
            onRemove={removeKitAttachmentFile}
            onFileAdd={addKitAttachmentFile}
            onMaxFileReached={handleMaxFileReached}
            isDownloadable
            downloadStream
            downloadIdField='kitAttachmentsId'
            downloadUrl={downloadUrl}
            isNotRemoved={isNotRemoved}
            errorMessage={strings.maxFileSize}
            availableTypes={actionFrom === 'zmccAttachement' ? strings.allowedFormatssForZmccTransaction : hasVideo ? strings.allowedFormatssForDemoKit : ''}
            uploaderStyle={uploaderStyle}
            newVersionUi={newVersionUi}
            enhancedUi={enhancedUi}
          />
          {error && <div className='mt-2 error-block'>{error}</div>}
          {maxFileError && <div className='mt-2 error-block'>{maxFileError}</div>}
        </div>

        {hasTransactionAttachmentsSection && (
          <AttachmentsBlock
            id='transactionAttachmentsId'
            title={strings.transactionAttachmentForKit}
            viewType={viewType}
            files={transactionAttachments}
            downloadUrl={process.env.REACT_APP_BASE_URL + URLS.downloadLoanAttachment}
          />
        )}
        </Wrapper>
        {props.actionFrom !== 'zmccAttachement' &&
        <div className='row'>
          <div className='col-sm-12 mt-3'>
            <CreateStockActionButtons onSave={saveKit} isLoading={isLoading} onCancel={cancelCreateKit} styleClass={'create-kit-button mt-3'}/>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default CreateKitAttachments;

CreateKitAttachments.defaultProps = {
  sectionWrapper: false,
}