import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import * as $ from 'jquery';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Tabs from 'library/common/commonComponents/Tabs/container/TabsContainer';
import { scrollToTop } from 'library/utilities/scrollActions';
import { camelCase } from 'library/utilities/stringUtils';
import { checkStockRoles } from 'library/utilities/checkRoles';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import {
  FETCH_USERS_LIST,
  FETCH_CONDITIONS,
  ADD_REASON_FOR_LOAN_DATA,
  FETCH_LABS_LOCATION_COUNTRIES,
  FETCH_TYPES_OF_JOBS,
} from 'library/common/commonConstants/storeConstants';
import DelayMessage from 'modules/Booking/Components/DelayMessage/DelayMessage';
import AdjustDates from './Components/AdjustDates';
import LLAdjustDates from './Components/LLAdjustDates';
import CreateMessage from './Components/CreateMessage';
import ReturnGoodsBooking from './Components/ReturnGoodsBooking';
import RelatedTransactions from './Components/RelatedTransactions';
import ConflictTransactionsPopup from './Components/ConflictTransactionsPopup';
import { breadcrumbsData, createTabs, createTabsKitLoan, createTabsLLKitLoan } from './TransactionDetailsConstants';
import {
  adjustTransactionDates,
  createMessage,
  getTransactionDetails,
  printDeliveryDocument,
  returnGoodsBookingSave,
  getUserTransactions,
  clearTransactionDetails,
  saveTransactionInformationDates,
  updateTransactionStatus,
  updateTransactionDetails,
  sendLogisticEmail,
  printEsbDeliveryDocument,
  getPreviousTransactionDetailsForPickupRequest
} from './TransactionDetailsActions';
import './transactionDetails.style.scss';
import { formReturnGoodsBookingModel } from './Components/ReturnGoodsBooking/ReturnGoodsBookingConstants';
import { RequestPickupPopup } from './Tabs/RequestPickupPopup/RequestPickupPopup.component';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { NEW_TYPES_OF_JOBS } from 'modules/LLKiLoan/LLKiLoan.constants';
import { DocumentsOutlineIcon, EditOutlineIcon, MessageOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';


export class TransactionDetails extends Component {
  constructor(props) {
    super(props);
    let currentTab = 1;
    if (props.history.location.state && props.history.location.state.tab) {
      if (props.history.location.state.tab === 'messages') {
        currentTab = 2;
      }
      if (props.history.location.state.tab === 'c2cLoanProcesses') {
        currentTab = 4;
      }
      if (props.history.location.state.tab === 'rating') {
        currentTab = props.history.location.state.transactionType === 'kit loan' ? 5 : 4;
      }
    }
    this.state = {
      currentTab,
      editing: false,
      saving: false,
      visible: false,
      transactionsType: null,
      dataToSend: {},
      typeUpdating: null,
      conflictedTransactions: [],
    };
  }

  componentDidMount() {
    const {
      match,
      transactionDetails,
      fetchMasterData,
      conditions,
      usersList,
      reasonForLoan,
      labsLocationCountries,
      typesOfJobsList,
      location,
      newTypesOfJobsList,
    } = this.props;

    const reloadCount = sessionStorage.getItem('reloadCount');
    //Commedted because restricting api call of transaction export list 
    // if(reloadCount < 1) {
    //   sessionStorage.setItem('reloadCount', String(reloadCount + 1));
    //   window.location.reload();
    // } else {
    //   sessionStorage.removeItem('reloadCount');
    // }
    if (!conditions || !conditions.length) {
      fetchMasterData(URLS.kitCondition, FETCH_CONDITIONS);
    }
    if (!usersList || !usersList.length) {
      fetchMasterData(URLS.userList, FETCH_USERS_LIST);
    }
    if (!reasonForLoan || !reasonForLoan.length) {
      fetchMasterData(URLS.reasonForLoans, ADD_REASON_FOR_LOAN_DATA);
    }
    if (!labsLocationCountries || !labsLocationCountries.length) {
      fetchMasterData(URLS.countriesLabsLocation, FETCH_LABS_LOCATION_COUNTRIES);
    }
    if (!typesOfJobsList || !typesOfJobsList.length) {
      fetchMasterData(URLS.loansTypesOfJobs, FETCH_TYPES_OF_JOBS);
    }
    if (!newTypesOfJobsList || !newTypesOfJobsList.length) {
      fetchMasterData(URLS.labLocationLocationTypesOfJobs, NEW_TYPES_OF_JOBS);
    }

    const transactionsType = this.props.match && this.props.match.path.replace('/:id', '').replace('/', '');
    this.setState({ transactionsType });
    const isDlaOrLab = location && location.pathname && location.pathname.includes('?') && location.pathname.includes('?') ? location.pathname.split('?')[2].split('=')[1] :location.search.includes('?') && location.search.split('?')[2].split('=')[1];
    const transactionId = match.params.id.includes('?') && match.params.id.includes('?') ? match.params.id.split('?')[0]: match.params.id;
    if (
      !transactionDetails ||
      (match.params &&
        match.params.id &&
        parseInt(transactionDetails.transactionsId, 10) !== parseInt(match.params.id, 10))
    ) {
      this.props.getTransactionDetails(transactionId, this.fetchQueryParam(), transactionsType, isDlaOrLab);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchMasterData, language, match } = this.props;
    if (prevProps.language !== language) {
      fetchMasterData(URLS.kitCondition, FETCH_CONDITIONS);
      fetchMasterData(URLS.loansTypesOfJobs, FETCH_TYPES_OF_JOBS);
      fetchMasterData(URLS.countriesLabsLocation, FETCH_LABS_LOCATION_COUNTRIES);
      fetchMasterData(URLS.reasonForLoans, ADD_REASON_FOR_LOAN_DATA);
      this.fetchTransactionDetails();
    }
    if (prevProps.match.params && (prevProps.match.params.id !== match.params.id)) {
      this.fetchTransactionDetails();
      this.setCurrentTab(1);
    }
  }

  componentWillUnmount() {
    this.props.clearTransactionDetails();
  }

  fetchTransactionDetails = () => {
    const { getTransactionDetails, match, location } = this.props;
    const id = match.params.id;
    const queryParams = this.fetchQueryParam();

    const isDlaOrLab = location.pathname.includes('?') && location.pathname.includes('?') ? location.pathname.split('?')[2].split('=')[1] :location.search.includes('?') && location.search.split('?')[2].split('=')[1];
    const transactionsType = match && match.path.replace('/:id', '').replace('/', '');
    getTransactionDetails(id, queryParams, transactionsType, isDlaOrLab);
  };

  fetchQueryParam = () => {
    const { location } = this.props;
    if (location.search.includes('?')) {
      return location.search.split('?')[1].split('=')[1];
    }else if(location.pathname.includes('?')) {
      return location.pathname.split('?')[1].split('=')[1];
    } else {
      return location.search.split('=')[1];
    }
  };

  saveCurrentTab = currentTab => {
    this.setState({ currentTab });
  };

  setCurrentTab = id => {
    this.setState({ currentTab: id });
    scrollToTop(500);
  };

  onPrevNextPress = (id, type) => () => {
    const { transactionDetails, location } = this.props;
    const isDlaOrLab = location.pathname.includes('?') && location.pathname.includes('?') ? location.pathname.split('?')[2].split('=')[1] :location.search.includes('?') && location.search.split('?')[2].split('=')[1];
    let transactionId = transactionDetails.prevTransactionId;
    if (type === 'next') {
      transactionId = transactionDetails.nextTransactionId;
    }
    const { transactionsType } = this.state;
    this.setState({ currentTab: 1, editing: false, visible: false });
    const query = parseInt(this.fetchQueryParam(), 10);
    const detailPageNumber = type === 'next' ? query + 1 : query - 1;
    if (isDlaOrLab) {
      this.props.history.push(
        `/${transactionsType}/${transactionId}?detailPage=${detailPageNumber}?isDlaOrLab=${isDlaOrLab}`,
      );
    }else{
      this.props.history.push(
        `/transactions/${transactionId}`,
      );
    }
    window.location.reload();
  };

  onEditPress = () => {
    if (this.state.currentTab !== 1) {
      this.setState({ currentTab: 1, editing: true });
    } else {
      this.setState(state => ({ currentTab: 1, editing: !state.editing }));
    }
  };

  onShowShippingDocumentsPress = () => {
    const { transactionDetails } = this.props;
    if (
      transactionDetails &&
      transactionDetails.sapCorrelationId &&
      (transactionDetails.sapLoanOrderStatus === 'CREATED' || transactionDetails.sapLoanOrderStatus === 'RETURNED')
    ) {
      const promiseTimeout = new Promise(resolve => setTimeout(() => resolve(false), 15000));
      const fetchData = async () => {
        const { success } = await Promise.race([
          promiseTimeout,
          this.props.printEsbDeliveryDocument(URLS.esbDeliveryNote, transactionDetails),
        ]);
        if (!success) {
          this.props.printDeliveryDocument(URLS.printDeliveryDocument, transactionDetails);
          this.props.toggleActionMessage(true, 'error', 'shippingDocumentErrorMessage');
          scrollToTop(500);
        }
      };
      fetchData();
    } else {
      this.props.printDeliveryDocument(URLS.printDeliveryDocument, transactionDetails);
    }
  };

  onShowDeliveryNotePress = () => {
    const { transactionDetails } = this.props;
    this.props.printDeliveryDocument(URLS.printReturnDeliveryDocument, transactionDetails);
  };

  setAdjustDatesModalVisible = () => {
    const { transactionDetails } = this.props;
    const isC2CLoan = transactionDetails && transactionDetails.c2cLoan;
    if(isC2CLoan) {
      this.props.history.push(`/c2c/${transactionDetails.transactionsId}/maintain-c2c-loan`);
    }
    else {
      this.setState(prevState => ({ visible: !prevState.visible }));
    }
  };

  checkRoleAndPermission(user, stockInformation) {
    let allow = false;
    if (!allow && user && user.stockRoles && user.stockRoles.length) {
      let stockId = stockInformation && stockInformation.id;
      if (stockId) {
        user.stockRoles.forEach(role => {
          if (
            (role.roleName.toLowerCase() === 'stock controller' ||
              role.roleName.toLowerCase() === 'dispatcher' ||
              role.roleName.toLowerCase() === 'outbound staff') &&
            stockId === role.stockId
          ) {
            allow = true;
          }
        });
      }
    }
    if (user && user.admin) {
      allow = true;
    }
    return allow;
  }

  checkIsManager = (user, stockInformation) =>{
    let isManager = false;
    if (!isManager && user && user.stockRoles && user.stockRoles.length) {
      let stockId = stockInformation && stockInformation.id;
      if (stockId) {
        user.stockRoles.forEach(role => {
          if (
            (role.roleName.toLowerCase() === 'manager') &&
            stockId === role.stockId
          ) {
            isManager = true;
          }
        });
      }
    }
    return isManager;
  }

  renderPrevNextButtons() {
    const { transactionDetails } = this.props;
    const first =
      !transactionDetails.prevTransactionId ||
      transactionDetails.prevTransactionId === transactionDetails.transactionsId;
    const last =
      !transactionDetails.nextTransactionId ||
      transactionDetails.nextTransactionId === transactionDetails.transactionsId;

      if(transactionDetails?.transactionStatus?.transactionStatusId === 5){ // If transaction status is cancelled then dont render
        return null;
      }
      
    return (
      <div className='d-flex flex-row align-items-center'>
      <div className='prevTransaction'>
        <Button
          dataTest='prev-transaction-button'
          bgFill={false}
          disabled={first}
          iconName='arrow'
          styleClass='br-w-0'
          iconStyle='rotate-180 ml-0'
          onClick={!first && this.onPrevNextPress(this.props.match.params.id, 'prev')}
        />
        </div>
        <div className='hide'>{strings.prevTransactionMessage}</div>
        <div className='nextTransaction'>
        <Button
          dataTest='next-transaction-button'
          bgFill={false}
          disabled={last}
          iconName='arrow'
          styleClass='mt-0 mr-2'
          iconStyle='ml-0'
          onClick={!last && this.onPrevNextPress(this.props.match.params.id, 'next')}
        />
        </div>
        <div className='hide'>{strings.nextTransactionMessage}</div>
      </div>
    );
  }

  renderUpdatedPrevNextButtons() {
    const { transactionDetails } = this.props;
    const first =
      !transactionDetails.prevTransactionId ||
      transactionDetails.prevTransactionId === transactionDetails.transactionsId;
    const last =
      !transactionDetails.nextTransactionId ||
      transactionDetails.nextTransactionId === transactionDetails.transactionsId;

      if(transactionDetails?.transactionStatus?.transactionStatusId === 5){ // If transaction status is cancelled then dont render
        return null;
      }
      
    return (
      <div className='ml-2'>
        <div className='updatePrevTransaction cursor-pointer' onClick={!first && this.onPrevNextPress(this.props.match.params.id, 'prev')} disabled={first}>
          <svg width="20" height="25" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.36699 6.97189L8.51905 0.415378C8.811 0.149331 9.20592 0 9.61757 0C10.0292 0 10.4241 0.149331 10.7161 0.415378C10.8621 0.548169 10.9781 0.706155 11.0572 0.880223C11.1363 1.05429 11.177 1.24099 11.177 1.42957C11.177 1.61814 11.1363 1.80484 11.0572 1.97891C10.9781 2.15298 10.8621 2.31096 10.7161 2.44375L3.54845 8.98598C3.4024 9.11877 3.28648 9.27675 3.20738 9.45082C3.12827 9.62489 3.08754 9.81159 3.08754 10.0002C3.08754 10.1887 3.12827 10.3754 3.20738 10.5495C3.28648 10.7236 3.4024 10.8816 3.54845 11.0144L10.7161 17.5566C11.0095 17.8237 11.1752 18.1867 11.1766 18.5657C11.1781 18.9448 11.0152 19.3088 10.7239 19.5778C10.4325 19.8468 10.0366 19.9987 9.62308 20C9.2096 20.0013 8.81247 19.852 8.51905 19.5849L1.36699 13.0284C0.491601 12.2249 -9.91821e-05 11.1358 -9.91821e-05 10.0002C-9.91821e-05 8.86456 0.491601 7.77538 1.36699 6.97189Z" fill="#9FA0A1"/>
          </svg>
        </div>
        <div className='display'>{strings.prevTransactionMessage}</div>
        <div className='updateNextTransaction cursor-pointer' onClick={!last && this.onPrevNextPress(this.props.match.params.id, 'next')} disabled={last}>
          <svg width="20" height="25" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.98701 6.97189L2.83495 0.415378C2.54301 0.149331 2.14808 0 1.73643 0C1.32478 0 0.929855 0.149331 0.63791 0.415378C0.491864 0.548169 0.375944 0.706155 0.296837 0.880223C0.217731 1.05429 0.177002 1.24099 0.177002 1.42957C0.177002 1.61814 0.217731 1.80484 0.296837 1.97891C0.375944 2.15298 0.491864 2.31096 0.63791 2.44375L7.80556 8.98598C7.9516 9.11877 8.06752 9.27675 8.14663 9.45082C8.22574 9.62489 8.26646 9.81159 8.26646 10.0002C8.26646 10.1887 8.22574 10.3754 8.14663 10.5495C8.06752 10.7236 7.9516 10.8816 7.80556 11.0144L0.63791 17.5566C0.344498 17.8237 0.17884 18.1867 0.177379 18.5657C0.175918 18.9448 0.338775 19.3088 0.63012 19.5778C0.921466 19.8468 1.31744 19.9987 1.73092 20C2.14441 20.0013 2.54154 19.852 2.83495 19.5849L9.98701 13.0284C10.8624 12.2249 11.3541 11.1358 11.3541 10.0002C11.3541 8.86456 10.8624 7.77538 9.98701 6.97189Z" fill="#9FA0A1"/>
          </svg>
        </div>
        <div className='display'>{strings.nextTransactionMessage}</div>
      </div>
    );
  }

  enableAdjustDateOrCreateMessageButton(user, stockInformation, isCreateMessageButton) {
    let isAllowed = false;
    if (!isAllowed && user && user.stockRoles && user.stockRoles.length) {
      let stockId = stockInformation && stockInformation.id;
      let businessGroup = stockInformation && stockInformation.businessUnit && stockInformation.businessUnit.designation;
      let country = stockInformation && stockInformation.country && stockInformation.country.shortName;
      if (stockId) {
        let rolesForAdjustButton = ['stock controller', 'dispatcher', 'outbound staff'];
        const rolesForMessageButton = [...rolesForAdjustButton, 'borrower', 'external borrower', 'manager', 'reconditioning'];
        if (businessGroup === 'MED' && country == "US"){
          rolesForAdjustButton = [...rolesForAdjustButton,'borrower mcs','borrower odx','borrower sur','borrower'];
        }
        const roles = isCreateMessageButton ? rolesForMessageButton : rolesForAdjustButton;

        user.stockRoles.forEach(role => {
          if (roles.includes(role.roleName.toLowerCase()) && stockId === role.stockId) {
            isAllowed = true;
          }
        });
      }
    }

    if (user && user.admin) {
      isAllowed = true;
    }
    return isAllowed;
  }

  createReturnGoodsModel = () => {
    const { conditions, usersList, transactionDetails } = this.props;
    const isKitLoan = transactionDetails.type === 'kit loan';
    const fetchConditionsToShow = isKitLoan ? [6, 2] : [6, 1, 2]; // Conditions Ids to display on dropdown
    let filteredConditions = [];
    fetchConditionsToShow &&
      fetchConditionsToShow.forEach(toShow => {
        let condition =
          conditions && conditions.length && conditions.find(condition => condition.kitStatusId === toShow);
        if (condition) {
          filteredConditions.push(condition);
        }
      });
    const model = [...formReturnGoodsBookingModel];
    model[0].options = usersList;
    model[4].options = filteredConditions;
    return model;
  };

  openRelatedTransactionsModal = (dataToSend, typeUpdating) => {
    this.setState({ dataToSend, typeUpdating }, () => {
      if ($('#relatedTransactionsModal') && $('#relatedTransactionsModal').modal) {
        $('#relatedTransactionsModal').modal('show');
      }
    });
  };

  setConflictedTransactions = conflictedTransactions => {
    this.setState({ conflictedTransactions });
  };

  handleSaveTransactionDetails = async transactionsId => {
    const { dataToSend, typeUpdating } = this.state;
    const {
      transactionDetails,
      adjustTransactionDates,
      updateTransactionStatus,
      saveTransactionInformationDates,
      updateTransactionDetails,
    } = this.props;
    const updatedDataToSend = { ...dataToSend, transactionsId };
    const detailPage = this.fetchQueryParam();
    let request;
    let args = [];
    switch (typeUpdating) {
      case 'isAdjustDates':
        request = adjustTransactionDates;
        args = [updatedDataToSend, transactionDetails.transactionsId, transactionDetails.type];
        break;
      case 'isActualDates':
        request = saveTransactionInformationDates;
        args = [updatedDataToSend, transactionDetails.transactionsId, detailPage, transactionDetails.type];
        break;
      case 'isTransactionStatus':
        request = updateTransactionStatus;
        args = [
          transactionDetails.transactionsId,
          updatedDataToSend.transactionStatus,
          updatedDataToSend.showMessage,
          updatedDataToSend.transactionsId,
        ];
        break;
      case 'isTransactionDetails':
        request = updateTransactionDetails;
        args = [
          transactionDetails.transactionsId,
          updatedDataToSend.dataToSend,
          updatedDataToSend.dataToSave,
          updatedDataToSend.transactionsId,
        ];
        break;
      case 'isUpdatingTransactionWithTransactionStatus':
        request = updateTransactionDetails;
        args = [
          transactionDetails.transactionsId,
          updatedDataToSend.dataToSend,
          updatedDataToSend.dataToSave,
          updatedDataToSend.transactionsId,
        ];
        break;
      default:
        break;
    }

    if (typeUpdating === 'isUpdatingTransactionWithTransactionStatus') {
      const { error } = await updateTransactionStatus(
        transactionDetails.transactionsId,
        updatedDataToSend.transactionStatus,
        updatedDataToSend.showMessage,
        updatedDataToSend.transactionsId,
        updatedDataToSend?.dataToSend?.helpForBuildingAndDismantling || null
      );
      if (error) return { error };
    }
    const { success, error } = await request(...args);

    return { success, error };
  };

  getTabs = (
    transactionType,
    transactionDetails,
    messagesCount,
    changeHistoryCount,
    usersList,
    bundleLoansWithSameStock,
    hasPermissions,
    attachmentsCount,
    showRequestPopup,
    hasManagerPermission
  ) => {
    if (transactionDetails && transactionDetails.labAndLocationBooking) {
      return createTabsLLKitLoan({
        messagesCount,
        changeHistoryCount,
        attachmentsCount,
        setCurrentTab: this.setCurrentTab,
        editing: this.state.editing,
        onEditPress: this.onEditPress,
        transactionsType: this.state.transactionsType,
        detailPage: this.fetchQueryParam(),
        fetchTransactionDetails: this.fetchTransactionDetails,
        showRequestPopup: showRequestPopup,
        match:this.props.match,
        bundleLoans: bundleLoansWithSameStock
      });
    }

    if (transactionType === 'kitLoan') {
      return createTabsKitLoan({
        history: this.props.history,
        messagesCount,
        changeHistoryCount,
        attachmentsCount,
        hasPermissions,
        hasManagerPermission,
        setCurrentTab: this.setCurrentTab,
        editing: this.state.editing,
        onEditPress: this.onEditPress,
        transactionsType: this.state.transactionsType,
        detailPage: this.fetchQueryParam(),
        usersList,
        bundleLoansWithSameStock,
        openRelatedTransactionsModal: this.openRelatedTransactionsModal,
        fetchTransactionDetails: this.fetchTransactionDetails,
        showRequestPopup: showRequestPopup,
        workFlowQuestions: this.props.workFlowQuestions,
        transactionIdDummy: transactionDetails.transactionsId,
        match:this.props.match
      });
    }

    return createTabs({
      history: this.props.history,
      messagesCount,
      changeHistoryCount,
      attachmentsCount,
      hasPermissions,
      hasManagerPermission,
      setCurrentTab: this.setCurrentTab,
      editing: this.state.editing,
      onEditPress: this.onEditPress,
      transactionsType: this.state.transactionsType,
      detailPage: this.fetchQueryParam(),
      loanType: transactionType,
      usersList,
      bundleLoansWithSameStock,
      openRelatedTransactionsModal: this.openRelatedTransactionsModal,
      fetchTransactionDetails: this.fetchTransactionDetails,
      showRequestPopup: showRequestPopup,
      match:this.props.match
    });
  };
  reuestPickupModalOpen = () => {
    $('#request-pickuo-modal').modal('show');
  };

  render() {
    const {
      appActions,
      changeLog,
      commentsData,
      damageLossReportData,
      match,
      transactionDetails,
      delayReturnTransactions,
      returnGoodsBookingDetail,
      user,
      usersList,
      bundleLoans,
      attachments,
      pickupRequestsData,
    } = this.props;
    const { transactionsType, typeUpdating, conflictedTransactions } = this.state;
    const { displayActionMessage, type, message } = this.props.appActions;
    const isLabAndLocationBooking = transactionDetails && transactionDetails.labAndLocationBooking;
    let messagesCount = 0;
    let changeHistoryCount = 0;
    let attachmentsCount = 0;
    const showPickUpRequestButton =
      transactionDetails &&
      transactionDetails.stockInformation &&
      transactionDetails.stockInformation.workflowCategoryAnswers &&
      transactionDetails.stockInformation.workflowCategoryAnswers.find(
        item => item.value === 'yes' && item.workflowCategoryQuestions.workflowCategoryQuestionsId === 30,
      );
    const showPickUpRequestButtonForKitloan =
      transactionDetails &&
      transactionDetails.transactionKit &&
      transactionDetails.transactionKit.stockInformation &&
      transactionDetails.transactionKit.stockInformation.workflowCategoryAnswers.find(
        item => item.value === 'yes' && item.workflowCategoryQuestions.workflowCategoryQuestionsId === 30,
      );
    const actualReceiptInStock = transactionDetails && transactionDetails.returnDelivery === null;
    const showRequestPopup = showPickUpRequestButton || showPickUpRequestButtonForKitloan;
    const stockInfo = transactionDetails?.stockInformation || transactionDetails?.transactionKit?.stockInformation
    let hasAccessToViewPickRequsest = true
    if(stockInfo){
      const showPickUpRequestRolesAccess = checkStockRoles(["Borrower", "External Borrower"], user, stockInfo)
      if(showPickUpRequestRolesAccess?.stockId === stockInfo?.id){
        hasAccessToViewPickRequsest = false
      }
    }
    if (commentsData.totalElements) {
      messagesCount += commentsData.totalElements;
    }
    if (damageLossReportData.totalElements) {
      messagesCount += damageLossReportData.totalElements;
    }
    if (pickupRequestsData.totalElements && showRequestPopup) {
      messagesCount += pickupRequestsData.totalElements;
    }
    if (changeLog.totalElements) {
      changeHistoryCount += changeLog.totalElements;
    }
    if (attachments && attachments.length) {
      attachmentsCount = attachments.length;
    }
    let showTransaction = false;
    if (transactionDetails && match && match.params && match.params.id) {
      showTransaction = true;
    }
    let transactionType = '';
    if (transactionDetails && transactionDetails.type) {
      transactionType = transactionDetails.labAndLocationBooking
        ? strings.labsAtLocationReservation
        : camelCase(transactionDetails.type);
    }
    const stockInformation =
      transactionDetails &&
      (transactionDetails.type === 'kit loan'
        ? get(transactionDetails, 'transactionKit.stockInformation', '')
        : transactionDetails.stockInformation);
    const hasPermissions = this.checkRoleAndPermission(user, stockInformation);
    const hasManagerPermission = this.checkIsManager(user, stockInformation);
    const rolesForSaving = ['Manager', 'Stock Controller', 'Dispatcher', 'Borrower'];
    let operaterUserEditPermission = false;
    const operaterUserPermission = () => {
      if (
        user &&
        user.accountId === transactionDetails &&
        transactionDetails.operatorUser &&
        transactionDetails.operatorUser.accountId
      ) {
        return (operaterUserEditPermission = true);
      }
    };

    const hasLLPermissions =
      (isLabAndLocationBooking && ((user && user.admin) || checkStockRoles(rolesForSaving, user, stockInformation))) ||
      operaterUserPermission;

    const disableButtons24 = transactionDetails && transactionDetails.twentyFourHourReservation;
    const displayAdjustButton = this.enableAdjustDateOrCreateMessageButton(user, stockInformation);
    const displayCreateMessageButton = this.enableAdjustDateOrCreateMessageButton(user, stockInformation, true);
    const displayDelayMessage =
      user.accountId === get(transactionDetails, 'user.accountId') ||
      user.accountId === get(transactionDetails, 'borrower.accountId');
    const bundleLoansWithSameStock =
      stockInformation && bundleLoans.filter(item => item.stockId === stockInformation.id);
    const tabs = this.getTabs(
      transactionType,
      transactionDetails,
      messagesCount,
      changeHistoryCount,
      usersList,
      bundleLoansWithSameStock,
      hasPermissions,
      attachmentsCount,
      showRequestPopup,
      hasManagerPermission
    );
    return (
      <>
        {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
        {displayDelayMessage && !!delayReturnTransactions && !!delayReturnTransactions.length && (
          <DelayMessage transactions={delayReturnTransactions} />
        )}
        <div className='container-fluid mb-3'>
          <Breadcrumbs data={breadcrumbsData(this.state.transactionsType, transactionType)} />
          {showTransaction &&
          <div className='title-section'>
           <div className='d-flex align-items-center justify-content-between'>
           <h1 className='trans-title'>
                  {transactionDetails.labAndLocationBooking
                    ? strings.labsAtLocationReservation
                    : strings[transactionType]}{' '}
                  #{transactionDetails.transactionNumber}
                </h1>
                <div >{this.renderUpdatedPrevNextButtons()}</div>
            </div> 
          
                <div className='d-flex flex-md-row flex-column align-items-md-center justify-content-start new-ui-text-util-butons'>
                {((hasLLPermissions && isLabAndLocationBooking) || (!isLabAndLocationBooking && hasPermissions)) && (
                  <div className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 cursor-pointer' onClick={this.onEditPress}>
                      <EditOutlineIcon />
                      <span className='ml-2 mt-2 color-blue'>{strings.edit}</span>
                    </div>
                )}
                  {displayAdjustButton && (
                    <div
                      className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 cursor-pointer'
                      data-toggle={transactionDetails.c2cLoan ? '' : 'modal'}
                      data-target={!disableButtons24 ? '#adjustDatesModal' : ''}
                      data-test='transaction-details-adjust-dates-button'
                      onClick={this.setAdjustDatesModalVisible}
                    >
                      <Icon name='newVersionCalendar' width={18} height={18} fill='#0088d0' className='plus-icon' viewBox='0 0 16 18'/>
                      <span className='ml-2 mt-2 color-blue'>{strings.adjustDates}</span>
                    </div>
                  )}
                  {displayCreateMessageButton && (
                    <div
                      className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 cursor-pointer'
                      data-toggle='modal'
                      data-target={!disableButtons24 ? '#createMessageModal' : ''}
                      data-test='transaction-details-create-message-button'
                      data-backdrop='static'
                      data-keyboard='false'
                    >
                      <MessageOutlineIcon />
                      <span className='ml-2 mt-2 color-blue'>{strings.createMessage}</span>
                    </div>
                  )}

                  {showRequestPopup && !isLabAndLocationBooking && actualReceiptInStock && hasAccessToViewPickRequsest && (
                    <>
                      <MessageOutlineIcon />
                      <Button
                        isGhost={true}
                        styleClass='pickRequest-button new-ui-text-util-butons'
                        bgFill={false}
                        value={strings.requestPickup}
                        onClick={() => this.reuestPickupModalOpen()}
                        dataTest='transaction-details-requestPickup-button'
                      />
                      <RequestPickupPopup
                        id='request-pickuo-modal'
                        transactionDetails={transactionDetails}
                        language={this.props.language}
                        sendLogisticEmail={this.props.sendLogisticEmail}
                        getPreviousTransactionDetailsForPickupRequest={this.props.getPreviousTransactionDetailsForPickupRequest}
                        workFlowQuestions={this.props.workFlowQuestions}
                      />
                    </>
                  )}

                  {!isLabAndLocationBooking && (
                    <>
                      <div
                        className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 cursor-pointer'
                        data-test='show-shipping-documents-button'
                        onClick={!disableButtons24 ? this.onShowShippingDocumentsPress : null}
                      >
                        <DocumentsOutlineIcon />
                        <span className='ml-2 mt-2 color-blue'>{strings.showShippingDocuments}</span>
                      </div>
                      <div
                        className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 cursor-pointer'
                        data-test='show-return-delivery-note-button'
                        onClick={!disableButtons24 ? this.onShowDeliveryNotePress : null}
                      >
                         <DocumentsOutlineIcon />
                        <span className='ml-2 mt-2 color-blue'>{strings.showReturnDeliveryNote}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              
              }
        </div>
        {showTransaction && (
          <Tabs
            ref={ref => {
              this.tabs = ref;
            }}
            currentTab={this.state.currentTab}
            saveCurrentTab={this.saveCurrentTab}
            tabs={tabs}
            newUiVersion={true}
          />
        )}
        {showTransaction && !isLabAndLocationBooking && (
          <AdjustDates
            id='adjustDatesModal'
            adjustTransactionDates={this.props.adjustTransactionDates}
            enableSave={displayAdjustButton}
            transactionDetails={transactionDetails || {}}
            changeVisible={this.setAdjustDatesModalVisible}
            visible={this.state.visible}
            openRelatedTransactionsModal={this.openRelatedTransactionsModal}
            isRelatedTransactions={bundleLoansWithSameStock && bundleLoansWithSameStock.length > 0}
          />
        )}
        {showTransaction && isLabAndLocationBooking && (
          <LLAdjustDates
            id='adjustDatesModal'
            adjustTransactionDates={this.props.adjustTransactionDates}
            transactionDetails={transactionDetails || {}}
            changeVisible={this.setAdjustDatesModalVisible}
            visible={this.state.visible}
          />
        )}
        {showTransaction && (
          <CreateMessage
            id='createMessageModal'
            createMessage={this.props.createMessage}
            transactionsId={transactionDetails && transactionDetails.transactionsId}
            isZmcc={false}
          />
        )}
        {showTransaction && (
          <ReturnGoodsBooking
            id='returnGoodsBookingModal'
            formModel={this.createReturnGoodsModel()}
            returnGoodsDetail={returnGoodsBookingDetail}
            returnGoodsBookingSave={this.props.returnGoodsBookingSave}
            transactionDetails={transactionDetails}
            transactionsId={transactionDetails && transactionDetails.transactionsId}
          />
        )}
        {showTransaction && (
          <RelatedTransactions
            id='relatedTransactionsModal'
            type={typeUpdating}
            bundleLoans={bundleLoansWithSameStock}
            transactionDetails={transactionDetails}
            onEditPress={this.onEditPress}
            setConflictedTransactions={this.setConflictedTransactions}
            handleSaveTransactionDetails={this.handleSaveTransactionDetails}
          />
        )}
        {showTransaction && (
          <ConflictTransactionsPopup
            id='conflictTransactionsModal'
            conflictedTransactions={conflictedTransactions}
            handleSaveTransactionDetails={this.handleSaveTransactionDetails}
            onEditPress={this.onEditPress}
            setConflictedTransactions={this.setConflictedTransactions}
            bundleLoans={bundleLoansWithSameStock}
            type={typeUpdating}
            transactionDetails={transactionDetails}
          />
        )}
        {appActions.isLoading && <div className='loader' />}
      </>
    );
  }
}

const mapStateToProps = ({
  appActionsReducer,
  authReducer,
  languageReducer,
  transactionDetailsReducer,
  masterDataReducer,
  kitDetailsReducer,
}) => ({
  appActions: appActionsReducer,
  userTransactions: transactionDetailsReducer.userTransactions,
  language: languageReducer,
  changeLog: transactionDetailsReducer.changeLog,
  commentsData: transactionDetailsReducer.commentsData,
  damageLossReportData: transactionDetailsReducer.damageLossReportData,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  delayReturnTransactions: transactionDetailsReducer.delayReturnTransactions,
  returnGoodsBookingDetail: transactionDetailsReducer.returnGoodsBookingDetail,
  conditions: masterDataReducer.conditions,
  usersList: masterDataReducer.usersList,
  user: authReducer.user,
  first: transactionDetailsReducer.first,
  last: transactionDetailsReducer.last,
  bundleLoans: transactionDetailsReducer.bundleLoans,
  reasonForLoan: masterDataReducer.reasonForLoan,
  labsLocationCountries: transactionDetailsReducer.labsLocationCountries,
  typesOfJobsList: transactionDetailsReducer.typesOfJobsList,
  attachments: transactionDetailsReducer.attachments,
  pickupRequestsData: transactionDetailsReducer.pickupRequestsData,
  workFlowQuestions: kitDetailsReducer.workFlowQuestions,
  newTypesOfJobsList: transactionDetailsReducer.newTypesOfJobsList,
});

export default connect(mapStateToProps, {
  adjustTransactionDates,
  createMessage,
  getTransactionDetails,
  printDeliveryDocument,
  returnGoodsBookingSave,
  fetchMasterData,
  getUserTransactions,
  clearTransactionDetails,
  updateTransactionStatus,
  saveTransactionInformationDates,
  updateTransactionDetails,
  sendLogisticEmail,
  printEsbDeliveryDocument,
  toggleActionMessage,
  getPreviousTransactionDetailsForPickupRequest
})(TransactionDetails);
