import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const customerDetilsModel = [
  {
    label: 'name',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerOrganization',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerDepartment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'addressText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerAddress',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerEmail',
    validators: [{check: Validators.email, message: 'invalidEmailError'}],
    required: false,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'mobile',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerMobile',
    required: true,
    styleClass: 'col-sm-3',
    disabled: false,
    hideOptionalText: true,
    isDisplayed: true,
  },
  {
    label: 'website',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerWebsite',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'pointOfContact',
    value: '',
    type: 'enhanced-RadioButton',
    placeholder: '',
    field: 'customerPointOfContact',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [
      {
        id: 'yes',
        value: 'yes',
      },
      {
        id: 'no',
        value: 'no',
      },
    ],
  },
  {
    label: 'nda',
    selected: false,
    type: 'checkbox',
    field: 'customerNDA',
    styleClass: 'col-sm-3 mt-4',
    newuiVersionStyle: true,
  },
  {
    label: 'function',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerFunction',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    disabled: false,
    hideOptionalText: true,
    styleClass: 'col-sm-3',
    showRedAsterik: true,
  },
  {
    label: "skillLevel",
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'customerSkillLevelId',
    validators: [],
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    hideOptionalText: true,
  },
  {
    label: 'descisionMaker',
    value: '',
    type: 'enhanced-RadioButton',
    placeholder: '',
    field: 'customerDescisionMaker',
    validators: [],
    styleClass: 'col-sm-3 mt-3',
    options: [
      {
        id: true,
        value: 'yes',
      },
      {
        id: false,
        value: 'no',
      },
    ],
  },
];

export const primaryPointOfContactModel = [
  {
    label: 'name',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'pocName',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'pocEmail',
    validators: [{check: Validators.email, message: 'invalidEmailError'}],
    required: false,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'designationTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'pocDesignation',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    disabled: false,
  },
];

export const customerBackgroundModel = [
  {
    label: 'marketSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'marketSegmentId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'marketSubSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'customerMarketSubsgementId',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'customerMicroscopeExperience',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerMicroscopeExperience',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    disabled: false,
  },
  {
    label: 'zeissCustomer',
    value: '',
    type: 'enhanced-RadioButton',
    placeholder: '',
    field: 'zeissCustomer',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [
      {
        id: 'yes',
        value: 'yes',
      },
      {
        id: 'no',
        value: 'no',
      },
    ],
  },
  {
    label: 'relationshipWithZeiss',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'relationshipId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'sbu',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'sbuType',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
];
