import React, { Component } from 'react';
import moment from 'moment';
import { debounce, toArray, isEqual, truncate } from 'lodash';
import $ from 'jquery';
import { lightBlue, darkBlue, darkGreenColor, battleshipGrey, redColor, appleGreen, pureRed, brilliantBlue, mediumGray } from 'resources/styles/variables.scss';

import AppScheduler from 'library/common/commonComponents/AppSchedular/AppSchedular';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import strings from 'resources/locales/Translate';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Button from 'library/common/commonComponents/Button/Button';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import * as actionStatusTypes from 'modules/Booking/BookingConstants';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { fetchFromStorage, removeFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { scrollToTop } from 'library/utilities/scrollActions';

import Filter from './component/Filter/Filter';
import { calendarBreadcrumbData } from './calendarBreadcrumbConstant';
import AlternateSlotMessage from './component/AlternateSlotMessage/AlternateSlotMessage';
import './calendar.style.scss';
import ShowGoogleMap from './component/GoogleMap/ShowGoogleMap.component';
import ContactPopup from './component/ContactPopup/ContactPopup.component';
import { FETCH_LABS_LOCATION_SYSTEM_CLASSES } from 'modules/Booking/BookingConstants';
import BlockingCalendarMessagPopup from './component/BlockingCalendarMessagPopup/BlockingCalendarMessagPopup.component';
import { clearZmccDataOnFiltersApplied } from './CalendarActions';
import ColorLegends from './component/ColorLegends/ColorLegends';

const INITIAL_PAGE = 25;
const itemsPerPage = {
  10: { id: 10, pages: 10 },
  25: { id: 25, pages: 25 },
  50: { id: 50, pages: 50 },
  100: { id: 100, pages: 100 },
};

const INITIAL_FILTER = {
  loanType: 1,
  category: null,
  categoryFullValue: null,
  stock: null,
  stockFullValue: null,
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    document.title = 'Calendar';
    const isLabsLocation = props.location.pathname.toLowerCase().includes('labs-location-calendar');
    const isZMCCCalendar = props.location.pathname.toLowerCase().includes('zmcc-calendar');
    const zmccfilters = fetchFromStorage(identifiers.zmccFilters);
    const storage = isLabsLocation
      ? fetchFromStorage(identifiers.filtersLabsLocationCalendar)
      : fetchFromStorage(identifiers.filtersCalendar);
    this.state = {
      isShowFilter: true,
      isLabsLocation,
      isZMCCCalendar,
      data: {
        page: 1,
        size: itemsPerPage[isZMCCCalendar ? 50 : INITIAL_PAGE].pages,
        sort: {
          kitName: 'asc',
          serialNumber: 'desc',
          dateOfManufacture: null,
          itemNumber: 'desc',
        },
        filter: (isLabsLocation && !isZMCCCalendar) ? props.labsLocationFilters : isZMCCCalendar ? zmccfilters ? zmccfilters.filter : props.zmccCalendarFilters : props.filters,
        searchTerm: storage ? storage : isZMCCCalendar ? zmccfilters ? zmccfilters.searchTerm : '' : '',
      },
      stockPlaceholder: '',
      categoryPlaceholder: '',
      homepageFilter: {
        stocks: [],
        systemClass: [],
      },
      alternateMessageData: {
        displayMessage: false,
        from: '',
        to: '',
      },
      contentLoaded: false,
      showGoogleMapModal: false,
      showContactPopup: false,
      selectedOnFilterCalendar: 'kitName',
      calendarBlockList: [],
      showCalendarBlockPopup: false,
      show24hrReservationButton: false,
      zmccCurrentFetchFromDate: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
      zmccCurrentFetchEndDate: moment().add(1, 'months').endOf('month').format("YYYY-MM-DD")
    };
  }

  handleChangeForFilter = (selectedObject) => {
    this.setState({ selectedOnFilterCalendar: selectedObject.value });
    this.handleSort(selectedObject.value)
  }

  async componentDidMount() {
    clearZmccDataOnFiltersApplied();
    const { fetchMasterData, fetchCartDataFromLocalStorage, changeLoader, location, fetchStocksForUser, categoriesAndSystems } = this.props;
    const { isLabsLocation, isZMCCCalendar } = this.state;
    const stockUrl = isLabsLocation ? URLS.countriesLabsLocation : `${URLS.countryList}?loantype=dla-stocks`;
    changeLoader(true);
    fetchCartDataFromLocalStorage();
    await fetchMasterData(stockUrl, actionTypes.FETCH_COUNTRY_LIST);
    if (!categoriesAndSystems || categoriesAndSystems.length === 0 && !isLabsLocation) {
     fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
    }
    await fetchMasterData(URLS.categoryList, actionTypes.FETCH_CATEGORY_LIST);
    if (!categoriesAndSystems || categoriesAndSystems.length === 0 && isLabsLocation) {
      fetchMasterData(URLS.systemclassesLabsLocation, FETCH_LABS_LOCATION_SYSTEM_CLASSES)
    }
    if(isZMCCCalendar) {
      fetchMasterData(`${URLS.zmccCountryAndStocksList}?excludeRequesterRole=true`, actionTypes.FETCH_RMS_ZMCC_COUNTRIES);
    }
    this.fetchAvailableKitList(this.state.data, location, 'isInitial');
    this.props.fetchMasterData(`${URLS.transactionStatuses}?type=GENERAL`, actionStatusTypes.FETCH_TRANSACTION_STATUSES);
    this.props.fetchMasterData(`${URLS.transactionStatuses}?type=PARTNER_LABS_LOCATION`, actionStatusTypes.FETCH_LABS_LOCATION_TRANSACTION_STATUSES);
  }

  componentDidUpdate(prevProps) {
    const { filters, labsLocationFilters, language } = this.props;
    const { isLabsLocation } = this.state;
    const stockUrl = isLabsLocation ? URLS.countriesLabsLocation : `${URLS.countryList}?loantype=dla-stocks`;

    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      const newData = { data: { ...this.state.data, filter: filters } };
      this.setState({ ...newData });
    }

    if(language !== prevProps.language){
      this.props.fetchMasterData(stockUrl, actionTypes.FETCH_COUNTRY_LIST);
      this.props.fetchMasterData(`${URLS.transactionStatuses}?type=GENERAL`, actionStatusTypes.FETCH_TRANSACTION_STATUSES);
      this.props.fetchMasterData(`${URLS.transactionStatuses}?type=PARTNER_LABS_LOCATION`, actionStatusTypes.FETCH_LABS_LOCATION_TRANSACTION_STATUSES);
    }

    if (prevProps.labsLocationFilters && !isEqual(prevProps.labsLocationFilters, labsLocationFilters)) {
      const newData = { data: { ...this.state.data, filter: labsLocationFilters } };
      this.setState({ ...newData });
    }
  }

  componentWillUnmount() {
    clearZmccDataOnFiltersApplied();
    removeFromStorage(identifiers.homepageFilter);
    this.debounceUpdateData.cancel();
  }

  fetchAvailableKitList = async (data, location, isInitial, zmccFilterDates = {}) => {
    const { changeLoader, fetchAvailableKitList } = this.props;
    changeLoader(true)
    await fetchAvailableKitList(data, location, isInitial, zmccFilterDates);
    this.setState({ contentLoaded: true }, () => {
      changeLoader(false);
    });
  };

  showOrHideFilter = () => {
    this.setState(prevState => ({ isShowFilter: !prevState.isShowFilter }));
  };

  showMapModal = () => {
    this.setState(prevState => ({ showGoogleMapModal: !prevState.showGoogleMapModal }), () => {
      $('#googlemap-modal').modal('show');
    });
  }

  handleFilterChange = (key, value, fullValue) => {
    const { location } = this.props;
    const {isZMCCCalendar}= this.state;
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.filter[key] = value;
        newData.filter[`${key}FullValue`] = fullValue;
        if (key === 'stock') {
          newData.filter.category = null;
          newData.filter.categoryFullValue = null;
        }
        return { data: newData };
      },
      () => {
        isZMCCCalendar && saveToStorage(identifiers.zmccFilters, this.state.data);
        const {zmccCurrentFetchFromDate, zmccCurrentFetchEndDate} = this.state
        let zmccMonthsData = {
          zmccFetchFromDate: zmccCurrentFetchFromDate,
          zmccFetchToDate: zmccCurrentFetchEndDate,
          filtersApplied: true
        }
        this.fetchAvailableKitList(this.state.data, location, false, isZMCCCalendar ? zmccMonthsData: {});
      },
    );
  };

  resetFilter = async key => {
    const { location } = this.props;
    const { isZMCCCalendar } = this.state;

    if (isZMCCCalendar){
      saveToStorage(identifiers.zmccFilters, {filter: {...INITIAL_FILTER}});
    }
    if (!key) {
      await this.setState(prevState => ({
        data: {
          ...prevState.data,
          filter: {
            ...INITIAL_FILTER,
            loanType: prevState.data.filter.loanType,
            loanTypeFullValue: prevState.data.filter.loanTypeFullValue,
          },
        },
      }));
    } else {
      await this.setState(prevState => ({
        data: {
          ...prevState.data,
          filter: {
            ...INITIAL_FILTER,
            ...prevState.data.filter,
            [key]: null,
            [`${key}FullValue`]: null,
          },
        },
      }));
    }
    this.fetchAvailableKitList(this.state.data, location, false, isZMCCCalendar ? {filtersApplied: true}: {});
  };

  handleSearchChange = e => {
    const { value } = e.target;
    const { location } = this.props;
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.page = 1;
        newData.searchTerm = value;
        return { data: newData };
      },
      () => {
        this.debounceUpdateData({ fetchAvailableKitList: () => this.fetchAvailableKitList(this.state.data, location) });
      },
    );
  };

  handleApiCallToKits(state) {
    const { location } = this.props;
      this.fetchAvailableKitList(
        this.state.data,
        location,
      );
  }

  handleSort = name => {
    this.setState(
      prevState => {
        const newData = JSON.parse(JSON.stringify(prevState.data));
        Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
        newData.sort[name] = prevState.data.sort[name] ? prevState.data.sort[name] === 'asc' ? 'desc' : 'asc' : 'asc';
        return { data: newData };
      },
      () => this.handleApiCallToKits(this.state),
    );
  };

  debounceUpdateData = debounce(({ fetchAvailableKitList }) => {
    const { isLabsLocation, isZMCCCalendar } = this.state;
    const { location } = this.props;

    if (isLabsLocation) {
      saveToStorage(identifiers.filtersLabsLocationCalendar, this.state.data.searchTerm);
    } else if (isZMCCCalendar){
      saveToStorage(identifiers.zmccFilters, this.state.data);
    }
    else {
      saveToStorage(identifiers.filtersCalendar, this.state.data.searchTerm);
    }

    fetchAvailableKitList(this.state.data, location);
  }, 1000);

  handlePageChange = page => {
    const { location } = this.props;
    this.setState(
      prevState => {
        const newData = { ...prevState.data };

        newData.page = page + 1;
        return { data: newData };
      },
      () => this.fetchAvailableKitList(this.state.data, location),
    );
  };

  handleItemsPerPage = value => {
    const { location } = this.props;
    this.setState(
      prevState => ({ data: { ...prevState.data, size: value } }),
      () => {
        this.fetchAvailableKitList(this.state.data, location);
      },
    );
  };

  checkToShowing24hrsReservation = () => {
    const { cart } = this.props.loanList;
    const isLabsLocation = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('labs-location-calendar');
    const isZmccCalendar = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('zmcc-calendar');
    const updatedCart = cart.filter(item => !item.isZMCCCalendar && !item.isLabsLocation);
    if(updatedCart.length === 1 && updatedCart[0]?.isTwentyFourReservationAllowed === true && !isLabsLocation && !isZmccCalendar){
      return true
    }
    return false
  }

  handleAddItemToCart = async items => {
    const { isLabsLocation, isZMCCCalendar } = this.state;
    const { addItemToCart, removeBooking, checkAvailability, toggleActionMessage, labsLocationAdvanceBookingError,fetchClandarBlocking } = this.props;

    if (isZMCCCalendar && !items.isZMCCSlotAvailibility) {
      let changedItems;
      if (Array.isArray(items)) {
        changedItems = items.map(item => ({ ...item, isAvailabilityChecked: true, isLabsLocation, isZMCCCalendar  }));
      } else {
        changedItems = [{ ...items, isAvailabilityChecked: true, isLabsLocation, isZMCCCalendar  }];
      }
      addItemToCart(changedItems);
      removeBooking(changedItems);
      return;
    }
    else{
      if (items.isZMCCSlotAvailibility){
        let changedItems;
        if (Array.isArray(items)) {
          changedItems = items.map(item => ({ ...item, isAvailabilityChecked: true, isSelected:false, isLabsLocation, isZMCCCalendar, isZMCCSlotAvailibility : false   }));
        } else {
          changedItems = [{ ...items, isAvailabilityChecked: true, isLabsLocation, isSelected:false, isZMCCCalendar, isZMCCSlotAvailibility : false    }];
        }
        addItemToCart(changedItems);
        return
      }
    }

    if (isLabsLocation) {
      let changedItems;
      if (Array.isArray(items)) {
        changedItems = items.map(item => ({ ...item, isAvailabilityChecked: true, isLabsLocation, isZMCCCalendar }));
      } else {
        changedItems = [{ ...items, isAvailabilityChecked: true, isLabsLocation, isZMCCCalendar }];
      }
      const fromdate = moment(items && items.length > 0 ? items[0].start :items.start).format('YYYY-MM-DD');
      const kitIds = items && items.length > 0 ? items.map(item => item.kit.kitInformationId) : [items.kit.kitInformationId];
      const {success, error} = await labsLocationAdvanceBookingError(kitIds, fromdate);
      if (!success) {
        if (error && error.response && error.response.data && error.response.data.status === 422) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', error.response.data.message, true);
        }
      }
      else{
        addItemToCart(changedItems);
        removeBooking(changedItems);
      }

      return;
    }
   const isBlockEnabled = items.kit && items.kit.stockInformation && items.kit.stockInformation.workflowCategoryAnswers && items.kit.stockInformation.workflowCategoryAnswers.find(item => item.workflowCategoryQuestions.workflowCategoryQuestionsId == 34);
    if (isBlockEnabled && isBlockEnabled.value && isBlockEnabled.value === 'yes') {
      const result = await fetchClandarBlocking();
      if (result.res && result.res.data && result.res.data.length > 0) {
        this.setState(prevState => ({ showCalendarBlockPopup: true , calendarBlockList: result.res.data}));
        $('#calendar-block').modal('show');
        return
        }
    }
    await checkAvailability(items)
      .then(res => {
        if (res.success) {
          let changedItems;
          if (Array.isArray(items)) {
            changedItems = items.map(item => ({ ...item, isAvailabilityChecked: true, isLabsLocation, isZMCCCalendar }));
          } else {
            changedItems = [{ ...items, isAvailabilityChecked: true, isLabsLocation, isZMCCCalendar }];
          }
          addItemToCart(changedItems);
          removeBooking(changedItems);
          this.setState({
            alternateMessageData: {
              displayMessage: false,
              from: '',
              to: '',
            },
          });
        }
      })
      .catch(err => {
        if (!err.isUIError) {
          if (err.data && err.data.kitAvailaleToCheck && err.data.kitAvailableToCheckMsg) {
            scrollToTop(500);
            toggleActionMessage(true, 'info', err.data.kitAvailableToCheckMsg, true);
          } else if (err.data && err.data.maxLoanDurationFound) {
            scrollToTop(500);
            toggleActionMessage(true, 'info', err.data.kitAvailableToCheckMsg, true);
          } else if (err.data && err.data.suggestions[0]) {
            scrollToTop(500);
            this.setState({
              alternateMessageData: {
                displayMessage: true,
                from: err.data.suggestions[0].reservationFrom,
                to: err.data.suggestions[0].reservationTo,
              },
            });
          }
        } else {
          scrollToTop(500);
          if (err.error && err.error.response) {
            toggleActionMessage(true, 'error', err.error.response.data.message, true);
          } else {
            toggleActionMessage(true, 'error', 'somethingWentWrongMessage');
          }
        }
      });
  };

  handleUpdateItem = item => {
    const { updateItem } = this.props;
    updateItem(item);
  };

  goToLoanList = async (action) => {
    const { isLabsLocation, isZMCCCalendar } = this.state;
    const { history, zmccCreateSlotAvailaibility, zmccRemoveSlotAvailaibility } = this.props;
    const { cart } = this.props.loanList;

    if (action == 'removeAvailibility') {
      const dataFromRemovingCart = fetchFromStorage(identifiers.removeAvailibilityForZmcc);
      const deleteIds ={ids:[]};
      dataFromRemovingCart && dataFromRemovingCart.map(item => deleteIds['ids'].push(item.id));
      const result = await zmccRemoveSlotAvailaibility(deleteIds);
      if (result.success){
        saveToStorage(identifiers.removeAvailibilityForZmcc, []);
        saveToStorage(identifiers.cart, []);

        window.location.reload();
      }
      else {
        if (result && result.error && result.error.response && result.error.response.data.message){
          const message = result.error.response.data.message;
          this.props.toggleActionMessage(true, 'error', message, true);
          scrollToTop(500);
        }
        else {
          this.props.toggleActionMessage(true, 'error', 'somethingWentWrongMessage');
          scrollToTop(500);
        }
      }

    } else {
      let url;
      if (isZMCCCalendar){
        const dataToSend =[];
        const kitWiseData = {};
        const kitIds = []
        const updatedCart = cart.filter(item => item.isZMCCCalendar);
        updatedCart.map(data => {
          if (!kitIds.includes(data["kit"]['kitInformationId'])) {
            kitWiseData[data["kit"]['kitInformationId']] = {
              "kitId" : data["kit"]["kitInformationId"],
              "roomGroupName": data["kit"]["roomGroupName"],
              "selectedDates":[
                {
                  "from" : moment(data["start"]).format('YYYY-MM-DD'),
                  "to": moment(data["start"]).format('YYYY-MM-DD')
                }
              ]
            }
          }
          else {
            kitWiseData[data["kit"]['kitInformationId']]["selectedDates"].push(
              {
                "from": moment(data["start"]).format('YYYY-MM-DD'),
                "to" : moment(data["start"]).format('YYYY-MM-DD')
              }
            )
          }
          if (!kitIds.includes(data["kit"]['kitInformationId'])){
            kitIds.push(data["kit"]['kitInformationId']);
          }
        })
        kitIds.forEach(kitId => dataToSend.push(kitWiseData[kitId]));
        const result = await zmccCreateSlotAvailaibility(dataToSend);
        if (result.success){
          saveToStorage(identifiers.cart, []);
          window.location.reload();
        }
        else {
          if (result && result.error && result.error.response && result.error.response.data.message){
            const message = result.error.response.data.message;
            this.props.toggleActionMessage(true, 'error', message, true);
            scrollToTop(500);
          }
          else {
            this.props.toggleActionMessage(true, 'error', 'somethingWentWrongMessage');
            scrollToTop(500);
          }
        }
    }
    else{
      const updatedCart = cart.filter(item => !item.isZMCCCalendar && !item.isLabsLocation);
      if(!isLabsLocation && updatedCart.length === 1){
        url = '/kit-loan';
        return history.push(url);
      }
      if (isLabsLocation && updatedCart.length === 1 ) {
        url = '/labs-location-kit-loan'
      } else {
        url = isLabsLocation ? '/labs-location-loan-list' : !isZMCCCalendar && '/loan-list';
      }
      history.push(url);
    }
    }

  };

  handleConflict = isConflict => {
    scrollToTop(500);
    this.props.toggleActionMessage(isConflict, 'error', 'conflictBooking');
  };

  handleBookingReject = () => {
    scrollToTop(500);
    this.props.toggleActionMessage(true, 'error', 'pastDateBooking');
  };

  closeMessage = () => {
    this.setState({
      alternateMessageData: {
        displayMessage: false,
        from: '',
        to: 'data.deliveryTo',
      },
    });
  };

  handleNonAvailability = (from, to) => {
    scrollToTop(500);

    const message = strings.availabilityMessage
      .replace('{from}', moment(from).format('DD-MM-YYYY'))
      .replace('{to}', moment(to).format('DD-MM-YYYY'));

    this.props.toggleActionMessage(true, 'error', message, true);
  };

  filterCart(cart) {
    const newCart = [];
    cart.forEach(item => {
      if (!item.hideOnCalendar) {
        newCart.push(item);
      }
    });
    return newCart;
  }

  createDataForSystemClassDropdown() {
    const { isLabsLocation, isZMCCCalendar } = this.state;
    const { stock } = this.state.data.filter ? this.state.data.filter : {stock: null};
    const { categoriesAndSystems, labsLocationSystemClasses } = this.props;
    let arrayOfSystemClass = [];

    if (isLabsLocation && !labsLocationSystemClasses.systemClasses && !isZMCCCalendar) {
      return labsLocationSystemClasses;
    }

    if (stock && stock.length > 0) {
      const { stockSystemClasses, systemClasses } = categoriesAndSystems;
      stock.forEach(stock => {
        const data = stockSystemClasses && stockSystemClasses[stock];
        if (data) {
          data.forEach(item => {
            arrayOfSystemClass.push(systemClasses.find(systemClass => item === systemClass.systemClassId));
          });
        }
      });
    } else {
      arrayOfSystemClass = categoriesAndSystems && categoriesAndSystems.systemClasses;
    }

    return Array.from(new Set(arrayOfSystemClass)).sort((a, b) => {
      const systemClassA = a.systemClassName.toUpperCase();
      const systemClassB = b.systemClassName.toUpperCase();

      return systemClassA > systemClassB ? 1 : -1;
    });
  }


  reserveKits = () => {
    /**
     * ? This function is used to reserve the kits
     * ? This function will check selected kit is available for 24 hours reservation or not
     * ! Do we need to check dates, zmcc condition and all here?
     */
    const { cart } = this.props.loanList;
    const {reserveKit, toggleActionMessage, history} = this.props
    const updatedCart = cart.filter(item => (!item.isZMCCCalendar && !item.isLabsLocation));
    if(updatedCart.length === 0){
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'selectKitsForBooking');
      return;
    }
    const reservationAllowed = updatedCart.every(item => item.isTwentyFourReservationAllowed === true);
    if(!reservationAllowed){
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'oneOrMoreKitsNotAllowedForReserve');
      return
    }
    let isDifferentPeriodsKits = false;
    updatedCart.forEach(item => {
      if (updatedCart && updatedCart[0] && !(updatedCart[0].start === item.start && updatedCart[0].end === item.end)) return isDifferentPeriodsKits = true;
      return isDifferentPeriodsKits = false;
    });
    if (isDifferentPeriodsKits) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'demoPeriodError');
      return;
    }
    reserveKit(updatedCart, history);
  }

  opneContactPopup = () =>{
    this.setState(prevState => ({ showContactPopup: !prevState.showContactPopup }), () => {
      $('#contact').modal('show');
    });
  };

  changeShowContactPopup = () =>{
    this.setState(prevState => ({ showContactPopup: !prevState.showContactPopup }))
  }

  createBooking = () => {
    const { cart } = this.props.loanList;
    const { createZmccBooking, history, user } = this.props;
    const zmccLoan = cart && cart.length > 0 && cart.filter(item => item.isZMCCCalendar);
    let dataToSend = {}
    dataToSend.transactionId = null;
    dataToSend.stockInformationId = zmccLoan[0].kit.stockInformation.id;
    dataToSend.kitInformationId = zmccLoan[0].kit.kitInformationId;
    dataToSend.suggestedDate = moment(zmccLoan[0].start).format('YYYY-MM-DD');
    dataToSend.zmccCalendarBlockDTO = {kitId: zmccLoan[0].kit.kitInformationId, roomGroupName: zmccLoan[0].kit.roomGroupName, selectedDates: [{from: moment(zmccLoan[0].start).format('YYYY-MM-DD'), to:moment(zmccLoan[0].end).format('YYYY-MM-DD') } ]}
    createZmccBooking([dataToSend], history);
    saveToStorage(saveToStorage(identifiers.cart, []));
    saveToStorage(saveToStorage(identifiers.removeAvailibilityForZmcc, []));
  }
  checkRolesToDisplayCreateBooking = () => {
    const {user} = this.props;
    if (user.admin) {
      return true;
    }
    const { cart } = this.props.loanList;
    const filterZmccLoan = cart && cart.length > 0 && cart.filter(item => item.isZMCCCalendar);
    const stockId = filterZmccLoan && filterZmccLoan.length > 0 && filterZmccLoan[0]?.kit?.stockInformation?.id;
    const roles = ['Stock Controller', 'Dispatcher', 'Operator', 'Reconditioning'];
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      if (user && user.stockRoles && user.stockRoles.length > 0) {
        return roles.some(role => {
          return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId);
        });
      }
    }
    return false;
  }

  checkRolesToDisplayCreateAvailability = () => {
    const {user} = this.props;
    if (user.admin) {
      return true;
    }
    const { cart } = this.props.loanList;
    const roles = ['Stock Controller'];
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      if (user && user.stockRoles && user.stockRoles.length > 0) {
        return roles.some(role => {
          return user.stockRoles.some(stockRole => stockRole.roleName === role && stockRole.businessUnitName == 'RMS (ZMCC)');
        });
      }
    }
    return false;
  }
  getStartDate = () => {
    // This method is for getting the loan from date if applied on home page
    // This should set date selected to calendar
    if(this.props.history?.location?.state?.loanFromDate){
      let loanFromDate = this.props.history?.location?.state?.loanFromDate
      if(moment(loanFromDate).isValid()){
        return loanFromDate;
      }
    }
    return undefined;
  }

  handleZmccPrevAndNextClick = async (currentDateOnCalendar) => {
    const {zmccExistingMonths} = this.props.calendar
    const {updateZmccDatesFilters, location, fetchAvailableKitList, changeLoader} = this.props
    const MONTH_FORMAT = "YYYY-MM"
    const DAY_FORMAT = "YYYY-MM-DD"

    let prevMonth = moment(currentDateOnCalendar).subtract(1, "month").format(MONTH_FORMAT)
    let nextMonth = moment(currentDateOnCalendar).add(1, "month").format(MONTH_FORMAT)
    let currentMonth = moment(currentDateOnCalendar).format(MONTH_FORMAT)
    const needsPrefetch = !zmccExistingMonths.has(prevMonth) || !zmccExistingMonths.has(nextMonth);
    if (needsPrefetch) {
      let prevDirection = !zmccExistingMonths.has(prevMonth) ? -1: !zmccExistingMonths.has(currentMonth) ? 0: 1;
      let nextDirection = !zmccExistingMonths.has(nextMonth) ? 1: !zmccExistingMonths.has(currentMonth) ? 0: -1;

      const prefetchMonthStart = moment(currentDateOnCalendar).add(prevDirection, "month").startOf("month").format(DAY_FORMAT);
      const prefetchMonthEnd = moment(currentDateOnCalendar).add(nextDirection, "month").endOf("month").format(DAY_FORMAT);
      if(prevDirection != nextDirection || !zmccExistingMonths.has(currentMonth)){
        changeLoader(true);
      }
      updateZmccDatesFilters([prevMonth, currentMonth, nextMonth]); 
      let zmccMonthsData = {
        zmccFetchFromDate: prefetchMonthStart,
        zmccFetchToDate: prefetchMonthEnd,
        filtersApplied: false
      }
      await fetchAvailableKitList(this.state.data, location, false, zmccMonthsData);
    
      this.setState({ contentLoaded: true }, () => {
        changeLoader(false);
        this.setState({ zmccCurrentFetchFromDate: prefetchMonthStart, zmccCurrentFetchEndDate: prefetchMonthEnd });
      });
    }
  }

  render() {
    const { cart } = this.props.loanList;
    const { kits, bookings, alreadyBooked, operatorsList } = this.props.calendar;
    const { displayActionMessage, type, message, showAsIs } = this.props.appActions;
    const { contentLoaded, data, isShowFilter, stockPlaceholder, categoryPlaceholder, homepageFilter, isLabsLocation, showGoogleMapModal, showContactPopup, showCalendarBlockPopup, calendarBlockList, isZMCCCalendar } =
      this.state;
    const { displayMessage, from, to } = this.state.alternateMessageData;
    const { addBookings, removeItemFromCart, removeBooking, loanTypes, countries, toggleActionMessage, categoriesAndSystems,getStockInformation, sendKitSignalizeDemand, fetchSystemClass, } = this.props;
    const listOfDropdown =[{id:1, value:'kitName', label: strings.name},{id:2, value:'dateOfManufacture', label: strings.Age}, {id:3, value:'serialNumber', label: strings.serialNumber}, {id:4, value:'itemNumber', label: strings.itemNumber}]
    const dataFromCart = fetchFromStorage(identifiers.removeAvailibilityForZmcc);

    const checkSum =() =>{
      const dataFromCart = fetchFromStorage(identifiers.removeAvailibilityForZmcc);
      let result = false;
        if (dataFromCart && dataFromCart.length >0){
          if (dataFromCart && dataFromCart.length>0){
            dataFromCart.map(each => {
              if (each.hasOwnProperty('isZMCCSlotAvailibility')){
                result = true;
                return result;
              }
            })
          }
        }
        return result;
    }
    const showRemoveAvialibilityButton = checkSum();

    const demoCalendarColorLegends =[
      { text: `${strings.delivery + ' ' + strings.and + ' ' + strings.returnDelivery}`, backgroundColor: lightBlue, supportingText: ''},
      { text:strings.demo, backgroundColor: darkBlue, supportingText: '' },
      { text: strings.reconditioning , backgroundColor: darkGreenColor, supportingText: ''},
      { text: strings.twentyFourHourReservation, backgroundColor: battleshipGrey, supportingText: '' },
      { text: strings.loanPeriodOverdrawn, backgroundColor: redColor, supportingText: '' },
    ];

    const llCalendarColorLegends =[
      { text:strings.demo, backgroundColor: darkBlue, supportingText: '' },
    ];

    const zmccCalendarColorLegends =[
      { text:strings.availableToBook, backgroundColor: lightBlue },
      { text:strings.demo, backgroundColor: darkBlue },
      { text:strings.legendConfirm, backgroundColor: appleGreen },
      { text:strings.legendMissing, backgroundColor: pureRed },
      { text:strings.legendNonIssue, backgroundColor: brilliantBlue },
      { text:strings.legendNotAvailable, backgroundColor: mediumGray },
    ];

    return (
      <div>
        {!!displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : strings[message]} />}
        {!!displayMessage && (
          <AlternateSlotMessage
            from={moment.utc(from).format('DD-MM-YYYY')}
            to={moment.utc(to).format('DD-MM-YYYY')}
            onClose={this.closeMessage}
          />
        )}

        <div className='container-fluid mb-3'>
          <Breadcrumbs data={calendarBreadcrumbData} />
          <div className='row no-gutters align-items-center'>
            <h1 className='col-md col-12'>
              <strong>{this.state.isZMCCCalendar ? 'ZMCC Calendar' :strings.calendar}</strong>
            </h1>
            <div className='col-md-auto col mr-3'>
              <div className='input-wrapper'>
                <SearchField onChange={this.handleSearchChange} initialValue={data.searchTerm} />
              </div>
            </div>
            <div className='col-auto'>
              <Button
                bgFill={false}
                iconName='filter'
                onClick={this.showOrHideFilter}
                value={isShowFilter ? strings.hideFilter : strings.showFilter}
                styleClass='filter-button'
              />
            </div>
          </div>
        </div>
        <Filter
          value={homepageFilter}
          isShowFilter={isShowFilter}
          countries={countries}
          categoriesAndSystems={this.createDataForSystemClassDropdown()}
          loanTypes={loanTypes}
          stockPlaceholder={stockPlaceholder}
          categoryPlaceholder={categoryPlaceholder}
          handleFilterChange={this.handleFilterChange}
          selected={data.filter}
          resetFilter={this.resetFilter}
          isLabsLocation={isLabsLocation}
          showMapModal={this.showMapModal}
          isZMCCCalendar={isZMCCCalendar}
        />
        <div className='container-fluid'>
          {contentLoaded && !!kits && !!kits.content && !!kits.content.length && (
            <AppScheduler
              cart={this.filterCart(cart)}
              events={
                isLabsLocation
                  ? bookings.filter(item => item.isLabsLocation)
                  : bookings.filter(item => !item.isLabsLocation)
              }
              resources={kits.content}
              alreadyBooked={alreadyBooked}
              maxInFuture={30}
              maxInPast={6}
              onRejectBooking={this.handleBookingReject}
              onConflictOccurred={this.handleConflict}
              onUpdateEvent={this.handleUpdateItem}
              onRemoveBookingFromCalendar={removeBooking}
              onSelectForBooking={addBookings}
              onAddNewBooking={this.handleAddItemToCart}
              onRemoveBooking={removeItemFromCart}
              onNonAvailability={this.handleNonAvailability}
              toggleActionMessage={toggleActionMessage}
              isLabsLocation={isLabsLocation}
              handleSort={this.handleSort}
              sortFields={this.state.data.sort}
              handleChangeForFilter={this.handleChangeForFilter}
              listOfDropdown={listOfDropdown}
              selectedOnFilterCalendar={this.state.selectedOnFilterCalendar}
              isZMCCCalendar={isZMCCCalendar}
              generalTransactionStatuses={this.props.transactionStatuses}
              llTransactionStatuses= {this.props.labsLocationTransactionStatuses}
              operatorsList={operatorsList}
              changeLoader={this.props.changeLoader}
              history={this.props.history}
              startDate={this.getStartDate()}
              handleZmccPrevAndNextClick={isZMCCCalendar ? this.handleZmccPrevAndNextClick: null}
            />
          )}
          {!!kits && (!kits.content || !kits.content.length) && (
            <div className='d-flex justify-content-center'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}

          {!!kits && !!kits.content && !!kits.content.length && (
            <>
            <div className='row'>
              <div className='col-md-4 d-flex mb-3 align-items-center'>
                <ItemsPerPage
                  itemsPerPage={toArray(itemsPerPage)}
                  handleItemsPerPage={this.handleItemsPerPage}
                  value={itemsPerPage[data.size]}
                />
                <Pagination
                  currentPage={data.page - 1}
                  totalPages={kits.totalPages}
                  onPageChange={this.handlePageChange}
                />
              </div>
              {isLabsLocation && (
              <div className='scheduler-legend col-md-8 d-flex mb-2'>
               
                <div className='scheduler-legend-item legend-loan'>
                  <div />
                  <div>{strings.demo}</div>
                </div>
                </div>
                )}
              {(!isLabsLocation && !isZMCCCalendar) &&
              <div style={{display: 'flex', alignItems: 'center', marginLeft:'auto'}}>
                <p>{strings.calendarKitNotFoundText}</p>
                <div className='ml-2'>
                  <Button
                  value={strings.contact}
                  iconName='plus'
                  onClick={() => this.opneContactPopup()}
                  />
                </div>
              </div>
              }
            </div>
            {
              (!isLabsLocation && !isZMCCCalendar ) && <ColorLegends items={demoCalendarColorLegends } />
            }
            <div className='zmcc-scheduler-legend'>
              {!isLabsLocation && isZMCCCalendar && (
                <div className='scheduler-legend-item legend-delivery zmcc-legends'>
                  <div>
                    {strings.availableToBook}
                  </div>
                  <div />
                </div>
              )}
              {isZMCCCalendar && (
                <div className='scheduler-legend-item legend-loan zmcc-legends'>
                  <div>
                    {strings.demo}
                  </div>
                  <div />
                </div>
              )}
              {isZMCCCalendar && (
                <div className='scheduler-legend-item legend-confirm zmcc-legends'>
                  <div>
                    {strings.legendConfirm}
                  </div>
                  <div />
                </div>
              )}
              {isZMCCCalendar && (
                <div className='scheduler-legend-item legend-missing zmcc-legends'>
                  <div>
                    {strings.legendMissing}
                  </div>
                  <div />
                </div>
              )}
              {isZMCCCalendar && (
                <div className='scheduler-legend-item legend-non-issue zmcc-legends'>
                  <div>
                    {strings.legendNonIssue}
                  </div>
                  <div />
                </div>
              )}
              {isZMCCCalendar && (
                <div className='scheduler-legend-item legend-not-available zmcc-legends'>
                  <div>
                    {strings.legendNotAvailable}
                  </div>
                  <div />
                </div>
              )}
            </div>

            </>

          )}
          {!!kits &&
            !!kits.content &&
            !!kits.content.length &&
            ((!isLabsLocation && !!cart.filter(item => !item.isLabsLocation).length) ||
              (isLabsLocation && !!cart.filter(item => item.isLabsLocation).length) ||
              (isZMCCCalendar && dataFromCart && !!dataFromCart.filter(item => !item.isLabsLocation && item.isZMCCCalendar).length)) && (
              <div className='d-flex flex-row justify-content-end'>
                {
                this.checkToShowing24hrsReservation() &&
                  <Button
                    styleClass='reserve-button mr-md-2'
                    value={strings.reserve24}
                    iconName='reserve'
                    bgFill={false}
                    onClick={this.reserveKits}
                  />
                }
                {
                  isZMCCCalendar && cart.filter(item => item?.isZMCCCalendar ).length === 1 &&
                  <Button
                    styleClass='btn-max-width-300'
                    value={(isZMCCCalendar && !showRemoveAvialibilityButton  && !this.checkRolesToDisplayCreateAvailability()) ? 'Create Availability' : (isZMCCCalendar && !showRemoveAvialibilityButton  && this.checkRolesToDisplayCreateAvailability()) ? 'Create Availability' :  (isZMCCCalendar && showRemoveAvialibilityButton) ? 'Remove Availibility' : (!isZMCCCalendar && cart.filter(item => !item?.isZMCCCalendar ).length === 1 && strings.continueToBooking)}
                    onClick={() => this.goToLoanList(isZMCCCalendar && showRemoveAvialibilityButton && "removeAvailibility")}
                    disabled = {isZMCCCalendar && !this.checkRolesToDisplayCreateAvailability()}
                />
                } 
                {
                  !isZMCCCalendar &&
                  <Button
                    styleClass='btn-max-width-300'
                    value={isLabsLocation ? strings.continueToBooking : ( !isLabsLocation && !isZMCCCalendar && cart.filter(item => !item?.isLabsLocation && !item?.isZMCCCalendar ).length === 1 ? strings.continueToBooking: strings.continueToLoanListText)}
                    onClick={() => this.goToLoanList(isZMCCCalendar && showRemoveAvialibilityButton && "removeAvailibility")}
                  />
                }
                {(isZMCCCalendar && (cart?.length === 1 || dataFromCart?.length ===1 ) && this.checkRolesToDisplayCreateBooking()) &&
                    <Button
                      styleClass='btn-max-width-300 ml-md-2'
                      value={strings.createBooking}
                      onClick={() => this.createBooking()}
                    />}
              </div>
            )}
            {(!isLabsLocation && isZMCCCalendar) &&<div style={{display: 'flex', alignItems: 'center'}}>
            <p>{strings.calendarKitNotFoundText}</p>
            <div className='ml-2'>
              <Button
              value={strings.contact}
              iconName='plus'
              onClick={() => this.opneContactPopup()}
            />

            </div>

            </div>}
        </div>
        {isLabsLocation && showGoogleMapModal && (
          <ShowGoogleMap
            id='googlemap-modal'
            locations={countries}
            handleFilterChange={this.handleFilterChange}
            calenderState={this.state.data}
          />
        )}
        {!isLabsLocation && showContactPopup &&
          <ContactPopup
            id='contact'
            countries={countries}
            selected={data.filter}
            getStockInformation={getStockInformation}
            sendKitSignalizeDemand={sendKitSignalizeDemand}
            changeShowContactPopup={this.changeShowContactPopup}
            fetchSystemClass={fetchSystemClass}
      />
        }

        {showCalendarBlockPopup &&
        <BlockingCalendarMessagPopup
          id = 'calendar-block'
          tranactionDelayedList={calendarBlockList}
        />}
      </div>
    );
  }
}

export default Calendar;
