import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';
import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { useZmccTransactionDetails } from './ZmccTransactionDetails.hook';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import { breadcrumbsData } from './ZmccTransactionDetails.constants';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { NavLink } from 'react-router-dom';
import { get } from 'lodash';
import CreateMessage from 'modules/Transactions/TransactionDetails/Components/CreateMessage';
import { ZmccAdjustDates } from './Components/ZmccAdjustDates/ZmccAdjustDatesPopup.component';
import SelectTypePopup from './Components/SelectTypePopup/SelectTypePopup.component';
import './zmccTransactionDetails.style.scss'
import { EditOutlineIcon, MessageOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

export const ZmccTransactionDetails = props => {
 
  const { createZmccTransactionTabs, onClickEdit, transactionType, filteredConnectedTransaction,hasPermission, transactionId, getTabId, setFormType, showAllButton, setShowModal, tabId } =
    useZmccTransactionDetails(props);

  const { displayActionMessage, type, message, showAsIs } = props.appActions;
  const transactionNumber = get(props.zmccTransactionDetails, 'transactions.transactionNumber');
  let showTransaction = false;
    if (transactionId) {
      showTransaction = true;
    }
  
  return (
    <>
      {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : strings[message]} />}
      <div className='mb-3'>
        <div className='container-fluid'>
        <Breadcrumbs data={breadcrumbsData(transactionType, strings.zmccReservation)} />
        </div>
          <>
            <div className='container-fluid row no-gutters align-items-center mb-3'>
              <h1 className='col-md col-12 kit-title'>{strings.zmccReservation} #{transactionNumber}</h1>
              <div className='col-auto mt-3 mt-md-0'>
                <div className='d-flex flex-row align-items-center'>
                  <div className='prevTransaction d-none'>
                    <Button
                      dataTest='prev-transaction-button'
                      bgFill={false}
                      disabled={true}
                      iconName='arrow'
                      styleClass='br-w-0'
                      iconStyle='rotate-180 ml-0'
                      // onClick={!first && this.onPrevNextPress(this.props.match.params.id, 'prev')}
                    />
                  </div>
                  <div className='hide'>{strings.prevTransactionMessage}</div>
                  <div className='nextTransaction d-none'>
                    <Button
                      dataTest='next-transaction-button'
                      bgFill={false}
                      disabled={true}
                      iconName='arrow'
                      styleClass='mt-0 mr-2'
                      iconStyle='ml-0'
                      // onClick={!last && this.onPrevNextPress(this.props.match.params.id, 'next')}
                    />
                  </div>
                  <div className='hide'>{strings.nextTransactionMessage}</div>
                </div>
              </div>
              {hasPermission && (
                <div className='col-auto mt-3 mt-md-0 ml-2'>
                  <button className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 text-button' onClick={onClickEdit} disabled={!showAllButton} >
                    <EditOutlineIcon stroke={!showAllButton ? "#9A9B9C": "#0088D0"} />
                    <span className='ml-2 mt-2 color-blue new-ui-text-util-butons '>{strings.edit}</span>
                  </button>
                </div>
              )}
              <div
                className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 cursor-pointer'
                data-toggle={'modal'}
                data-target={'#adjustDatesModal'}
                data-test='transaction-details-adjust-dates-button'
                //   onClick={this.setAdjustDatesModalVisible}
                data-backdrop='static'
                data-keyboard='false'
              >
                <Icon name='newVersionCalendar' width={18} height={18} fill='#0088d0' className='plus-icon' viewBox='0 0 16 18'/>
                <span className='ml-2 mt-2 color-blue new-ui-text-util-butons '>{strings.adjustDates}</span>
              </div>
              <div
                className='btn-no-border btn-lighter mt-2 mt-md-0 mr-3 pl-0 cursor-pointer'
                data-toggle='modal'
                data-target={'#createMessageModal'}
                data-test='transaction-details-create-message-button'
                data-backdrop='static'
                data-keyboard='false'
              >
              <MessageOutlineIcon />
              <span className='ml-2 mt-2 color-blue new-ui-text-util-butons '>{strings.createMessage}</span>
            </div>
            </div>
           
            <Tabs
              tabs={createZmccTransactionTabs}
              getTabId={(e) => getTabId(e)}
              zmmcNoTypeModal={(e) => setShowModal(e)}
              currentTab={tabId}
              newUiVersion={true}
            />
          </>
          {(showTransaction && showAllButton) &&   
            <CreateMessage
              id='createMessageModal'
              createMessage={props.createMessage}
              transactionsId={transactionId}
              isZmcc={true}
              sendLogisticEmail={props.sendLogisticEmail}
              transactionNumber={transactionNumber}
              getTabId={(e) => getTabId(e)}
            />
            }
          {(showTransaction && showAllButton) &&
            <ZmccAdjustDates
              id='adjustDatesModal'
              zmccTransactionDetails={props.zmccTransactionDetails}
              saveAdjustDates={props.saveAdjustDates}
              transactionId={transactionId}
            />
          }
          <SelectTypePopup
            id='select-type'
          setFormType={(e) => setFormType(e)}
          setShowModal={(e) => setShowModal(e)}
          />
      </div>
    </>
  );
};

export default ZmccTransactionDetails;