import moment from 'moment';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';

import services from '../CreateKit/CreateKit.service';
import { uploadKitImage, uploadKitAttachment } from '../CreateKit/CreateKit.actions';
import {
    UPDATE_ZMCC_KIT_FORM,
    FETCH_WAREHOUSES_FOR_ZMCC_BU,
    FETCH_SYSTEM_CLASSES_ZMCC_BU,
    FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
    FETCH_ZMCC_KIT_DETAILS,
    RESET_ZMCC_BU_KIT_FORM,
    FETCH_WAREHOUSE_ROOMS_ZMCC_BU,
    FETCH_WAREHOUSE_USERS_ZMCC_BU,
    generalInformationModel
  } from './CreateZmccKit.constants';

import { informationFormModel } from './Components/InformationTab/InformationTab.constants';
import { VrDetailsModel } from '../KitDetails/Components/VrDetails/VrDetails.constants';

export const updateForm = payload => ({
    type: UPDATE_ZMCC_KIT_FORM,
    payload,
});

export const resetForm = () => ({
    type: RESET_ZMCC_BU_KIT_FORM,
});

export const fetchStocks = id => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(`${URLS.countryList}/business-unit?busunessUnitId=${id}`);

    dispatch({
      type: FETCH_WAREHOUSES_FOR_ZMCC_BU,
      payload: data.map(item => ({ ...item, systemClassText: item.systemClassName })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_WAREHOUSES_FOR_ZMCC_BU,
      payload: [],
    });
  }
};

export const fetchUsersOfBusinessUnit = businessUnitId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.getUsersFromBU(businessUnitId));
    dispatch({
      type: FETCH_WAREHOUSE_USERS_ZMCC_BU,
      payload: data.body,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const fetchSystemClasses = businessUnitId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemClass.replace('{id}', businessUnitId));

    dispatch({
      type: FETCH_SYSTEM_CLASSES_ZMCC_BU,
      payload: data.map(item => ({ ...item, systemClassName: item.systemClassText })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_SYSTEM_CLASSES_ZMCC_BU,
      payload: [],
    });
  }
};

export const fetchRoomsListForWarehouse = warehouseId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.roomsList(warehouseId));

    dispatch({
      type: FETCH_WAREHOUSE_ROOMS_ZMCC_BU,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};


export const fetchSystemMainComponent = systemClassId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemMainComponent.replace('{id}', systemClassId));

    dispatch({
      type: FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
      payload: data.map(item => ({ ...item, systemMainComponentName: item.systemMainComponentText })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
      payload: [],
    });
  }
};
export const fetchZMCCKitDetails = (id, isCopy) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await services.fetchKitDetailService(id);
    let kitDetails = data;
    if (isCopy) {
      kitDetails = {
        ...data,
        kitInformationId: null,
        serialNumber: '',
        dateOfManufacture: '',
        owner: null,
        warehouseRoom: null,
        isMobileOrExternal: '',
        windowsUserPassword: null,
        vrDetailsUserName: null,
        vrDetailsPassword: null
      };
    }
    const formData = createFormData(kitDetails);

    dispatch({
      type: FETCH_ZMCC_KIT_DETAILS,
      payload: { kitDetails, ...formData },
    });
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch(changeLoader(false));
  }
};

export const createZmccKit = isCopy => async (dispatch, getState) => {
  const { createZmccKitReducer } = getState();
  const {
    generalInformationForm,
    informationForm,
    furtherImportantComponentForm,
    salesComponentForm,
    freeAccessoriesForm,
    kitStatusForm,
    kitImagesToDelete,
    kitAttachmentsToDelete,
    kitImages,
    kitAttachments,
    kitInformationId,
    vrDetalisForm
  } = createZmccKitReducer;

  const validatedGeneralForm = validateForm({ form: generalInformationForm, model: generalInformationModel });
  if (!validatedGeneralForm.isFormValid) {
    dispatch(toggleActionMessage(true, 'error', 'fillKitHeaderInformation'));
    dispatch(updateForm({ generalInformationForm: validatedGeneralForm }));
    scrollToTop(500);

    return { success: false };
  }

  const validatedInformationForm = validateForm({ form: informationForm, model: informationFormModel });
  if (!validatedInformationForm.isFormValid) {
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
    dispatch(updateForm({ informationForm: validatedInformationForm }));
    scrollToTop(500);

    return { success: false };
  }

  const validatedVrDetailsForm = validateForm({ form: vrDetalisForm, model: VrDetailsModel });

  if (!validatedVrDetailsForm.isFormValid) {
    dispatch(toggleActionMessage(true, 'error', 'urlErrorMessage'));
    dispatch(updateForm({ vrDetalisForm: validatedVrDetailsForm }));
    scrollToTop(500);
    return { success: false };
  }

  const valuesToSend = {
    ...generalInformationForm.formData,
    ...informationForm.formData,
    ...kitStatusForm.formData,
    ...furtherImportantComponentForm.formData,
    ...salesComponentForm.formData,
    ...freeAccessoriesForm.formData,
    deleteImageIds: kitImagesToDelete,
    deleteAttachmentIds: kitAttachmentsToDelete,
    ...vrDetalisForm.formData
  };

  Object.keys(valuesToSend).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete valuesToSend[key];
    }
    if (key === 'stockInformation' || key === 'businessUnit' || key === 'country') {
      valuesToSend[key] = { id: valuesToSend[key] };
    }
    if (key === 'systemClass') {
      valuesToSend[key] = { systemClassId: valuesToSend[key] };
    }
    if (key === 'room') {
      valuesToSend[key] = { warehouseRoomId: valuesToSend[key] };
    }
    if (key === 'owner') {
      valuesToSend[key] = { userId: valuesToSend[key] };
    }
    if (key === 'systemMainComponent') {
      valuesToSend[key] = { systemMainComponentId: valuesToSend[key] };
    }
    if (key === 'kitCondition') {
      valuesToSend[key] = valuesToSend[key] ? { kitConditionId: valuesToSend[key] } : null;
    }
    if (key === 'dateOfManufacture') {
      valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : '';
    }
    if (key === 'refurbishmentFrom') {
      valuesToSend[key] =
        kitStatusForm.formData.kitCondition === 5
          ? moment(kitStatusForm.formData.refurbishmentFrom).format('YYYY-MM-DD')
          : null;
    }
    if (key === 'refurbishmentTo') {
      valuesToSend[key] =
        kitStatusForm.formData.kitCondition === 5
          ? moment(kitStatusForm.formData.refurbishmentTo).format('YYYY-MM-DD')
          : null;
    }
    if (key === 'ibase') {
      valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : '';
    }
  });

  valuesToSend.kitInformationId = kitInformationId || '';

  if (isCopy) {
    valuesToSend.kitImages = kitImages
      .filter(image => image.kitImagesId)
      .map(image => ({ ...image, kitImagesId: 0, preview: image.actualImageUrl }));
    valuesToSend.kitAttachments = kitAttachments
      .filter(file => file.kitAttachmentsId)
      .map(file => ({ ...file, kitAttachmentsId: 0 }));
  }

  const imagesToUpload = kitImages.filter(image => !image.kitImagesId);
  const attachmentsToUpload = kitAttachments.filter(file => !file.kitAttachmentsId);

  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.kit, valuesToSend);
    const message = isCopy
      ? 'kitCopySuccessMessage'
      : !informationForm.formData.room
      ? 'roomNotAddedMesage'
      : 'kitSavedSuccessMessage';
    const type = !informationForm.formData.room ? 'info' : 'success';
    if (imagesToUpload.length) {
      await dispatch(uploadKitImage(imagesToUpload, data.kitInformationId));
    }

    if (attachmentsToUpload.length) {
      await dispatch(uploadKitAttachment(attachmentsToUpload, data.kitInformationId));
    }

    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, type, message));

    return { success: true, kitInformationId: data.kitInformationId };
  } catch (err) {
    const response = err && err.response && err.response.status;
    dispatch(changeLoader(false));
    scrollToTop(500);
    if (response === 422 || response === 403) {
      let message = err.response.data.message.replace("Stock Controller", "ZMCC Manager");
      dispatch(toggleActionMessage(true, 'error', message, true));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }

    return { success: false };
  }
};

  export const createFormData = kitDetails => {
    const {
      kitReparation,
      kitInformationId,
      kitName,
      description,
      furtherImportantComponents,
      salesComponents,
      stockInformation,
      businessUnit,
      systemClass,
      systemMainComponent,
      ibase,
      serialNumber,
      dateOfManufacture,
      availabilityFrom,
      availabilityTo,
      kitCondition,
      storageLocation,
      comment,
      freeAccessories,
      kitImages,
      kitAttachments,
      warehouseRoom,
      owner,
      isMobileOrExternal
    } = kitDetails || {};
    const reparation = (kitReparation && kitReparation.length && kitReparation.find(item => item.isReparationTimeActive)) || {};
    const generalInformationForm = {
      formData: {
        kitInformationId: kitInformationId,
        kitName: kitName,
        description: description,
      },
      isFormValid: false,
    };
    const furtherImportantComponentForm = {
      formData: {
        furtherImportantComponents: furtherImportantComponents,
      },
      isFormValid: false,
    };
    const salesComponentForm = {
      formData: {
        salesComponents: salesComponents,
      },
      isFormValid: false,
    };
    const informationForm = {
      formData: {
        stockInformation: stockInformation && stockInformation.id,
        stockInformationFullValue: stockInformation,
        businessUnit: businessUnit && businessUnit.id,
        businessUnitFullValue: businessUnit,
        systemClass: systemClass && systemClass.systemClassId,
        systemClassFullValue: systemClass,
        systemMainComponent: systemMainComponent && systemMainComponent.systemMainComponentId,
        systemMainComponentFullValue: systemMainComponent,
        ibase: ibase,
        serialNumber: serialNumber,
        dateOfManufacture: dateOfManufacture && new Date(dateOfManufacture),
        availabilityFrom: moment(availabilityFrom).isValid() ? new Date(availabilityFrom) : '',
        availabilityTo: moment(availabilityTo).isValid() ? new Date(availabilityTo) : '',
        storageLocation,
        room: warehouseRoom && warehouseRoom.id,
        roomFullValue: warehouseRoom,
        owner: owner && owner.id,
        ownerFullValue: owner,
        isMobileOrExternal: isMobileOrExternal ? 'Yes' : isMobileOrExternal === false ? 'No' : '',
      },
      isFormValid: false,
    };
    const kitStatusForm = {
      formData: {
        kitCondition: kitCondition && kitCondition.kitConditionId,
        kitConditionFullValue: kitCondition && {
          kitConditionId: kitCondition.kitConditionId,
          kitStatusId: kitCondition.kitConditionId,
          translatedStatus:
            kitCondition.kitConditionTranslated &&
            kitCondition.kitConditionTranslated[0] &&
            kitCondition.kitConditionTranslated[0].translatedCondition,
        },
        comment,
        refurbishmentFrom: reparation.reparationFrom ? new Date(reparation.reparationFrom) : null,
        refurbishmentTo: reparation.reparationTo ? new Date(reparation.reparationTo) : null,
      },
      isFormValid: false,
    };
    const freeAccessoriesForm = {
      formData: {
        freeAccessories,
      },
      isFormValid: false,
    };
    const vrDetalisForm = {
      formData: {
        windowsUserPassword: kitDetails && kitDetails.windowsUserPassword,
        vrDetailsUserName: kitDetails && kitDetails.vrDetailsUserName,
        vrDetailsPassword: kitDetails && kitDetails.vrDetailsPassword,
        linkToOnboardingMaterial: kitDetails && kitDetails.linkToOnboardingMaterial,
      },
      isFormValid: false,
    }
    const images = kitImages ? kitImages.map(image => ({ ...image, name: image.file })) : [];
    const attachments = kitAttachments ? kitAttachments.map(file => ({ ...file, name: file.file })) : [];
  
    return {
      generalInformationForm,
      informationForm,
      kitStatusForm,
      salesComponentForm,
      furtherImportantComponentForm,
      freeAccessoriesForm,
      kitImages: images,
      kitImagesToDelete: [],
      kitAttachments: attachments,
      kitAttachmentsToDelete: [],
      vrDetalisForm
    };
  };