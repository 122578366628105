import { ViewTypes, SummaryPos } from 'react-big-scheduler';
import ViewTypesCustomized from '../../commonComponents/ReactBigScheduler/ViewTypes';

export const schedularConfig = (hideOptions, currentViewType = ViewTypes.Custom1, isZMCCCalendar, isLabsLocation) => ({
  schedulerWidth: '100%',
  besidesWidth: 20,
  schedulerMaxHeight: 500,
  tableHeaderHeight: 60,
  horizontalPadding: hideOptions ? 20 : 50,

  agendaResourceTableWidth: 264,
  agendaMaxEventWidth: 100,

  dayResourceTableWidth: hideOptions ? 0 : 280,
  weekResourceTableWidth: '16%',
  monthResourceTableWidth: hideOptions ? 0 : 284,
  quarterResourceTableWidth: hideOptions ? 0 : 280,
  yearResourceTableWidth: hideOptions ? 0 : 280,
  customResourceTableWidth: hideOptions ? 0 : 280,

  dayCellWidth: 40,
  weekCellWidth: '12%',
  monthCellWidth: 50,
  quarterCellWidth: 50,
  yearCellWidth: 50,
  customCellWidth: 50,
  weekDayCellWidth: 30,

  dayMaxEvents: 99,
  weekMaxEvents: 99,
  monthMaxEvents: 99,
  quarterMaxEvents: 99,
  yearMaxEvents: 99,
  customMaxEvents: 99,
  weekDayMaxEvents: 99,

  eventItemHeight: 15,
  eventItemLineHeight: 19,
  nonAgendaSlotMinHeight: 0,
  dayStartFrom: currentViewType === ViewTypesCustomized.WeekDay ? 8 :0,
  dayStopTo: ViewTypesCustomized.WeekDay  ? 18 : 23,
  defaultEventBgColor: '#91b3eb',
  selectedAreaColor: 'rgba(74,179,0, 0.2)',
  nonWorkingTimeHeadColor: '#999999',
  nonWorkingTimeHeadBgColor: (!isLabsLocation &&  !isZMCCCalendar ) ? '' : 'rgba(0, 0, 0, 0.05)',
  nonWorkingTimeBodyBgColor: (!isLabsLocation &&  !isZMCCCalendar ) ? '' : 'rgba(0, 0, 0, 0.05)',
  summaryColor: '#666',
  summaryPos: SummaryPos.TopRight,
  groupOnlySlotColor: 'rgba(0, 0, 0, 0.05)',

  startResizable: currentViewType == ViewTypesCustomized.WeekDay ? false: true,
  endResizable: currentViewType == ViewTypesCustomized.WeekDay ? false: true,
  movable: currentViewType == ViewTypesCustomized.WeekDay ? false: true,
  creatable: currentViewType == ViewTypesCustomized.WeekDay ? false: true,
  crossResourceMove: true,
  checkConflict: true,
  scrollToSpecialMomentEnabled: false,
  eventItemPopoverEnabled: true,
  calendarPopoverEnabled: true,
  recurringEventsEnabled: true,
  headerEnabled: false,
  displayWeekend: true,
  relativeMove: true,
  defaultExpanded: true,

  resourceName: 'Available Kits',
  taskName: 'Task Name',
  agendaViewHeader: 'Agenda',
  addMorePopoverHeaderFormat: 'MMM D, YYYY dddd',
  eventItemPopoverDateFormat: 'MMM D',
  nonAgendaDayCellHeaderFormat: 'ha',
  nonAgendaOtherCellHeaderFormat: (!isLabsLocation &&  !isZMCCCalendar ) ? 'D.MM' : 'D MMM',

  minuteStep: 30,

  views: [
    { viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false },
    { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
    { viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
    { viewName: 'Quarter', viewType: ViewTypes.Quarter, showAgenda: false, isEventPerspective: false },
    { viewName: 'Year', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false },
  ],

  
  // This config has been set for applying grouping for rooms (Only for zmcc)
  // could be achieved through constructor
  isZmcc: false, 
  isOperatorZmcc: isZMCCCalendar
});
